import React, { Component } from "react";
import { connect } from "react-redux";
import MainLayout from "../layouts/MainLayout";
import { container as ReviewsContainer } from "../modules/Reviews";
import authSelectors from "../modules/Auth/selectors";
import authActions from "../modules/Auth/actions";
import { UserRequests } from "../components";
import { UserHeader } from '../modules/Users/components';
import {
  Row,
  Col,
  Container,
  MDBInput,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Input,
  Card,
  CardBody
} from "mdbreact";

const ProfileView = props => (
  <MainLayout>
    <Container>
      <Row>
        <Col size="xl-4">
          <UserHeader user={props.user} />
        </Col>
        <Col size="xl-8">
          <ReviewsContainer userId={props.userData.id} />
        </Col>
      </Row>
    </Container>
  </MainLayout>
);

export default connect(
  authSelectors,
  authActions
)(ProfileView);
