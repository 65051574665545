const defaultState = {
    topLocations: [],
    suggestedLocations: []
}
export default (state = defaultState, action) => {
    switch(action.type) {
        case 'GET_TOP_LOCATIONS_SUCCESS': return {
            ...state,
            topLocations: [...Object.values(action.payload.offices)]
        }
        case 'GET_SUGGESTED_LOCATIONS_SUCCESS': return {
            ...state,
            suggestedLocations: [...Object.values(action.payload.offices)]
        }
        default: return state;
    }
}