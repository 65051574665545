import React, { Component } from 'react';
import MainLayout from '../layouts/MainLayout';
import BrowseContainer from '../modules/Offices/containers/browseContainer'

const BrowseView = (props) => (
    <MainLayout>
        <BrowseContainer {...props}/>
    </MainLayout>
)


export default BrowseView