import React, { Component } from 'react';
import { NavLink} from 'react-router-dom';
import { BecomeHostButton } from '../../modules/Auth/components';
import StyledHost from './styledHost'
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardImage,
    CardTitle,
    CardText,
    Row,
    Col,
    Fa
} from "mdbreact";
class HomeHost extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { showModal } = this.props;
        return (
            <StyledHost>
                <div className="home-host text-center blue-gradient">
                <Row className="host-row">
                       <h3>Become a Host</h3>
                </Row>
                <Row className="host-row" > <Col className="text-center">
                <BecomeHostButton showModal={showModal}/>
                </Col></Row>
                </div>
            </StyledHost>
        );
    }
}

export default HomeHost;
