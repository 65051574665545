import config from "../../config";
import { adalApiFetch } from "../Auth";

export const fetchOffice = async (id) => {
  try {
    const office = await adalApiFetch(
      fetch,
      `${config.api.endpoints.offices.details}?id=${id}`
    ).then((res) => {
      if (!res.ok || res.status !== 200) {
        throw new Error(
          "Call to endpoint " +
            res.url +
            " failed with status code " +
            res.status
        );
      }
      if (res.headers.get("content-type").indexOf("application/json") === -1) {
        throw new TypeError(
          'Response from "' + res.url + '" has unexpected "content-type"'
        );
      }
      return res.json();
    });

    return office;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};
