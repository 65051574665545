import { AuthenticationContext, adalFetch, withAdalLogin } from "react-adal";
import config from "../../config";

export const adalConfig = {
  tenant: config.api.tenant,
  clientId: config.api.clientId,
  endpoints: {
    api: config.api.clientId,
  },
  postLogoutRedirectUri: window.location.href,
  redirectUri: window.location.origin,
  cacheLocation: "localStorage",
};

export const authContext = new AuthenticationContext(adalConfig);

export const getToken = () => {
  return authContext.getCachedToken(authContext.config.clientId);
};

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.clientId, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(
  authContext,
  adalConfig.endpoints.api
);

export { default as actions } from "./actions";
export { default as reducer } from "./reducer";
export { default as container } from "./container";
export { default as selectors } from "./selectors";
