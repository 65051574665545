import React, { Component } from "react";

import "./RecentVisits.scss";

import { connect } from "react-redux";
import {
  MDBContainer,
  MDBIcon,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBTabContent,
  MDBTabPane,
} from "mdbreact";
import loadingSelectors from "../../../Loading/selectors";
import authSelectors from "../../../Auth/selectors";
import selectors from "../../selectors";
import officeSelectors from "../../../Offices/selectors";
import actions from "../../actions";
import officeActions from "../../../Offices/actions";
import BookedDeskTable from "./Parts/BookedDeskTable";
import BookedHostTable from "./Parts/BookedHostTable";
import PlanVisitModal from "../../../Offices/components/PlanVisit/Modal";
import moment from "moment";
import { createDateWithHours } from "../../../../utilities/dateHelpers";

class RecentVisists extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
      showPlanVisitModal: false,
      selectedLocation: null,
      selectedBuilding: null,
    };
  }

  render() {
    const {
      activeTab,
      showPlanVisitModal,
      selectedLocation,
      selectedBuilding,
    } = this.state;
    const { floorVisits, bookings, userData, offices } = this.props;
    const { buildings, fetchingOffices } = offices;

    const sortedFloorVisits = [
      ...floorVisits
        .filter(
          (fv) => createDateWithHours(new Date(fv.endDate), 23) >= new Date()
        )
        .sort((a, b) => {
          const timestampA = new Date(a.startDate).getTime();
          const timestampB = new Date(b.startDate).getTime();
          return timestampA > timestampB ? -1 : timestampA < timestampB ? 1 : 0;
        }),
      ...floorVisits
        .filter(
          (fv) => createDateWithHours(new Date(fv.endDate), 23) < new Date()
        )
        .sort((a, b) => {
          const timestampA = new Date(a.startDate).getTime();
          const timestampB = new Date(b.startDate).getTime();
          return timestampA > timestampB ? -1 : timestampA < timestampB ? 1 : 0;
        }),
    ].slice(0, 5);

    const sortedBookings = Object.values(bookings)
      .sort((a, b) => {
        if (
          a.bookingStatus.id == 1 &&
          a.startDateTimeStamp * 1000 < new Date().getTime()
        )
          return 1;
        if (a.bookingStatus.id > b.bookingStatus.id) return 1;
        if (a.bookingStatus.id < b.bookingStatus.id) return -1;
        if (a.startDateTimeStamp < b.startDateTimeStamp) return 1;
        if (a.startDateTimeStamp > b.startDateTimeStamp) return -1;
        return 0;
      })
      .filter((booking) => booking.guest && booking.guest.id === userData.id)
      .slice(0, 5);

    // MEMO: visits grouped by offices
    const sortedOfficeVisits = sortedFloorVisits.reduce(
      (accumulator, currentValue) => {
        const foundLocation = accumulator.find(
          (i) => i.locationId === currentValue.locationId
        );

        if (!foundLocation) {
          accumulator.push(currentValue);
        }

        return accumulator;
      },
      []
    );

    // TODO: group by
    // TODO: when this enabled test this, until then we're showing no data
    const sortedOfficeBookings = []; // sortedBookings;

    return floorVisits.length > 0 || sortedOfficeBookings.length > 0 ? (
      <div className="recentVisits">
        <MDBContainer>
          <h1>Recent Bookings </h1>

          <MDBNav tabs className="nav-justified" color="primary">
            <MDBNavItem>
              <MDBNavLink
                link
                to="#"
                active={activeTab === "1"}
                onClick={() => this.changeTab("1")}
                role="tab"
              >
                <MDBIcon far icon="building" /> Booked Desks
              </MDBNavLink>
            </MDBNavItem>

            <MDBNavItem>
              <MDBNavLink
                link
                to="#"
                active={activeTab === "2"}
                onClick={() => this.changeTab("2")}
                role="tab"
              >
                <MDBIcon far icon="user" /> Booked Hosts
              </MDBNavLink>
            </MDBNavItem>
          </MDBNav>

          <MDBTabContent className="card" activeItem={activeTab}>
            <MDBTabPane tabId="1" role="tabpanel">
              <BookedDeskTable
                data={sortedOfficeVisits}
                selectedLocation={selectedLocation}
                buildingFetching={fetchingOffices}
                buildings={buildings}
                onBuildingSelection={(selectedBuilding) => {
                  this.setState({
                    ...this.state,
                    showPlanVisitModal: true,
                    selectedBuilding,
                  });
                }}
                onBooking={(selectedLocationId) => {
                  this.setState({
                    ...this.state,
                    selectedLocation: selectedLocationId,
                  });

                  this.fetchBuildings(selectedLocationId);
                }}
              />
            </MDBTabPane>

            <MDBTabPane tabId="2" role="tabpanel">
              <BookedHostTable data={sortedOfficeBookings} />
            </MDBTabPane>
          </MDBTabContent>

          {selectedLocation && selectedBuilding && (
            <PlanVisitModal
              key={selectedLocation.id}
              showModal={showPlanVisitModal}
              office={undefined}
              officeId={selectedLocation}
              buildingId={selectedBuilding.id}
              buildingName={selectedBuilding.name}
              onToggle={() =>
                this.setState({
                  ...this.state,
                  showPlanVisitModal: !showPlanVisitModal,
                })
              }
              hideRebookModal
            />
          )}
        </MDBContainer>
      </div>
    ) : null;
  }

  changeTab = (tab) => {
    const { activeTab } = this.state;

    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  fetchBuildings = (locationId) => {
    const { getBuildings } = this.props;
    getBuildings(locationId, moment().format("YYYY-MM-DD"));
  };
}

export default connect(
  (state) => ({
    ...selectors(state),
    ...authSelectors(state),
    ...loadingSelectors(state),
    offices: { ...officeSelectors(state) },
  }),
  {
    ...actions,
    ...officeActions,
  }
)(RecentVisists);
