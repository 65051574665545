import React from "react";
import ingeniusLogo from "../../media/InGeniusLogo.svg";

const InGeniusLogo = (props) => {
  return (
    <a
      href="https://ingenius-accelerator.nestle.com/"
      target="_blank"
      rel="noreferrer noopener"
      title="InGenius"
      alt="InGenius Logo"
      style={{
        display: "inline-block",
        width: 74,
        height: 57,
        backgroundColor: "white",
        backgroundImage: `url('${ingeniusLogo}')`,
        backgroundSize: "53px auto",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        marginRight: 28,
        verticalAlign: "middle"
      }}
    ></a>
  );
};

export default InGeniusLogo;
