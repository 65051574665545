import { adalApiFetch } from "../Auth/index";
import config from "../../config";

const getOfficesStart = () => ({
  type: "GET_OFFICES_START",
});

const getOfficesSuccess = (response, clear) => ({
  type: "GET_OFFICES_SUCCESS",
  payload: response,
  clear,
});

const getOfficesError = (error) => ({
  type: "GET_OFFICES_ERROR",
  error,
});

export const getOffices = (filters, clear) => async (dispatch) => {
  dispatch(getOfficesStart());
  try {
    const offices = await adalApiFetch(
      fetch,
      config.api.endpoints.offices.list,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(filters),
      }
    ).then((res) => {
      if (!res.ok || res.status !== 200) {
        throw new Error(
          "Call to endpoint " +
            res.url +
            " failed with status code " +
            res.status
        );
      }
      if (res.headers.get("content-type").indexOf("application/json") === -1) {
        throw new TypeError(
          'Response from "' + res.url + '" has unexpected "content-type"'
        );
      }
      return res.json();
    });
    return dispatch(getOfficesSuccess(offices, clear));
  } catch (error) {
    console.log(error);
    return dispatch(getOfficesError(error));
  }
};

const updateOfficeStart = () => ({
  type: "UPDATE_OFFICE_START",
});

const updateOfficeSuccess = (updatedOffice) => ({
  type: "UPDATE_OFFICE_SUCCESS",
  payload: updatedOffice,
});

const updateOfficeError = (error) => ({
  type: "UPDATE_OFFICE_ERROR",
  error,
});

export const updateOffice = (filters) => async (dispatch) => {
  dispatch(updateOfficeStart());
  try {
    const updatedOffice = await adalApiFetch(
      fetch,
      config.api.endpoints.offices.update,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(filters),
      }
    ).then((res) => {
      if (!res.ok || res.status !== 200) {
        throw new Error(
          "Call to endpoint " +
            res.url +
            " failed with status code " +
            res.status
        );
      }
      if (res.headers.get("content-type").indexOf("application/json") === -1) {
        throw new TypeError(
          'Response from "' + res.url + '" has unexpected "content-type"'
        );
      }
      return res.json();
    });
    return dispatch(updateOfficeSuccess(updatedOffice));
  } catch (error) {
    return dispatch(updateOfficeError(error));
  }
};

/*
.########..##.....##.####.##.......########..####.##....##..######....######.
.##.....##.##.....##..##..##.......##.....##..##..###...##.##....##..##....##
.##.....##.##.....##..##..##.......##.....##..##..####..##.##........##......
.########..##.....##..##..##.......##.....##..##..##.##.##.##...####..######.
.##.....##.##.....##..##..##.......##.....##..##..##..####.##....##........##
.##.....##.##.....##..##..##.......##.....##..##..##...###.##....##..##....##
.########...#######..####.########.########..####.##....##..######....######.
*/

const getBuildingsStart = () => ({
  type: "GET_BUILDINGS_START",
});

const getBuildingsSuccess = (response, clear, locationId) => ({
  type: "GET_BUILDINGS_SUCCESS",
  payload: response,
  clear,
  locationId,
});

const getBuildingsError = (error) => ({
  type: "GET_BUILDINGS_ERROR",
  error,
});

export const getBuildings =
  (locationId, startDate, clear) => async (dispatch) => {
    dispatch(getBuildingsStart());
    try {
      const buildings = await adalApiFetch(
        fetch,
        `${config.api.endpoints.buildings.list}?locationId=${locationId}&startDate=${startDate}`
      ).then((res) => {
        if (!res.ok || res.status !== 200) {
          throw new Error(
            "Call to endpoint " +
              res.url +
              " failed with status code " +
              res.status
          );
        }
        if (
          res.headers.get("content-type").indexOf("application/json") === -1
        ) {
          throw new TypeError(
            'Response from "' + res.url + '" has unexpected "content-type"'
          );
        }
        return res.json();
      });
      return dispatch(getBuildingsSuccess(buildings, clear, locationId));
    } catch (error) {
      console.log(error);
      return dispatch(getBuildingsError(error));
    }
  };

const createBuildingStart = () => ({
  type: "CREATE_BUILDING_START",
});

const createBuildingSuccess = (payload) => ({
  type: "CREATE_BUILDING_SUCCESS",
  payload,
});

const createBuildingError = (error) => ({
  type: "CREATE_BUILDING_ERROR",
  error,
});

export const createBuilding = (locationId, data) => async (dispatch) => {
  dispatch(createBuildingStart());
  try {
    const payload = await adalApiFetch(
      fetch,
      `${config.api.endpoints.buildings.create}?locationId=${locationId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    ).then((res) => {
      if (!res.ok || res.status !== 200) {
        throw new Error(
          "Call to endpoint " +
            res.url +
            " failed with status code " +
            res.status
        );
      }

      return true;
    });
    return dispatch(createBuildingSuccess());
  } catch (error) {
    return dispatch(createBuildingError(error));
  }
};

const editBuildingStart = () => ({
  type: "EDIT_BUILDING_START",
});

const editBuildingSuccess = (payload) => ({
  type: "EDIT_BUILDING_SUCCESS",
  payload,
});

const editBuildingError = (error) => ({
  type: "EDIT_BUILDING_ERROR",
  error,
});

export const editBuilding = (buildingId, data) => async (dispatch) => {
  dispatch(editBuildingStart());
  try {
    const payload = await adalApiFetch(
      fetch,
      `${config.api.endpoints.buildings.edit}?buildingId=${buildingId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    ).then((res) => {
      if (!res.ok || res.status !== 200) {
        throw new Error(
          "Call to endpoint " +
            res.url +
            " failed with status code " +
            res.status
        );
      }

      return true;
    });
    return dispatch(editBuildingSuccess());
  } catch (error) {
    return dispatch(editBuildingError(error));
  }
};
const deleteBuildingStart = () => ({
  type: "EDIT_BUILDING_START",
});

const deleteBuildingSuccess = (payload) => ({
  type: "EDIT_BUILDING_SUCCESS",
  payload,
});

const deleteBuildingError = (error) => ({
  type: "EDIT_BUILDING_ERROR",
  error,
});

export const deleteBuilding = (buildingId) => async (dispatch) => {
  dispatch(deleteBuildingStart());
  try {
    const payload = await adalApiFetch(
      fetch,
      `${config.api.endpoints.buildings.delete}?buildingId=${buildingId}`,
      {
        method: "DELETE",
      }
    ).then((res) => {
      if (!res.ok || res.status !== 200) {
        throw new Error(
          "Call to endpoint " +
            res.url +
            " failed with status code " +
            res.status
        );
      }

      return true;
    });
    return dispatch(deleteBuildingSuccess());
  } catch (error) {
    return dispatch(deleteBuildingError(error));
  }
};

/*
.########.##........#######...#######..########...######.
.##.......##.......##.....##.##.....##.##.....##.##....##
.##.......##.......##.....##.##.....##.##.....##.##......
.######...##.......##.....##.##.....##.########...######.
.##.......##.......##.....##.##.....##.##...##.........##
.##.......##.......##.....##.##.....##.##....##..##....##
.##.......########..#######...#######..##.....##..######.
*/

const getBuildingFloorsStart = () => ({
  type: "GET_BUILDING_FLOORS_START",
});

const getBuildingFloorsSuccess = (response, clear) => ({
  type: "GET_BUILDING_FLOORS_SUCCESS",
  payload: response,
  clear,
});

const getBuildingFloorsError = (error) => ({
  type: "GET_BUILDING_FLOORS_ERROR",
  error,
});

export const getBuildingFloors =
  (buildingId, startDate, endDate, clear) => async (dispatch) => {
    dispatch(getBuildingFloorsStart());
    try {
      const buildings = await adalApiFetch(
        fetch,
        `${config.api.endpoints.floors.list}?buildingId=${buildingId}&startDate=${startDate}&endDate=${endDate}`
      ).then((res) => {
        if (!res.ok || res.status !== 200) {
          throw new Error(
            "Call to endpoint " +
              res.url +
              " failed with status code " +
              res.status
          );
        }
        if (
          res.headers.get("content-type").indexOf("application/json") === -1
        ) {
          throw new TypeError(
            'Response from "' + res.url + '" has unexpected "content-type"'
          );
        }
        return res.json();
      });
      return dispatch(getBuildingFloorsSuccess(buildings, clear));
    } catch (error) {
      console.log(error);
      return dispatch(getBuildingFloorsError(error));
    }
  };

export default {
  getOffices,
  updateOffice,
  createBuilding,
  deleteBuilding,
  editBuilding,
  getBuildings,
  getBuildingFloors,
};
