import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { MDBBtn as Button, MDBFileInput } from "mdbreact";
import { getToken } from "../../../Auth";
import config from "../../../../config";

const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  font-family: sans-serif;
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

class UploadOfficeInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: null,
      selectedUrl: "",
      progress: 0,
      loading: false,
      error: null,
    };
  }
  handleFiles = (files) => {
    const self = this;
    if (files.length === 1) {
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onloadend = function() {
        let selectedUrl = reader.result;
        self.setState({ files, selectedUrl, loading: true, error: null });
        self.uploadFile(files[0]);
      };
    } else {
      self.setState({
        loading: false,
        error: "You can only upload single image.",
      });
    }
  };
  uploadFile = (file) => {
    const { officeId } = this.props;
    var url = `${config.api.endpoints.offices.uploadPolicy}?locationId=${officeId}`;
    var xhr = new XMLHttpRequest();
    var formData = new FormData();
    const self = this;
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.setRequestHeader("Authorization", `Bearer ${getToken()}`);
    xhr.upload.addEventListener("progress", function(e) {
      self.setState({
        progress: (e.loaded * 100.0) / e.total || 100,
      });
    });

    xhr.addEventListener("readystatechange", function(e) {
      if (xhr.readyState == 4 && xhr.status == 200) {
        self.setState({ loading: false, selectedUrl: xhr.responseText }, () => {
          self.props.onSuccess(self.state.selectedUrl);
        });
      } else if (xhr.readyState == 4 && xhr.status != 200) {
        self.setState(
          {
            loading: false,
            error: xhr.statusText,
            selectedUrl: "",
          },
          () => self.props.onError()
        );
      }
    });

    formData.append("file", file);
    xhr.send(formData);
  };
  render() {
    const { files, selectedUrl, loading, error, progress } = this.state;
    const {} = this.props;
    return (
      <label className="btn btn-primary">
        <input
          onChange={(e) => {
            this.handleFiles(e.target.files);
          }}
          type="file"
          accept="application/pdf"
          style={{ display: "none" }}
        />
        {!!loading ? "Uploading..." : "Upload PDF"}
      </label>
    );
  }
}

export default UploadOfficeInformation;
