const defaultState = {
  reviews: {},
  totalResults: 0
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case "GET_REVIEWS_SUCCESS":
      return {
        ...state,
        totalResults: action.payload.totalResults,
        reviews: action.payload.reviews
      };
    default:
      return state;
  }
};
