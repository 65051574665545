import { adalApiFetch } from "../Auth/";
import config from "../../config";

const getResourcesStart = () => ({
  type: "GET_RESOURCES_START",
});

const getResourcesSuccess = (payload) => ({
  type: "GET_RESOURCES_SUCCESS",
  payload,
});

const getResourcesError = (error) => ({
  type: "GET_RESOURCES_ERROR",
  error,
});

export const getResources = () => async (dispatch) => {
  dispatch(getResourcesStart());
  try {
    let resources = null;
    if (
      window.localStorage.getItem("Resources") &&
      JSON.parse(window.localStorage.getItem("Resources")).text
    ) {
      resources = JSON.parse(window.localStorage.getItem("Resources"));
    } else {
      resources = await adalApiFetch(
        fetch,
        config.api.endpoints.resources.all
      ).then((res) => {
        if (!res.ok || res.status !== 200) {
          throw new Error(
            "Call to endpoint " +
              res.url +
              " failed with status code " +
              res.status
          );
        }
        if (
          res.headers.get("content-type").indexOf("application/json") === -1
        ) {
          throw new TypeError(
            'Response from "' + res.url + '" has unexpected "content-type"'
          );
        }
        return res.json();
      });
    }
    return dispatch(getResourcesSuccess(resources));
  } catch (error) {
    return dispatch(getResourcesError(error));
  }
};
const getAllLocationsStart = () => ({
  type: "GET_ALL_LOCATIONS_START",
});

const getAllLocationsSuccess = (payload) => ({
  type: "GET_ALL_LOCATIONS_SUCCESS",
  payload,
});

const getAllLocationsError = (error) => ({
  type: "GET_ALL_LOCATIONS_ERROR",
  error,
});

export const getAllLocations = () => async (dispatch) => {
  dispatch(getAllLocationsStart());
  try {
    let locations = null;
    if (
      // TODO: deciede how to refresh localstorage until then it's disabled
      // sometimes we need to refresh the localstorage, otherwise it will not 
      // display new locations
      false &&
      window.localStorage.getItem("Locations") &&
      JSON.parse(window.localStorage.getItem("Locations"))
    ) {
      locations = JSON.parse(window.localStorage.getItem("Locations"));
    } else {
      locations = await adalApiFetch(
        fetch,
        config.api.endpoints.resources.allLocations
      ).then((res) => {
        if (!res.ok || res.status !== 200) {
          throw new Error(
            "Call to endpoint " +
              res.url +
              " failed with status code " +
              res.status
          );
        }
        if (
          res.headers.get("content-type").indexOf("application/json") === -1
        ) {
          throw new TypeError(
            'Response from "' + res.url + '" has unexpected "content-type"'
          );
        }
        return res.json();
      });
    }
    return dispatch(getAllLocationsSuccess(locations));
  } catch (error) {
    return dispatch(getAllLocationsError(error));
  }
};

export default {
  getResources,
  getAllLocations,
};
