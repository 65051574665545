import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { adalApiFetch } from '../../../Auth/index';
import media from '../../../../media';
import { debounce } from '../../../../utilities';
import config from '../../../../config';
import actions from '../../actions';
import selectors from '../../selectors';
import officeActions from '../../../Offices/actions';
import authActions from '../../../Auth/actions';
import officeSelectors from '../../../Offices/selectors';
import resourcesActions from '../../../Resources/actions';
import resourcesSelectors from '../../../Resources/selectors';
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Fa,
  Input,
  MDBBtn as Button,
  MDBBtnGroup,
  MDBChip,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
  MDBMedia,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  toast,
} from 'mdbreact';

const combinedSelectors = (state) => ({
  ...selectors(state),
  ...officeSelectors(state),
  ...resourcesSelectors(state),
});
const combinedActions = {
  ...actions,
  ...officeActions,
  ...resourcesActions,
  ...authActions,
};

class AddOfficesModalButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal || false,
      officeDtos: [],
      selectedOffices: [],
      dto: {
        addOffices: [],
      },
      filter: '',
      focusedInput: null,
      loading: false,
      error: null,
    };
  }

  getOffices = () => {
    const { dto, filter } = this.state;
    this.setState(
      {
        loading: true,
        officeDtos: [],
      },
      () => {
        adalApiFetch(fetch, config.api.endpoints.offices.list, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: filter,
            activeOnly: true,
          }),
        })
          .then((res) => res.json())
          .then(({ totalResults, offices }) =>
            this.setState({
              loading: false,
              officeDtos: offices
                .map((item) => item.office)
                .filter((office) => dto.addOffices.indexOf(office.id) == -1),
            })
          )
          .catch((error) => {
            console.log(error);
            this.setState({ loading: false, error });
          });
      }
    );
  };
  handleSubmit = () => {
    const { dto } = this.state;
    const { updateUser, getUserProfileData } = this.props;
    updateUser(dto).then((action) => {
      switch (action.type) {
        case 'UPDATE_USER_SUCCESS': {
          getUserProfileData();
          toast.success(`Updates were saved!`, {
            position: 'top-center',
          });
          break;
        }
      }
      this.setState({
        showModal: false,
        officeDtos: [],
        selectedOffices: [],
        dto: {
          addOffices: [],
        },
        filter: '',
      });
    });
  };

  handleFilterChange = (e) => {
    this.setState({ filter: e.target.value });
  };
  handleFilterKeyUp = (e) => {
    if (e.keyCode === 13) {
      this.getOffices();
    }
  };

  handleToggleOffice = (office) => {
    const { dto, selectedOffices, officeDtos } = this.state;
    let tempOffices = [...selectedOffices];
    if (tempOffices.map((office) => office.id).indexOf(office.id) > -1) {
      tempOffices.splice(
        tempOffices.map((office) => office.id).indexOf(office.id),
        1
      );
    } else {
      tempOffices.push(office);
    }
    this.setState({
      dto: {
        ...dto,
        addOffices: tempOffices.map((office) => office.id),
      },
      selectedOffices: tempOffices,
    });
  };

  handleCheck = (e) => {
    const { dto } = this.state;
    this.setState({
      dto: {
        ...dto,
        termsAndConditionsAccepted: e.target.checked,
      },
    });
  };
  render() {
    const { loading, officeDtos, selectedOffices, showModal, dto } = this.state;
    const { size, resources } = this.props;
    return (
      <Fragment>
        <Button
          onClick={() => this.setState({ showModal: true })}
          disabled={Object.keys(resources).lenght === 0}
          color='primary'
          size={size}
        >
          {this.props.text || 'Add more locations'}
        </Button>
        <Modal
          backdrop={true}
          centered
          backdropClassName='modalZFix'
          isOpen={showModal}
          toggle={() => this.setState({ showModal: false })}
        >
          <ModalHeader
            className='text-left'
            toggle={() => this.setState({ showModal: false })}
          >
            Add more offices
          </ModalHeader>
          <ModalBody className='text-left'>
            <Container>
              <Row>
                <Col middle>
                  <div
                    style={{
                      fontSize: 20,
                      fontWeight: '400',
                      marginBottom: 12,
                    }}
                  >
                    Select offices you want to host in.
                  </div>
                  {selectedOffices.map((office) => (
                    <MDBChip
                      waves
                      close
                      handleClose={() => this.handleToggleOffice(office)}
                      key={`office-${office.id}`}
                    >
                      {office.name}
                    </MDBChip>
                  ))}
                </Col>
              </Row>
              <Row className='align-items-center'>
                <Col size='12'>
                  <MDBBtnGroup style={{ width: '100%' }}>
                    <input
                      className='form-control form-control-sm'
                      type='text'
                      placeholder='Find your offices'
                      aria-label='Search'
                      style={{ flexGrow: 1, marginBottom: 0 }}
                      onChange={(e) => {
                        this.handleFilterChange(e);
                        debounce(this.getOffices, 1000)();
                      }}
                      onKeyUp={this.handleFilterKeyUp}
                    />
                    <Button
                      size='sm'
                      color='primary'
                      onClick={() => this.getOffices()}
                    >
                      {' '}
                      <Fa icon='search' />
                    </Button>
                  </MDBBtnGroup>
                </Col>
              </Row>
              <Row>
                <Col className='text-center'>
                  {loading && <Spinner small />}
                </Col>
              </Row>
              <MDBListGroup
                style={{
                  maxHeight: '250px',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                }}
              >
                {officeDtos
                  .filter(
                    (dto) =>
                      selectedOffices
                        .map((office) => office.id)
                        .indexOf(dto.id) == -1
                  )
                  .map((office) => {
                    return (
                      <MDBListGroupItem>
                        <MDBMedia>
                          <MDBMedia className='pb-0' left>
                            <img
                              style={{ width: 30, height: 30 }}
                              src={office.coverPhotoUrl}
                            />
                          </MDBMedia>
                          <MDBMedia body>
                            <Row>
                              <Col size='8'>
                                <h5>{office.name}</h5> {office.address.city},{' '}
                                {office.address.city}
                              </Col>
                              <Col size='4' style={{ textAlign: 'right' }}>
                                <Button
                                  onClick={() =>
                                    this.handleToggleOffice(office)
                                  }
                                  floating
                                  tag='a'
                                  size='sm'
                                  color='primary'
                                  // gradient="aqua"
                                >
                                  <MDBIcon
                                    size='2x'
                                    style={{ color: '#fff' }}
                                    icon={
                                      dto.addOffices.indexOf(office.id) > -1
                                        ? 'remove'
                                        : 'plus'
                                    }
                                  />
                                </Button>
                              </Col>
                            </Row>
                          </MDBMedia>
                        </MDBMedia>
                      </MDBListGroupItem>
                    );
                  })}
              </MDBListGroup>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button
              className='mt-0'
              color='primary'
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default connect(
  combinedSelectors,
  combinedActions
)(AddOfficesModalButton);
