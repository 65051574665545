import React, { Component } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { withRouter } from "react-router-dom";
// import { TravelsFilter } from '../../index';
import marker from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import "./styles.css";

const icon = L.icon({
  iconUrl: marker,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  shadowSize: [41, 41],
  iconAnchor: [12.5, 41],
  shadowAnchor: [15, 41],
  popupAnchor: [0, 0],
});

const getConfig = (param) => ({
  params: {
    center: [42.698334, 23.319941],
    zoomControl: false,
    zoom: 4,
    scrollWheelZoom: !param.movementDisabled,
    maxZoom: param.maxZoom ? param.maxZoom : 18,
    minZoom: 4,
    scrollwheel: false,
    legends: true,
    infoControl: false,
    attributionControl: true,
    dragging: !param.movementDisabled,
  },
  tileLayer: {
    uri: "https://stamen-tiles-{s}.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.{ext}",
    params: {
      attribution:
        'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      subdomains: "abcd",
      minZoom: 1,
      maxZoom: 16,
      ext: "jpg",
    },
  },
});

export function stringToColor(string) {
  function hashCode(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }
  function intToRGB(i) {
    var c = (i & 0x00ffffff).toString(16).toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
  }
  return "#" + intToRGB(hashCode(string));
}

let subwayLineNames = [];

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      tileLayer: null,
      geojsonLayer: null,
      geojson: null,
      selectedManuscript: "*",
      selectedLocationType: "*",
      numEntrances: null,
      config: getConfig({
        movementDisabled: props.movementDisabled,
        maxZoom: props.maxZoom,
      }),
    };
    this._mapNode = null;
    this.updateMap = this.updateMap.bind(this);
    this.onEachFeature = this.onEachFeature.bind(this);
    this.pointToLayer = this.pointToLayer.bind(this);
    this.filterFeatures = this.filterFeatures.bind(this);
    this.filterGeoJSONLayer = this.filterGeoJSONLayer.bind(this);
  }

  styleFeature = (feature, event) => {
    return event == "hover"
      ? {
          color: "#aaffcc", //stringToColor(feature.properties.manuscript),
          weight: 5,
          opacity: 1,
        }
      : {
          color: "#aaffcc", //stringToColor(feature.properties.manuscript),
          weight: 2,
          opacity: 0.8,
        };
  };

  componentDidMount() {
    if (!this.state.map) this.init(this._mapNode);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.state.geojson.features.length > 0 &&
        this.state.map &&
        !this.state.geojsonLayer) ||
      this.state.geojson !== prevState.geojson
    ) {
      this.addGeoJSONLayer(this.state.geojson);
    }
  }

  componentWillUnmount() {
    this.state.map.remove();
  }

  static getDerivedStateFromProps(props, state) {
    return {
      numEntrances: props.geojson.features.length,
      geojson: props.geojson,
    };
  }

  updateMap({ manuscript, locationType }) {
    if (manuscript === "- Всички ръкописи: -") {
      manuscript = "*";
    }
    this.setState({
      selectedManuscript: manuscript,
      selectedLocationType: locationType,
    });
  }

  addGeoJSONLayer(geojson) {
    if (this.state.geojsonLayer) {
      this.state.geojsonLayer.clearLayers();
    }
    const geojsonLayer = L.geoJson(geojson, {
      onEachFeature: this.onEachFeature,
      pointToLayer: this.pointToLayer,
      filter: this.filterFeatures,
      style: this.styleFeature,
    });
    geojsonLayer.addTo(this.state.map);
    this.setState({ geojsonLayer });
    this.zoomToFeature(geojsonLayer);
  }

  filterGeoJSONLayer() {
    this.state.geojsonLayer.clearLayers();
    this.state.geojsonLayer.addData(this.state.geojson);
    this.zoomToFeature(this.state.geojsonLayer);
  }

  zoomToFeature(target) {
    var fitBoundsParams = {
      paddingTopLeft: [130, 130],
      paddingBottomRight: [130, 130],
    };
    if (Object.keys(target.getBounds()).length) {
      this.state.map.fitBounds(target.getBounds(), fitBoundsParams);
    }
  }

  filterFeatures(feature, layer) {
    return true;
  }

  pointToLayer(feature, latlng) {
    var markerParams = {
      radius: 4,
      title: feature.properties.name,
      fillColor: "orange",
      color: "#fff",
      icon: icon,
      weight: 1,
      opacity: 0.5,
      fillOpacity: 0.8,
    };

    return L.marker(latlng, markerParams);
  }

  onEachFeature(feature, layer) {
    var self = this;
    layer.on("mouseover", function (e) {
      if (layer.feature.geometry.type === "LineString") {
        layer.setStyle(self.styleFeature(feature, "hover"));
      }
    });
    layer.on("mouseout", function (e) {
      if (layer.feature.geometry.type === "LineString") {
        layer.setStyle(self.styleFeature(feature, ""));
      }
    });
    layer.on("click", function (e) {
      if (e.target.feature.properties.href) {
        window.open(e.target.feature.properties.href);
      } else {
        self.props.history.push(`/office/${e.target.feature.properties.id}`);
      }
    });
  }

  init(id) {
    if (this.state.map) return;
    let map = L.map(id, this.state.config.params);
    if (!this.props.movementDisabled) {
      L.control.zoom({ position: "bottomleft" }).addTo(map);
      L.control.scale({ position: "bottomleft" }).addTo(map);
    }

    const tileLayer = L.tileLayer(
      this.state.config.tileLayer.uri,
      this.state.config.tileLayer.params
    ).addTo(map);
    this.setState({ map, tileLayer });
  }

  render() {
    const { style } = this.props;
    return (
      <div
        id="mapUI"
        style={{ height: 400, position: "relative", zIndex: 0, ...style }}
      >
        <div ref={(node) => (this._mapNode = node)} id="map" />
      </div>
    );
  }
}

export default withRouter(Map);
