import React from "react";
import { MDBMedia, Fa } from "mdbreact";
import styled from 'styled-components';
import {Link }from 'react-router-dom';

const StyledOfficeCover = styled.div`
    width: 50px;
    display: inline-block;
    background-image: url('${({cover}) => cover}');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #ccc;
    border-radius: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:before {
        content: '';
        display: block;
        padding-bottom: 100%;
    }
`
const SmallOfficeDetails = ({ office }) => (
    <MDBMedia>
      <MDBMedia className="pb-0" left >
      <Link to={`/office/${office.id}`}>
        <StyledOfficeCover cover={office.coverPhotoUrl}/>
        </Link>
      </MDBMedia>
      <MDBMedia body>
        <Link to={`/office/${office.id}`}><h5 style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{office.name}</h5></Link>
        {office.address.city}, {office.address.country}
      </MDBMedia>
    </MDBMedia>
);

export default SmallOfficeDetails;
