import React, { Component } from "react";
import Chart from "react-google-charts";
class Map extends Component {
  constructor(props) {
    super(props);
  }
  handleMapSelect = ({ chartWrapper }) => {
    const chart = chartWrapper.getChart();
    const selection = chart.getSelection();
    if (selection.length === 1) {
      const [selectedItem] = selection;
      const dataTable = chartWrapper.getDataTable();
      const { row, column } = selectedItem;
    }
  };
  render() {
    const { data } = this.props;
    return (
      <div>
        <Chart
          width={"100%"}
          height={"470px"}
          chartType="GeoChart"
          data={data}
          options={{
            colorAxis: { colors: ["#4285f4"] }
          }}
          chartEvents={[
            {
              eventName: "select",
              callback: this.handleMapSelect
            }
          ]}
          rootProps={{ "data-testid": "1" }}
        />
      </div>
    );
  }
}

export default Map;
