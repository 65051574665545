import { adalApiFetch } from "../Auth/index";
import config from "../../config";

const getAdminOfficesStart = () => ({
  type: "GET_ADMIN_OFFICES_START"
});

const getAdminOfficesSuccess = (response, clear) => ({
  type: "GET_ADMIN_OFFICES_SUCCESS",
  payload: response,
  clear
});

const getAdminOfficesError = error => ({
  type: "GET_ADMIN_OFFICES_ERROR",
  error
});

export const getAdminOffices = (filters, clear) => async dispatch => {
  dispatch(getAdminOfficesStart());
  try {
    const offices = await adalApiFetch(
      fetch,
      config.api.endpoints.offices.list,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(filters)
      }
    ).then(res => {
      if (!res.ok || res.status !== 200) {
        throw new Error(
          "Call to endpoint " +
            res.url +
            " failed with status code " +
            res.status
        );
      }
      if (res.headers.get("content-type").indexOf("application/json") === -1) {
        throw new TypeError(
          'Response from "' + res.url + '" has unexpected "content-type"'
        );
      }
      return res.json();
    });
    return dispatch(getAdminOfficesSuccess(offices, clear));
  } catch (error) {
    console.log(error);
    return dispatch(getAdminOfficesError(error));
  }
};

export default {
  getAdminOffices
};
