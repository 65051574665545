import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { adalApiFetch } from '../../../Auth/index';
import media from '../../../../media';
import { debounce } from '../../../../utilities';
import config from '../../../../config';
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Fa,
  Input,
  MDBBtn as Button,
  MDBBtnGroup,
  MDBChip,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
  MDBMedia,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  toast,
} from 'mdbreact';

const combinedSelectors = (state) => ({
  //   ...selectors(state),
  //   ...officeSelectors(state),
  //   ...resourcesSelectors(state),
});
const combinedActions = {
  //   ...actions,
  //   ...officeActions,
  //   ...resourcesActions,
  //   ...authActions,
};

class SelectUsersButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal || false,
      data: {},
      selection: props.selection || [],

      filter: '',
      focusedInput: null,
      loading: false,
      error: null,
    };
  }

  fetchData = () => {
    this.setState(
      {
        loading: true,
        data: {},
      },
      () => {
        adalApiFetch(fetch, config.api.endpoints.users.list, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: this.state.filter,
          }),
        })
          .then((res) => res.json())
          .then((users) =>
            this.setState({
              loading: false,
              data: users,
            })
          )
          .catch((error) => {
            console.log(error);
            this.setState({ loading: false, error });
          });
      }
    );
  };
  handleSubmit = () => {
    this.props.onSubmit(this.state.selection);
    this.setState({ showModal: false, filter: '', data: {} });
  };

  handleFilterChange = (e) => {
    this.setState({ filter: e.target.value });
  };
  handleFilterKeyUp = (e) => {
    if (e.keyCode === 13) {
      this.fetchData();
    }
  };

  handleToggleSelection = (user) => {
    if (this.state.selection.map((u) => u.id).indexOf(user.id) === -1) {
      //add the user if he is not in the selection
      this.setState({
        selection: [...this.state.selection, user],
      });
    } else {
      //remove the user if he's in the selection
      this.setState({
        selection: this.state.selection.filter((u) => u.id !== user.id),
      });
    }
  };

  render() {
    const { loading, data, showModal } = this.state;
    const {} = this.props;
    return (
      <Fragment>
        <Button
          onClick={() => this.setState({ showModal: true })}
          color='primary'
          size={'sm'}
        >
          {this.props.text || 'Select users'}
        </Button>
        <Modal
          backdrop={true}
          centered
          backdropClassName='modalZFix'
          isOpen={showModal}
          toggle={() => this.setState({ showModal: false })}
        >
          <ModalHeader
            className='text-left'
            toggle={() => this.setState({ showModal: false })}
          >
            Select users
          </ModalHeader>
          <ModalBody className='text-left'>
            <Container>
              <Row>
                <Col middle>
                  <div
                    style={{
                      fontSize: 20,
                      fontWeight: '400',
                      marginBottom: 12,
                    }}
                  >
                    Your selection:
                  </div>
                  {this.state.selection.map((user) => (
                    <MDBChip
                      waves
                      close
                      handleClose={() => this.handleToggleSelection(user)}
                      title={user.email}
                      key={`user-${user.id}`}
                    >
                      {user.name}
                    </MDBChip>
                  ))}
                </Col>
              </Row>
              <Row className='align-items-center'>
                <Col size='12'>
                  <MDBBtnGroup style={{ width: '100%' }}>
                    <input
                      className='form-control form-control-sm'
                      type='text'
                      placeholder='Search for deskovery users'
                      aria-label='Search'
                      style={{ flexGrow: 1, marginBottom: 0 }}
                      onChange={(e) => {
                        this.handleFilterChange(e);
                        debounce(this.fetchData, 1000)();
                      }}
                      onKeyUp={this.handleFilterKeyUp}
                    />
                    <Button
                      size='sm'
                      color='primary'
                      onClick={() => this.fetchData()}
                    >
                      {' '}
                      <Fa icon='search' />
                    </Button>
                  </MDBBtnGroup>
                </Col>
              </Row>
              <Row>
                <Col className='text-center'>
                  {loading && <Spinner small />}
                </Col>
              </Row>
              <MDBListGroup
                style={{
                  maxHeight: '250px',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                }}
              >
                {Object.values(data)
                  .filter(
                    (user) =>
                      this.props.selection
                        .map((selectedUser) => selectedUser.id)
                        .indexOf(user.id) == -1
                  )
                  .map((user) => {
                    return (
                      <MDBListGroupItem>
                        <MDBMedia>
                          <MDBMedia className='pb-0' left>
                            <img
                              style={{ width: 60, height: 60, borderRadius: 5 }}
                              src={user.photoUrl}
                            />
                          </MDBMedia>
                          <MDBMedia body>
                            <Row>
                              <Col size='8'>
                                <h5>
                                  {user.name}
                                  <br />
                                  <small>
                                    {user.email && user.email.toLowerCase()}
                                  </small>
                                </h5>
                              </Col>
                              <Col size='4' style={{ textAlign: 'right' }}>
                                <Button
                                  onClick={() =>
                                    this.handleToggleSelection(user)
                                  }
                                  floating
                                  tag='a'
                                  size='sm'
                                  color='primary'
                                  // gradient="aqua"
                                >
                                  <MDBIcon
                                    size='2x'
                                    style={{ color: '#fff' }}
                                    icon={
                                      this.state.selection
                                        .map((u) => u.id)
                                        .indexOf(user.id) > -1
                                        ? 'remove'
                                        : 'plus'
                                    }
                                  />
                                </Button>
                              </Col>
                            </Row>
                          </MDBMedia>
                        </MDBMedia>
                      </MDBListGroupItem>
                    );
                  })}
              </MDBListGroup>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button
              className='mt-0'
              color='primary'
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default connect(combinedSelectors, combinedActions)(SelectUsersButton);
