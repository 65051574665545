import React, { Component } from "react";
import StyledOffice from "./styledOffice";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImage,
  CardTitle,
  CardText,
  Row,
  Col,
  Container,
  Fa,
  MDBInput,
  MDBDatePicker
} from "mdbreact";
class OfficePickerSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOffice: null
    };
  }
  handleSelectChange = e => {
    this.setState({
      selectedOffice: e.target.value
    });
  };
  render() {
    const { selectedOffice } = this.state;
    const { offices, onClick } = this.props;
    return (
      <div class="blue-gradient">
        <StyledOffice>
          <Container>
            <Row>
              <Col>
                <h1 className="text-center">Add New Office</h1>
              </Col>
            </Row>
            <Row center>
              <Col size="md-6" className="text-center select-col">
                <Row>
                  <Col size="md-8" middle>
                    <select
                      class="browser-default custom-select"
                      onChange={this.handleSelectChange}
                    >
                      <option value="">SELECT OFFICE</option>
                      {offices.map(office => {
                        return (
                          <option value={office.name}>{office.name}</option>
                        );
                      })}
                    </select>
                  </Col>
                  <Col size="md-4" middle>
                    <Button
                      class=" btn peach-gradient continue-btn"
                      onClick={() => onClick(selectedOffice)}
                    >
                      Continue
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </StyledOffice>
      </div>
    );
  }
}
export default OfficePickerSection;
