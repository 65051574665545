import React from 'react';

import './ConfirmationModal.scss';

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  MDBBtn as Button,
} from 'mdbreact';

export default function ConfirmationModal({
  open,
  onClose,
  onCancel,
  onConfirm,
  confirmText,
  cancelText,
  headerText,
  bodyText,
  modalStyle,
}) {
  const _cancelText = cancelText !== undefined ? cancelText : 'Cancel';
  const _confirmText = confirmText !== undefined ? confirmText : 'Confirm';
  const _headerText = headerText !== undefined ? headerText : 'Are you sure?';
  const _bodyText =
    bodyText !== undefined
      ? bodyText
      : " You're about to perform a dangerous action.";
  const _modalStyle = modalStyle ? modalStyle : 'danger';

  return (
    <Modal
      className='confirmationalModal'
      modalStyle={_modalStyle}
      backdropClassName='confirmationalModalBackdrop'
      isOpen={open}
      toggle={onClose}
      backdrop
      centered
    >
      <ModalHeader toggle={onClose}>{_headerText}</ModalHeader>

      {_bodyText && <ModalBody>{_bodyText}</ModalBody>}

      <ModalFooter>
        <Button color='primary' onClick={onCancel ? onCancel : onClose}>
          {_cancelText}
        </Button>

        <Button color='danger' onClick={onConfirm}>
          {_confirmText}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
