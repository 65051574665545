import React from 'react';
export default ({
    inputValue,
    onInputChange,
    onInputKeyUp,
    onButtonClick,
}) => (
    <div>
        <input 
            type="text"
            className='form-control'
            onKeyUp={onInputKeyUp} 
            onChange={onInputChange} 
            value={inputValue}/>
        <button 
            className='btn'
            type="button" 
            onClick={onButtonClick}>Change Name</button>
    </div>
)