import styled from 'styled-components';

export const SlidesContainer = styled.div`
  position: relative;
`;
export const Slide = styled.div`
  width: 100%;
  height: 400px;
  opacity: ${props => (props.active ? 1 : 0)};
  top: 0;
  left: 0;
  position: ${props => (props.active ? "relative" : "absolute")};
  visibility: ${props => (props.active ? "visible" : "hidden")};
  transition: 0.3s;
`;
export const Pager = styled.ul`
    display: block;
    text-align: center;
    margin: 0; padding: 0;
    li {
        display: inline-block;
        font-size: 0;
        width: 10px; height: 10px;
        background: #333;
        border-radius: 50%;
        opacity: .5;
        &.active { opacity: 1; }
        + li { margin-left: 5px; }
    }
`