import React, { Component } from 'react';
import { connect } from 'react-redux';
import { OfficePickerSection } from '../components';
import { OfficeDetails } from '../components';
import { Thankful } from '../components';
import selectors from '../selectors';
import actions from '../actions';


class addOffice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step:0,
            selectedOffice:null
        }
    }
    handleOfficePickerSubmit = (selectedOffice) =>{
        this.setState({
            step: 1,
            selectedOffice
        })
    }
    handleDetailsSubmit = () =>{
        this.setState({
            step: 2,
        })
    }
    render() {
        const {
            step,
            selectedOffice
        } = this.state;
        const {
            offices,
        } = this.props
        return (
            <div>
        {step === 0 && <OfficePickerSection offices={offices} onClick={this.handleOfficePickerSubmit}></OfficePickerSection> }
        {/*  onSubmit={this.handleAddOfficeSubmit} */}
        {step === 1 && <OfficeDetails selectedOffice={offices[0]} onClick={this.handleDetailsSubmit}/> }
        {step === 2 && <Thankful></Thankful>}
            
            </div>

        )
    }
}

export default connect(selectors, actions)(addOffice);