import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  MDBBtn as Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  MDBInput,
  toast,
} from 'mdbreact';
import selectors from '../../selectors';
import actions from '../../actions';

class EditUserButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal || false,
      user: props.user,
    };
  }

  handleSubmit = () => {
    const { user, createBooking } = this.props;
    this.setState({ showModal: false }, () => {
      toast.success(`Changes for ${user.name} have been saved.`, {
        position: 'top-center',
      });
    });
  };

  render() {
    const { showModal } = this.state;
    const { size, user, style, text } = this.props;
    return (
      <Fragment>
        <Button
          onClick={() => this.setState({ showModal: true })}
          gradient='peach'
          {...this.props}
        >
          {text || 'Edit user'}
        </Button>
        <Modal
          backdrop={true}
          centered
          backdropClassName='modalZFix'
          isOpen={showModal}
          toggle={() => this.setState({ showModal: false })}
        >
          <ModalHeader
            className='text-left'
            toggle={() => this.setState({ showModal: false })}
          >
            {this.props.user.name}
          </ModalHeader>
          <ModalBody className='text-left'>
            <div>
              <MDBInput
                label='Name'
                type='text'
                value={this.state.user.name}
                onChange={(e) => {
                  this.setState({
                    user: {
                      ...this.state.user,
                      name: e.target.value,
                    },
                  });
                }}
              />
              <MDBInput
                label='Email'
                type='text'
                value={this.state.user.email}
                onChange={(e) => {
                  this.setState({
                    user: {
                      ...this.state.user,
                      email: e.target.value,
                    },
                  });
                }}
              />
              <MDBInput
                label='Phone Number'
                type='text'
                value={this.state.user.phoneNumber}
                onChange={(e) => {
                  this.setState({
                    user: {
                      ...this.state.user,
                      phoneNumber: e.target.value,
                    },
                  });
                }}
              />
              <MDBInput
                label='Workplace URL'
                type='text'
                value={this.state.user.workplaceUrl}
                onChange={(e) => {
                  this.setState({
                    user: {
                      ...this.state.user,
                      workplaceUrl: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className='mt-0'
              type='button'
              gradient='peach'
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default connect(selectors, actions)(EditUserButton);
