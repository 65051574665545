import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import Autosuggest from "react-autosuggest";
import { Link } from "react-router-dom";
import { Button, Row, Col, Container } from "mdbreact";
import { adalApiFetch } from "../../modules/Auth";
import config from "../../config";
import StyledDesk from "./styledDesk";
import BecomeHostButton from "../../modules/Auth/components/BecomeHostButton";
import authSelectors from "../../modules/Auth/selectors";
import resourcesSelectors from "../../modules/Resources/selectors";

class FindDesk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      suggestions: [],
    };
  }
  onChange = (event, { newValue }) => {
    this.setState({ text: newValue });
  };

  handleKeyDown = (e) => {
    if (e.keyCode == 13) {
      this.handleFindSubmit();
    }
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.getSuggestions();
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };
  getSuggestions = () => {
    const { text } = this.state;
    adalApiFetch(fetch, config.api.endpoints.offices.suggest + `?text=${text}`)
      .then((res) => res.json())
      .then((suggestions) =>
        this.setState({ suggestions }, () => console.log(this.state))
      )
      .catch((error) => console.log(error));
  };
  handleFindSubmit = () => {
    const { text } = this.state;
    const { history } = this.props;
    history.push(`/offices?q=${text}`);
  };
  render() {
    const { text, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: "Where are you traveling to?",
      value: text,
      className: "form-control",
      onChange: this.onChange,
      onKeyDown: this.handleKeyDown,
    };

    return (
      <StyledDesk>
        <Container className="text-center">
          <Row center style={{ maxWidth: 970, margin: "0 auto" }}>
            <Col>
              <Row>
                <Col>
                  <h1
                    style={{
                      fontSize: 52,
                      fontWeight: "bold",
                      color: "#212529",
                      fontFamily: '"Calibre", "Arial", sans-serif',
                    }}
                  >
                    {this.props.resources.texts &&
                      this.props.resources.texts[0].strings.filter(
                        (text) => text.key === "homeHeading"
                      )[0].translatedText}
                  </h1>
                  <p style={{ fontSize: 18, marginBottom: 60 }}>
                    {this.props.resources.texts &&
                      this.props.resources.texts[0].strings.filter(
                        (text) => text.key === "homeDescription"
                      )[0].translatedText}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Link to="/offices">
                    <Button style={{ minWidth: 180 }} color="orange">
                      Find a Desk
                    </Button>
                  </Link>
                  {this.props.userData.userType.id < 2 && (
                    <BecomeHostButton gradient={null} color="blue">
                      Become a Host
                    </BecomeHostButton>
                  )}
                </Col>
              </Row>
              {/* <Row>
                <Col style={{ textAlign: "center" }}>
                  <strong>
                    *Due to COVID-19 we have adapted Deskovery so that you can
                    find and automatically book a desk at your home location
                    based on local regulations and capacity. Hosting has
                    currently been disabled.
                  </strong>
                </Col>
              </Row> */}
            </Col>
          </Row>
        </Container>
      </StyledDesk>
    );
  }
}

export default withRouter(
  connect((state) => ({
    ...resourcesSelectors(state),
    ...authSelectors(state),
  }))(FindDesk)
);
