import React from "react";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import middleware from "./middleware";
import { combineReducers } from "redux";
import modules from "./modules";

const dev = window.localStorage.getItem("dev");

if (dev) {
  console.log(`Welcome, ${dev}!`);
}

const Reducers = Object.keys(modules).reduce((reducers, key) => {
  reducers[key] = modules[key].reducer;
  return reducers;
}, {});

const RootReducer = combineReducers(Reducers);
export const store = createStore(
  RootReducer,
  {},
  dev
    ? composeWithDevTools(applyMiddleware(...middleware, thunkMiddleware))
    : applyMiddleware(...middleware, thunkMiddleware)
);

export default ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};
