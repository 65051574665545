const defaultState = {
    userData: {},
    isUserLoggedIn: false,
    fetching: false,
    fetched: false,
    error: null,
    addConsentLoading: false,
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case 'UPDATE_USER_DATA': 
            return {
                ...state,
                userData: {
                    ...state.userData,
                    ...action.payload
                }
            }
        case 'GET_USER_DATA_START':
            return {
                ...state,
                fetching: true,
                fetched: false,
                error: null
            };
        case 'GET_USER_DATA_SUCCESS':
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: null,
                userData: action.userData,
                isUserLoggedIn: true
            };
        case 'GET_USER_DATA_ERROR':
            return {
                ...state,
                fetching: false,
                fetched: false,
                error: action.error,
                isUserLoggedIn: false
            };
        case 'ADD_USERCONSENT_START':
            return {
              ...state,
              addConsentLoading: true
            }
        case 'ADD_USERCONSENT_SUCCESS':
            return {
              ...state,
              addConsentLoading: false
            }
        case 'ADD_USERCONSENT_ERROR':
            return {
              ...state,
              addConsentLoading: false,
              error: action.error
            }
        default:
            return state;
    }
}