import styled from 'styled-components'
const StyledThankful = styled.div`
h1,p{
    font-weight:bold;
}
.thank-conatiner{
    text-align:center;
    padding:100px;
    height:100vh;
}
   
`

export default StyledThankful