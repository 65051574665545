const defaultState = {
  offices: {},
  buildings: {},
  isFetching: false,
  fetched: false,
  fetchedBuildingsCID: undefined,
  buildingsFetching: false,
  totalResults: 0,
  error: null,
};
export default (state = defaultState, action) => {
  const openingTimes = [
    {
      label: "Monday",
      startTime: "09:00",
      endTime: "18:00",
      capacity: 5,
    },
    {
      label: "Tuesday",
      startTime: "09:00",
      endTime: "18:00",
      capacity: 100,
    },
    {
      label: "Wednesday",
      startTime: "09:00",
      endTime: "18:00",
      capacity: 100,
    },
    {
      label: "Thursday",
      startTime: "09:00",
      endTime: "18:00",
      capacity: 100,
    },
    {
      label: "Friday",
      startTime: "09:00",
      endTime: "18:00",
      capacity: 100,
    },
    {
      label: "Saturday",
      startTime: "09:00",
      endTime: "18:00",
      capacity: 100,
    },
    {
      label: "Sunday",
      startTime: "09:00",
      endTime: "18:00",
      capacity: 0,
    },
  ];
  switch (action.type) {
    case "GET_OFFICES_START":
      return {
        ...state,
        isFetching: true,
        fetched: false,
        error: null,
      };
    case "GET_OFFICES_ERROR":
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };

    case "GET_OFFICES_SUCCESS":
      return {
        ...state,
        isFetching: false,
        fetched: true,
        totalResults: action.payload.totalResults,
        offices: action.clear
          ? action.payload.offices.reduce((acc, curr) => {
              acc[curr.id] = acc[curr.id]
                ? acc[curr.id]
                : {
                    ...curr.office,
                    // openingTimes,
                  };
              return acc;
            }, {})
          : {
              ...state.offices,
              ...action.payload.offices.reduce((acc, curr) => {
                acc[curr.id] = acc[curr.id]
                  ? acc[curr.id]
                  : {
                      ...curr.office,
                      // openingTimes,
                    };
                return acc;
              }, {}),
            },
      };
    case "GET_BUILDINGS_START":
      return {
        ...state,
        isFetching: true,
        buildingsFetching: true,
        fetched: false,
        error: null,
        buildings: {},
      };
    case "GET_BUILDINGS_ERROR":
      return {
        ...state,
        isFetching: false,
        buildingsFetching: false,
        error: action.payload,
        buildings: {},
      };

    case "GET_BUILDINGS_SUCCESS":
      return {
        ...state,
        isFetching: false,
        buildingsFetching: false,
        fetched: true,
        fetchedBuildingsCID: action.locationId,
        buildings: action.payload.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {}),
      };
    default:
      return state;
  }
};
