import React from 'react';
import {
    MDBIcon,
    Col, Container, Row, Footer
} from "mdbreact";

const MainFooter = (props) => (
    <Footer color='grey lighten-3' className="font-small pt-2 pb-2">
        <Container fluid>
            <Row>
                <Col className="text-left blue-text">&copy; Nestlé {new Date().getFullYear()}</Col>
                <Col className="text-right">
                    <a className="blue-text" href="https://nestle.facebook.com/groups/833627076986923/" target="_blank"> <MDBIcon icon="question-circle-o" /> Help </a>&nbsp;
                </Col>
            </Row>
        </Container>
    </Footer>
)

export default MainFooter;