import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { adalApiFetch } from '../../../Auth/index';
import media from '../../../../media';
import { debounce } from '../../../../utilities';
import config from '../../../../config';
import actions from '../../actions';
import selectors from '../../selectors';
import authActions from '../../../Auth/actions';
import resourcesActions from '../../../Resources/actions';
import resourcesSelectors from '../../../Resources/selectors';
import { ConfirmationModal } from '../../../../components';
import moment from 'moment';
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Fa,
  Input,
  MDBBtn as Button,
  MDBBtnGroup,
  MDBChip,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
  MDBMedia,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  toast,
} from 'mdbreact';

const combinedSelectors = (state) => ({
  ...selectors(state),
  ...resourcesSelectors(state),
});
const combinedActions = {
  ...actions,
  ...resourcesActions,
  ...authActions,
};

class AddBuildingButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal || false,
      dto: {
        id: props.building ? props.building.id : undefined,
        officeId: props.building
          ? props.building.officeId
          : props.office
          ? props.office.id
          : null,
        name: '',
        floors: [{ label: 'G', totalCapacity: 0 }],
        ...props.building,
      },
      loading: false,
      error: null,
      confirmationalAction: null,
    };
  }

  render() {
    const { showModal } = this.state;
    const { size } = this.props;
    return (
      <Fragment>
        <Button
          onClick={() => this.setState({ showModal: true })}
          color={this.props.building ? 'primary' : 'success'}
          size={size}
        >
          {this.props.building ? `Edit` : `New Building`}
        </Button>

        <Modal
          backdrop={true}
          centered
          size='lg'
          backdropClassName='modalZFix modalSelfZfix'
          isOpen={showModal}
          toggle={() => this.setState({ showModal: false })}
        >
          <ModalHeader
            className='text-left'
            toggle={() => this.setState({ showModal: false })}
          >
            {this.props.building
              ? `Editing ${this.props.building.name}`
              : `Create new building`}
          </ModalHeader>
          <ModalBody className='text-left'>
            <Container>
              <Row className='mb-3'>
                <Col size='12'>
                  <h4>Building name:</h4>
                </Col>
                <Col size='12'>
                  <input
                    placeholder='Building name'
                    className='form-control'
                    type='text'
                    value={this.state.dto.name}
                    onChange={(e) =>
                      this.setState({
                        ...this.state,
                        dto: { ...this.state.dto, name: e.target.value },
                      })
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col size='12'>
                  <h4>Floors:</h4>{' '}
                </Col>
                <Col size='12' middle>
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>Label</th>
                        <th>Total Capacity</th>
                        <th style={{ textAlign: 'right' }}>
                          <Button
                            size='sm'
                            color='success'
                            className='nowrap'
                            onClick={(e) =>
                              this.setState({
                                ...this.state,
                                dto: {
                                  ...this.state.dto,
                                  floors: [
                                    {
                                      label: `Floor ${this.state.dto.floors.length}`,
                                      capacity: 0,
                                    },
                                    ...this.state.dto.floors,
                                  ],
                                },
                              })
                            }
                          >
                            New floor
                          </Button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.dto.floors.map((floor, index) => {
                        return (
                          <tr>
                            <td>
                              <input
                                className='form-control d-block'
                                placeholder='Label'
                                type='text'
                                value={floor.label}
                                onChange={(e) =>
                                  this.setState({
                                    ...this.state,
                                    dto: {
                                      ...this.state.dto,
                                      floors: this.state.dto.floors.map(
                                        (innerFloor, innerIndex) => {
                                          if (index !== innerIndex)
                                            return innerFloor;
                                          return {
                                            ...innerFloor,
                                            label: e.target.value,
                                          };
                                        }
                                      ),
                                    },
                                  })
                                }
                              />
                            </td>
                            <td>
                              <input
                                className='form-control d-block'
                                placeholder='Total Capacity'
                                type='number'
                                min='0'
                                value={floor.totalCapacity}
                                onChange={(e) =>
                                  this.setState({
                                    ...this.state,
                                    dto: {
                                      ...this.state.dto,
                                      floors: this.state.dto.floors.map(
                                        (innerFloor, innerIndex) => {
                                          if (index !== innerIndex)
                                            return innerFloor;
                                          return {
                                            ...innerFloor,
                                            totalCapacity: e.target.value,
                                          };
                                        }
                                      ),
                                    },
                                  })
                                }
                              />
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              <MDBBtnGroup>
                                <Button
                                  title='Move Up'
                                  size='sm'
                                  disabled={index === 0}
                                  onClick={(e) => {
                                    let cache = [...this.state.dto.floors];
                                    [cache[index - 1], cache[index]] = [
                                      cache[index],
                                      cache[index - 1],
                                    ];
                                    this.setState({
                                      ...this.state,
                                      dto: {
                                        ...this.state.dto,
                                        floors: cache,
                                      },
                                    });
                                  }}
                                >
                                  <MDBIcon icon='arrow-up' />
                                </Button>
                                <Button
                                  title='Move Down'
                                  size='sm'
                                  disabled={
                                    index === this.state.dto.floors.length - 1
                                  }
                                  onClick={(e) => {
                                    let cache = [...this.state.dto.floors];
                                    [cache[index + 1], cache[index]] = [
                                      cache[index],
                                      cache[index + 1],
                                    ];
                                    this.setState({
                                      ...this.state,
                                      dto: {
                                        ...this.state.dto,
                                        floors: cache,
                                      },
                                    });
                                  }}
                                >
                                  <MDBIcon icon='arrow-down' />
                                </Button>
                                <Button
                                  size='sm'
                                  title='delete'
                                  color='danger'
                                  onClick={(e) => {
                                    this.setState({
                                      ...this.state,
                                      confirmationalAction: {
                                        id: index,
                                        type: 'delete_floor',
                                        bodyText:
                                          'You are about to delete a floor.',
                                      },
                                    });
                                  }}
                                >
                                  <MDBIcon icon='trash' />
                                </Button>
                              </MDBBtnGroup>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Container>
          </ModalBody>

          <ModalFooter>
            {!!this.props.building && (
              <Button
                className='mt-0'
                color='danger'
                onClick={(e) => {
                  this.setState({
                    ...this.state,
                    confirmationalAction: {
                      id: null,
                      type: 'delete_building',
                      bodyText: 'You are about to delete a building.',
                    },
                  });
                }}
              >
                Remove building
              </Button>
            )}
            <Button
              className='mt-0'
              color='primary'
              onClick={(e) => {
                if (!this.props.office) return;
                this.props.building
                  ? this.props
                      .editBuilding(this.props.building.id, this.state.dto)
                      .then((action) => {
                        if (action.type.indexOf('_SUCCESS') > -1) {
                          this.props.getBuildings(
                            this.props.office.id,
                            moment().format('YYYY-MM-DD')
                          );
                        }
                      })
                  : this.props
                      .createBuilding(this.props.office.id, this.state.dto)
                      .then((action) => {
                        if (action.type.indexOf('_SUCCESS') > -1) {
                          this.props.getBuildings(
                            this.props.office.id,
                            moment().format('YYYY-MM-DD')
                          );
                        }
                      });
                this.setState({
                  ...this.state,
                  showModal: false,
                });
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>

        <ConfirmationModal
          open={!!this.state.confirmationalAction}
          bodyText={
            this.state.confirmationalAction
              ? this.state.confirmationalAction.bodyText
              : null
          }
          onClose={() =>
            this.setState({
              ...this.state,
              confirmationalAction: null,
            })
          }
          onConfirm={() => {
            switch (this.state.confirmationalAction.type) {
              case 'delete_building':
                this.props
                  .deleteBuilding(this.props.building.id)
                  .then((action) => {
                    if (action.type.indexOf('_SUCCESS') > -1) {
                      this.props.getBuildings(
                        this.props.office.id,
                        moment().format('YYYY-MM-DD')
                      );
                    }
                  });
                this.setState({
                  ...this.state,
                  showModal: false,
                  confirmationalAction: null,
                });

                return;

              case 'delete_floor':
                const index = this.state.confirmationalAction.id;
                this.setState({
                  ...this.state,
                  dto: {
                    ...this.state.dto,
                    floors: this.state.dto.floors.filter(
                      (innerFloor, innerIndex) => {
                        if (index == innerIndex) return false;
                        return true;
                      }
                    ),
                  },
                  confirmationalAction: null,
                });

                return;
            }
          }}
        />
      </Fragment>
    );
  }
}

export default connect(combinedSelectors, combinedActions)(AddBuildingButton);
