import * as Admin from "./Admin";
import * as Auth from "./Auth";
import * as Bookings from "./Bookings";
import * as Dashboard from "./Dashboard";
import * as Loading from "./Loading";
import * as Locations from "./Locations";
import * as Offices from "./Offices";
import * as Onboarding from "./Onboarding";
import * as Resources from "./Resources";
import * as Reviews from "./Reviews";
import * as SampleModule from "./SampleModule";
import * as Users from "./Users";

export default {
  Admin,
  Auth,
  Bookings,
  Dashboard,
  Loading,
  Locations,
  Offices,
  Onboarding,
  Resources,
  Reviews,
  SampleModule,
  Users
};
