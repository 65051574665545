import React, { Component } from "react";
import PropTypes from "prop-types";
import { stringToHexColor } from "../../utilities";
import "./styles.scss";

class UserPicture extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    avatar: PropTypes.string
  };
  static defaultProps = {
    avatar: null
  };

  render() {
    const {} = this.state;
    const { name, avatar } = this.props;
    return (
      <div
        style={{
          backgroundImage: avatar ? `url('${avatar}')` : null,
          backgroundColor: avatar ? "transparent" : stringToHexColor(name)
        }}
        className="user-photo"
        title={name}
      >
        {name
          .split(" ")
          .map(name => name[0])
          .join("")}
      </div>
    );
  }
}

export default UserPicture;
