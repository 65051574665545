import React, { Component, Fragment } from 'react';

import './style.scss';

import { MDBBtn as Button } from 'mdbreact';
import 'react-dates/initialize';
import config from '../../../../../config';
import PlanVisitModal from '../Modal';

const {
  appSettings: { userCanSelectPreviousDates },
} = config;

export default class PlanVisitButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal || false,
    };
  }

  render() {
    const { showModal } = this.state;
    return (
      <Fragment>
        <Button
          onClick={() => this.setState({ ...this.state, showModal: true })}
          color='orange'
          size='sm'
        >
          Visit
        </Button>

        <PlanVisitModal
          {...this.props}
          showModal={showModal}
          onToggle={() =>
            this.setState({ ...this.state, showModal: !showModal })
          }
        />
      </Fragment>
    );
  }
}
