import React, { Component } from "react";
import styled from "styled-components";
import media from "../../media";
import { connect } from "react-redux";
import selectors from "./selectors";
import resourcesSelectors from "../Resources/selectors";
import actions from "./actions";
import resourcesActions from "../Resources/actions";
import bookingsSelectors from "../Bookings/selectors";
import bookingsActions from "../Bookings/actions";
import { Progress } from "mdbreact";
import { withTracking } from "react-appinsights";

const combinedSelectors = (state) => ({
  ...selectors(state),
  ...resourcesSelectors(state),
  ...bookingsSelectors(state),
});
const combinedActions = {
  ...actions,
  ...resourcesActions,
  ...bookingsActions,
};

const Splash = styled.div`
background-image: url('${media["deskoveryLogoWhite"]}'), linear-gradient(40deg, #45cafc, #303f9f);
background-repeat: no-repeat;
background-position: center;
background-size: 50% auto, cover;
position: fixed;
top: 0; left: 0; right; 0; bottom: 0;
width: 100vw; height: 100vh;
`;

class AuthContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      bookingsInterval: null,
    };
  }

  lastActivity = new Date();

  setLastActivity = () => {
    this.lastActivity = new Date();
  };

  componentDidMount() {
    this.props.getUserData();
    this.props.getResources();
    this.props.getBookings();
    this.props.getFloorVisits();
    this.setState({
      bookingsInterval: window.setInterval(() => {
        if (new Date() - this.lastActivity < 1000 * 60 * 2) {
          this.props.getBookings();
        }
      }, 15000),
    });

    window.addEventListener("mousemove", this.setLastActivity);
  }

  componentWillUnmount() {
    window.clearInterval(this.state.bookingsInterval);
    window.removeEventListener("mousemove", this.setLastActivity);
  }
  render() {
    const { isUserLoggedIn, resources } = this.props;
    return isUserLoggedIn ? (
      this.props.children
    ) : (
      <Splash>
        <Progress material preloader color="primary" />
      </Splash>
    );
  }
}

export default withTracking(
  connect(combinedSelectors, combinedActions)(AuthContainer)
);
