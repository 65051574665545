import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { MDBBtn as Button, MDBFileInput } from "mdbreact";
import { getToken } from "../../../Auth";
import config from "../../../../config";

const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  font-family: sans-serif;
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

class UploadOfficeImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: null,
      selectedImageUrl: this.props.coverPhotoUrl,
      progress: 0,
      loading: false,
      error: null
    };
  }
  static propTypes = {
    propOne: PropTypes.string
  };
  static defaultProps = {
    propOne: ""
  };
  handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
    var dt = e.dataTransfer;
    var files = dt.files;
  };
  handleFiles = files => {
    const self = this;
    if (files.length === 1) {
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onloadend = function() {
        let selectedImageUrl = reader.result;
        self.setState({ files, selectedImageUrl, loading: true, error: null });
        self.uploadFile(files[0]);
      };
    } else {
      self.setState({
        loading: false,
        error: "You can only upload single image."
      });
    }
  };
  handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    var dt = e.dataTransfer;
    var files = dt.files;
    this.handleFiles(files);
  };
  uploadFile = file => {
    const { officeId, coverPhotoUrl } = this.props;
    var url = `${config.api.endpoints.offices.upload}?locationId=${officeId}`;
    var xhr = new XMLHttpRequest();
    var formData = new FormData();
    const self = this;
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.setRequestHeader("Authorization", `Bearer ${getToken()}`);
    xhr.upload.addEventListener("progress", function(e) {
      self.setState({
        progress: (e.loaded * 100.0) / e.total || 100
      });
    });

    xhr.addEventListener("readystatechange", function(e) {
      if (xhr.readyState == 4 && xhr.status == 200) {
        self.setState({ loading: false });
      } else if (xhr.readyState == 4 && xhr.status != 200) {
        self.setState({
          loading: false,
          error: xhr.statusText,
          selectedImageUrl: coverPhotoUrl
        });
      }
    });

    formData.append("file", file);
    xhr.send(formData);
  };
  render() {
    const { files, selectedImageUrl, loading, error, progress } = this.state;
    const {} = this.props;
    return (
      <div
        style={{
          backgroundImage: `url('${selectedImageUrl}')`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          paddingBottom: "56.25%",
          position: "relative"
        }}
        onDragOver={this.handleDragOver}
        onDrop={this.handleDrop}
      >
        {!error && !loading && !files && (
          <StyledOverlay>
            <div>Drop prefered image here or</div>
            <label className="btn btn-primary">
              <input
                onChange={e => {
                  this.handleFiles(e.target.files);
                }}
                type="file"
                accept="image/png,image/jpeg"
                style={{ display: "none" }}
              />
              Click here to select one
            </label>
          </StyledOverlay>
        )}
        {error && (
          <StyledOverlay>
            <div>Error: {error}</div>
            <label className="btn btn-primary">
              <input
                onChange={e => {
                  this.handleFiles(e.target.files);
                }}
                type="file"
                accept="image/png,image/jpeg"
                style={{ display: "none" }}
              />
              Click here to select one
            </label>
          </StyledOverlay>
        )}
        {loading && (
          <StyledOverlay>
            Loading {Math.round(parseFloat(progress))}%
          </StyledOverlay>
        )}
      </div>
    );
  }
}

export default UploadOfficeImage;
