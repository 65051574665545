import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, info) {
      // You can also log the error to an error reporting service
      //logErrorToMyService(error, info);
      console.error(error, info)
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
            <div>
                <h1 className="text-center">Oops! Something went wrong.</h1>
                <p className="text-center">This button might solve the problem:<br/> <button type="button" className="btn btn-primary" onClick={()=>{
                    window.localStorage.removeItem('Resources');
                    window.location.reload();
                }}>Refresh</button></p>
            </div>
        )
      }
  
      return this.props.children; 
    }
  }

  export default ErrorBoundary