import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "mdbreact";
import authSelectors from '../Auth/selectors';
import { Slideshow } from "./components";
import selectors from "./selectors";
import actions from "./actions";

class OnboardingModule extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { onboarded, changeOnboardingStatus, userData, showModal, history } = this.props;
    return (
      <Modal
        backdrop={true}
        centered
        isOpen={showModal}
        toggle={() => history.push('/')}
      >
        <ModalBody>
          <Slideshow userData={userData} onFinish={() => {
            history.push('/')
            changeOnboardingStatus(true)
          }} />
        </ModalBody>
      </Modal>
    );
  }
}

export default connect(
  (state) => ({
    ...selectors(state),
    ...authSelectors(state)
  }),
  actions
)(withRouter(OnboardingModule));
