const changeName = (name, timeout) => ({
    type: 'ASYNC_CHANGE_NAME', 
    payload: {
        name, 
        timeout: (timeout || 1000)
    }
});

export default {
    changeName
}