import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  MDBBtn as Button,
  MDBBtnGroup,
  MDBBtn,
  MDBIcon,
  MDBInputGroup,
  MDBCollapse,
  MDBCollapseHeader,
  MDBCard as Card,
  MDBCardBody,
  MDBInput,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  toast,
} from 'mdbreact';
import UploadOfficeImage from '../UploadOfficeImage';
import UploadOfficeInformation from '../UploadOfficeInformation';
import selectors from '../../selectors';
import actions from '../../actions';
import config from '../../../../config.js';
import SelectUsersButton from '../../../Users/components/SelectUsersButton';

class EditOfficeButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal || false,
      selectedEditGroup: 'locationImage',
      office: {
        ...props.office,
        officePolicyUrl: props.office.officePolicyUrl || '',
        siteManagers: props.office.siteManagers || [],
        openingTimes: props.office.openingTimes,
      },
    };
  }

  handleSubmit = () => {
    const { office } = this.state;
    const { updateOffice, getOffices, onSubmit } = this.props;
    updateOffice(office).then((action) => {
      if (!!onSubmit) onSubmit(office);
      getOffices({
        locationIds: [office.id],
      }).then((action) => {
        this.setState({ showModal: false }, () => {
          toast.success(`Changes for ${office.name} have been saved.`, {
            position: 'top-center',
          });
        });
      });
    });
  };

  toggleCollapse = (selectedEditGroup) => () =>
    this.setState((prevState) => ({
      selectedEditGroup:
        prevState.selectedEditGroup !== selectedEditGroup
          ? selectedEditGroup
          : '',
    }));

  render() {
    const { showModal, selectedEditGroup } = this.state;
    const { size, office, style, text, updateOffice } = this.props;
    var sortedOpeningTimes = [...this.state.office.openingTimes] || [];
    sortedOpeningTimes.push(sortedOpeningTimes.shift());
    return (
      <Fragment>
        <Button
          onClick={() => this.setState({ showModal: true })}
          gradient='peach'
          {...this.props}
        >
          {text || 'Edit office'}
        </Button>
        <Modal
          backdrop={true}
          centered
          backdropClassName='modalZFix'
          isOpen={showModal}
          toggle={() => this.setState({ showModal: false })}
        >
          <ModalHeader
            className='text-left'
            toggle={() => this.setState({ showModal: false })}
          >
            {this.props.office.name}
          </ModalHeader>
          <ModalBody className='text-left'>
            <Card className='mb-3'>
              <MDBCollapseHeader onClick={this.toggleCollapse('locationImage')}>
                Facility image:
              </MDBCollapseHeader>

              <MDBCollapse id='locationImage' isOpen={selectedEditGroup}>
                <MDBCardBody style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <UploadOfficeImage
                    officeId={office.id}
                    coverPhotoUrl={office.coverPhotoUrl}
                  />
                </MDBCardBody>
              </MDBCollapse>
            </Card>
            <Card className='mb-3'>
              <MDBCollapseHeader onClick={this.toggleCollapse('details')}>
                Details:
              </MDBCollapseHeader>

              <MDBCollapse id='details' isOpen={selectedEditGroup}>
                <MDBCardBody>
                  <MDBInput
                    label='Title'
                    type='text'
                    value={this.state.office.name}
                    onChange={(e) => {
                      this.setState({
                        office: {
                          ...this.state.office,
                          name: e.target.value,
                        },
                      });
                    }}
                  />
                  <MDBInput
                    label='Description'
                    type='textarea'
                    value={this.state.office.description}
                    onChange={(e) => {
                      this.setState({
                        office: {
                          ...this.state.office,
                          description: e.target.value,
                        },
                      });
                    }}
                  />
                  <MDBInput
                    label='Site policy URL (auto generated with uploiad or provide link)'
                    type='text'
                    value={this.state.office.officePolicyUrl}
                    onChange={(e) => {
                      this.setState({
                        office: {
                          ...this.state.office,
                          officePolicyUrl: e.target.value,
                        },
                      });
                    }}
                  />
                  <UploadOfficeInformation
                    text='Upload Site Policy (PDF)'
                    officeId={this.props.office.id}
                    onSuccess={(officePolicyUrl) =>
                      this.setState({
                        office: {
                          ...this.state.office,
                          officePolicyUrl,
                        },
                      })
                    }
                  />
                  {/* <MDBInput
                    label="Reception Opening Hours"
                    type="textarea"
                    value={this.state.office.openingHours.join("\n")}
                    onChange={(e) => {
                      this.setState({
                        office: {
                          ...this.state.office,
                          openingHours: e.target.value.split("\n"),
                        },
                      });
                    }}
                  /> */}
                </MDBCardBody>
              </MDBCollapse>
            </Card>
            <Card className='mb-3'>
              <MDBCollapseHeader onClick={this.toggleCollapse('location')}>
                Location:
              </MDBCollapseHeader>
              <MDBCollapse id='location' isOpen={selectedEditGroup}>
                <MDBCardBody>
                  <MDBInput
                    label='Address Line 1'
                    type='text'
                    value={this.state.office.address.address1}
                    onChange={(e) => {
                      this.setState({
                        office: {
                          ...this.state.office,
                          address: {
                            ...this.state.office.address,
                            address1: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                  <MDBInput
                    label='Address Line 2'
                    type='text'
                    value={this.state.office.address.address2}
                    onChange={(e) => {
                      this.setState({
                        office: {
                          ...this.state.office,
                          address: {
                            ...this.state.office.address,
                            address2: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                  <MDBInput
                    label='Coordinates'
                    type='text'
                    value={this.state.office.coordinates}
                    onChange={(e) => {
                      this.setState({
                        office: {
                          ...this.state.office,
                          coordinates: e.target.value,
                        },
                      });
                    }}
                  />
                  <MDBInput
                    label='City'
                    type='text'
                    value={this.state.office.address.city}
                    onChange={(e) => {
                      this.setState({
                        office: {
                          ...this.state.office,
                          address: {
                            ...this.state.office.address,
                            city: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                  <MDBInput
                    label='Country'
                    type='text'
                    value={this.state.office.address.country}
                    onChange={(e) => {
                      this.setState({
                        office: {
                          ...this.state.office,
                          address: {
                            ...this.state.office.address,
                            country: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                </MDBCardBody>
              </MDBCollapse>
            </Card>
            <Card className='mb-3'>
              <MDBCollapseHeader
                onClick={this.toggleCollapse('openingHoursAndCapacity')}
              >
                Opening hours and Capacity:
              </MDBCollapseHeader>

              <MDBCollapse
                id='openingHoursAndCapacity'
                isOpen={selectedEditGroup}
              >
                <MDBCardBody>
                  <table>
                    <tr>
                      <th style={{ textAlign: 'right' }}>Day</th>
                      <th>Opening</th>
                      <th>Closing</th>
                      <th>Capacity (%)</th>
                    </tr>
                    {!!sortedOpeningTimes &&
                      sortedOpeningTimes.map((entry, index) => {
                        return (
                          <tr>
                            <td style={{ textAlign: 'right' }}>
                              {config.enums.weekDays[entry.day]}
                            </td>
                            {[
                              { key: 'startTime', props: {} },
                              {
                                key: 'endTime',
                                props: { placeholder: '18:00' },
                              },
                              {
                                key: 'capacity',
                                props: {
                                  type: 'number',
                                  min: '0',
                                  max: '100',
                                },
                              },
                            ].map((input) => {
                              return (
                                <td style={{ textAlign: 'right' }}>
                                  <input
                                    value={entry[input.key]}
                                    type='time'
                                    title='HH:MM'
                                    placeholder='09:00'
                                    required
                                    pattern='^(?:\d|[01]\d|2[0-3]):[0-5]\d$'
                                    onChange={(e) => {
                                      this.setState({
                                        ...this.state,
                                        office: {
                                          ...this.state.office,
                                          openingTimes: this.state.office.openingTimes.map(
                                            (innerEntry, innerIndex) => {
                                              if (entry.day !== innerEntry.day)
                                                return innerEntry;
                                              return {
                                                ...innerEntry,
                                                [input.key]: e.target.value,
                                              };
                                            }
                                          ),
                                        },
                                      });
                                    }}
                                    {...input.props}
                                  />
                                </td>
                              );
                            })}

                            {/* <td style={{ textAlign: "right" }}>
                            <input
                              value={entry.capacity}
                              type="number"
                              min="0"
                              max="100"
                              onChange={(e) => {
                                this.setState({
                                  ...this.state,
                                  office: {
                                    ...this.state.office,
                                    openingTimes: this.state.office.openingTimes.map(
                                      (innerEntry, innerIndex) => {
                                        if (index !== innerIndex)
                                          return innerEntry;
                                        return {
                                          ...innerEntry,
                                          capacity: e.target.value,
                                        };
                                      }
                                    ),
                                  },
                                });
                              }}
                            />
                          </td> */}
                          </tr>
                        );
                      })}
                  </table>
                </MDBCardBody>
              </MDBCollapse>
            </Card>
            <Card className='mb-3'>
              <MDBCollapseHeader onClick={this.toggleCollapse('contacts')}>
                Contacts:
              </MDBCollapseHeader>
              <MDBCollapse id='contacts' isOpen={selectedEditGroup}>
                <MDBCardBody>
                  <MDBInput
                    label='Site contact email'
                    type='text'
                    value={this.state.office.email}
                    onChange={(e) => {
                      this.setState({
                        office: {
                          ...this.state.office,
                          email: e.target.value,
                        },
                      });
                    }}
                  />
                  <MDBInput
                    label='Site contact phone number'
                    type='text'
                    value={this.state.office.frontDeskPhoneNumber}
                    onChange={(e) => {
                      this.setState({
                        office: {
                          ...this.state.office,
                          frontDeskPhoneNumber: e.target.value,
                        },
                      });
                    }}
                  />
                  <MDBInput
                    label='Security phone number'
                    type='text'
                    value={this.state.office.securityNumber}
                    onChange={(e) => {
                      this.setState({
                        office: {
                          ...this.state.office,
                          securityNumber: e.target.value,
                        },
                      });
                    }}
                  />
                  <MDBInput
                    label='First-aid phone number'
                    type='text'
                    value={this.state.office.firstAidNumber}
                    onChange={(e) => {
                      this.setState({
                        office: {
                          ...this.state.office,
                          firstAidNumber: e.target.value,
                        },
                      });
                    }}
                  />
                  <MDBInput
                    label='Fire brigade phone number'
                    type='text'
                    value={this.state.office.fireNumber}
                    onChange={(e) => {
                      this.setState({
                        office: {
                          ...this.state.office,
                          fireNumber: e.target.value,
                        },
                      });
                    }}
                  />
                  <MDBInput
                    label='Police phone number'
                    type='text'
                    value={this.state.office.policeNumber}
                    onChange={(e) => {
                      this.setState({
                        office: {
                          ...this.state.office,
                          policeNumber: e.target.value,
                        },
                      });
                    }}
                  />
                  <MDBInput
                    label='Visitor Application URL'
                    type='text'
                    value={this.state.office.applicationUrl}
                    onChange={(e) => {
                      this.setState({
                        office: {
                          ...this.state.office,
                          applicationUrl: e.target.value,
                        },
                      });
                    }}
                  />
                </MDBCardBody>
              </MDBCollapse>
            </Card>
            <Card className='mb-3'>
              <MDBCollapseHeader onClick={this.toggleCollapse('siteManagers')}>
                Site Managers:
              </MDBCollapseHeader>
              <MDBCollapse id='siteManagers' isOpen={selectedEditGroup}>
                <MDBCardBody>
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>Users</th>
                        <th style={{ textAlign: 'right' }}>
                          <SelectUsersButton
                            text='Add site managers'
                            selection={this.state.office.siteManagers}
                            onSubmit={(siteManagers) => {
                              this.setState({
                                office: {
                                  ...this.state.office,
                                  siteManagers,
                                },
                              });
                            }}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.office.siteManagers.map((user, index) => {
                        return (
                          <tr>
                            <td>{user.name}</td>
                            <td style={{ textAlign: 'right' }}>
                              <MDBBtnGroup>
                                <Button
                                  title='Move Up'
                                  size='sm'
                                  disabled={index === 0}
                                  onClick={(e) => {
                                    let cache = [
                                      ...this.state.office.siteManagers,
                                    ];
                                    [cache[index - 1], cache[index]] = [
                                      cache[index],
                                      cache[index - 1],
                                    ];
                                    this.setState({
                                      ...this.state,
                                      office: {
                                        ...this.state.office,
                                        siteManagers: cache,
                                      },
                                    });
                                  }}
                                >
                                  <MDBIcon icon='arrow-up' />
                                </Button>
                                <Button
                                  title='Move Down'
                                  size='sm'
                                  disabled={
                                    index ===
                                    this.state.office.siteManagers.length - 1
                                  }
                                  onClick={(e) => {
                                    let cache = [
                                      ...this.state.office.siteManagers,
                                    ];
                                    [cache[index + 1], cache[index]] = [
                                      cache[index],
                                      cache[index + 1],
                                    ];
                                    this.setState({
                                      ...this.state,
                                      office: {
                                        ...this.state.office,
                                        siteManagers: cache,
                                      },
                                    });
                                  }}
                                >
                                  <MDBIcon icon='arrow-down' />
                                </Button>
                                <Button
                                  size='sm'
                                  title='delete'
                                  color='danger'
                                  onClick={(e) => {
                                    this.setState({
                                      ...this.state,
                                      office: {
                                        ...this.state.office,
                                        siteManagers: this.state.office.siteManagers.filter(
                                          (innerFloor, innerIndex) => {
                                            if (index == innerIndex)
                                              return false;
                                            return true;
                                          }
                                        ),
                                      },
                                    });
                                  }}
                                >
                                  <MDBIcon icon='trash' />
                                </Button>
                              </MDBBtnGroup>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </MDBCardBody>
              </MDBCollapse>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button
              className='mt-0'
              type='button'
              color='primary'
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default connect(selectors, actions)(EditOfficeButton);
