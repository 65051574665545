import React, { Component } from "react";
import MainLayout from "../layouts/MainLayout";
import {
  Container,
} from "mdbreact";
import MyVisitsContainer from '../modules/Bookings/containers/myVisitsContainer';

const MyTripsView = ({ match }) => {
  const { params } = match;
  
  return (
    <MainLayout>
      <Container>
        <MyVisitsContainer params={params}/>
      </Container>
    </MainLayout>
  );
};

export default MyTripsView;
