import React, { Component } from 'react';
import MainLayout from '../layouts/MainLayout';
import {
  Container,
    MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
} from 'mdbreact'
import MyGuestsContainer from '../modules/Bookings/containers/myGuestsContainer';

const MyGuestsView = ({match}) => (
    <MainLayout>
      <Container>
      <MyGuestsContainer params={match.params}/>
      </Container>
    </MainLayout>
)


export default MyGuestsView