import styled from 'styled-components'
const StyledOffice = styled.div`
h1{
    color:white;
    font-weight:bold;
}
height: calc(100vh - 118px);
padding:150px 0;


   
`

export default StyledOffice