import React, { Component } from "react";
import { Map } from "../../../../components";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImage,
  CardTitle,
  CardText,
  Row,
  Col,
  Container,
  Fa,
  MDBInput,
  MDBDatePicker,
} from "mdbreact";
import StyledDetails from "./styledDetails";
class SelectedOfficeMap extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { office, onClick } = this.props;
    return (
      <StyledDetails>
        <Row>
          <Col
            size={office.coordinates.split(",").length == 2 ? "md-6" : "md-12"}
          >
            <div
              className="office-header-image"
              style={{ backgroundImage: `url(${office.coverPhotoUrl})` }}
            />
          </Col>
          {office.coordinates.split(",").length == 2 && (
            <Col size="md-6">
              <div className="map-container">
                <Map
                  movementDisabled
                  geojson={{
                    type: "FeatureCollection",
                    features: [
                      {
                        type: "Feature",
                        properties: {
                          href: `https://www.google.com/maps/place/${
                            office.address.address1
                          }, ${office.address.city},${
                            office.address.country
                          }/@${office.coordinates.split(",").reverse()}`,
                          name: `${office.address.address1}, ${office.address.city}, ${office.address.country}`,
                          "marker-color": "#0000ff",
                        },
                        geometry: {
                          type: "Point",
                          coordinates: office.coordinates.split(",").reverse(),
                        },
                      },
                    ],
                  }}
                />
                <a
                  target="_blank"
                  class="btn aqua-gradient"
                  href={`https://www.google.com/maps/place/${
                    office.address.address1
                  }, ${office.address.city},${
                    office.address.country
                  }/@${office.coordinates.split(",").reverse()}`}
                >
                  Open in Google Maps
                </a>
              </div>
            </Col>
          )}
        </Row>
      </StyledDetails>
    );
  }
}
export default SelectedOfficeMap;
