const defaultState = {
  resources: {},
  locations: [],
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case "GET_RESOURCES_SUCCESS": {
      window.localStorage.setItem("Resources", JSON.stringify(action.payload));
      return {
        ...state,
        resources: action.payload,
      };
    }
    case "GET_ALL_LOCATIONS_SUCCESS": {
      window.localStorage.setItem("Locations", JSON.stringify(action.payload));
      return {
        ...state,
        locations: action.payload,
      };
    }
    default:
      return state;
  }
};
