import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  Row,
  Col,
  COntainer,
  MDBBtn,
  MDBBtnGroup,
  MDBSelect,
} from "mdbreact";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import "react-dates/initialize";
import BootstrapTable from "react-bootstrap-table-next";
import { Bar } from "react-chartjs-2";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { GeoChart, KPISection } from "./components";
import { AverageDisplay } from "./components/KPISection/Averages";
import loadingSelectors from "../Loading/selectors";
import resourcesSelectors from "../Resources/selectors";
import resourcesActions from "../Resources/actions";
import bookingsSelectors from "../Bookings/selectors";
import selectors from "./selectors";
import actions from "./actions";
import ErrorBoundary from "./errorBoundary";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBDataTable,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBIcon,
} from "mdbreact";
import classnames from "classnames";
import media from "../../media";
const { SearchBar } = Search;

const combinedSelectors = (state) => ({
  ...selectors(state),
  ...resourcesSelectors(state),
  ...loadingSelectors(state),
  ...bookingsSelectors(state),
});

class SampleModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: "1",
      activeDashboard: "visits",
      startDate: moment(),
      endDate: moment(),
      selectedFloorIDs: [],
      focusedInput: null,
      selectedRangeType: "day",
      selectedLocationIDs:
        props.floorVisits.length === 0
          ? []
          : props.floorVisits.reduce((acc, curr) => {
              if (acc.indexOf(curr.locationId) === -1)
                acc.push(curr.locationId);
              return acc;
            }, []),
    };
  }
  toggle = (tab) => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };
  toggleActiveDashboard = (tab) => {
    if (this.state.activeDashboard !== tab) {
      this.setState({
        activeDashboard: tab,
      });
    }
  };
  fetchOfficeKPIs = () => {
    if (!this.state.startDate || !this.state.endDate) return;
    this.props.getDashboardLocationKPIs({
      locationIds: this.state.selectedLocationIDs,
      // buildingId: "",
      floorIds: this.state.selectedFloorIDs,
      startDate: this.state.startDate.format("YYYY-MM-DD"),
      endDate: this.state.endDate.format("YYYY-MM-DD"),
    });
  };
  componentDidMount() {
    this.props.getAverages();
    this.props.getDashboardBookings();
    this.props.getDashboardTotals();
    this.props.getDashboardLocations();
    this.fetchOfficeKPIs();
    this.props.getDashboardUsers();
    this.props.getAllLocations();
  }
  render() {
    const {} = this.state;
    const {
      averages,
      totals,
      bookings,
      locations,
      resources,
      dashboardUsers,
      locationKPIs,
      fullLocationsList,
    } = this.props;
    // const countriesHash = resources.countries.reduce((acc, curr) => {
    //   acc[curr.id] = acc[curr.id] ? acc[curr.id] : curr.name;
    //   return acc;
    // }, {});
    const mapHosts = locations.offices.reduce((acc, curr) => {
      acc[curr.countryName] = acc[curr.countryName]
        ? acc[curr.countryName] + curr.totalHosts
        : curr.totalHosts;
      return acc;
    }, {});
    const mapVisits = locations.offices.reduce((acc, curr) => {
      acc[curr.countryName] = acc[curr.countryName]
        ? acc[curr.countryName] + curr.totalVisits
        : curr.totalVisits;
      return acc;
    }, {});
    const mapGuests = mapVisits; //TODO integrate this with the API
    const columns = [
      {
        dataField: "id",
        text: "Id",
        sort: true,
        headerAttrs: {
          width: "65px",
        },
      },
      {
        dataField: "name",
        text: "Location",
        sort: true,
      },
      {
        dataField: "facilityRating",
        text: "Facility Rating",
        sort: true,
      },
      {
        dataField: "totalHosts",
        text: "Total Hosts",
        sort: true,
      },
      {
        dataField: "totalVisits",
        text: "Total Visits",
        sort: true,
      },
      {
        dataField: "averageStay",
        text: "Avg. stay (days)",
        sort: true,
      },
    ];
    return (
      <ErrorBoundary>
        <MDBRow className="mb-4">
          <MDBCol md="12">
            <MDBNav tabs color="primary" className="nav-justified">
              <MDBNavItem>
                <MDBNavLink
                  to="#"
                  className={classnames({
                    active: this.state.activeDashboard === "visits",
                  })}
                  onClick={(e) => {
                    e.preventDefault();
                    this.toggleActiveDashboard("visits");
                  }}
                  role="tab"
                >
                  Office KPI's
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink
                  to="#"
                  className={classnames({
                    active: this.state.activeDashboard === "hosts",
                  })}
                  onClick={(e) => {
                    e.preventDefault();
                    this.toggleActiveDashboard("hosts");
                  }}
                  role="tab"
                >
                  <MDBIcon /> Deskovery Overall KPI's
                </MDBNavLink>
              </MDBNavItem>
            </MDBNav>
            <MDBTabContent
              className="card"
              activeItem={this.state.activeDashboard}
            >
              <MDBTabPane tabId="hosts" role="tabpanel">
                <KPISection
                  data={{ averages, totals, bookings, locations, resources }}
                />
                <div className="mt-4">
                  <MDBRow className="mb-4">
                    <MDBCol md="12">
                      <MDBNav tabs color="primary" className="nav-justified">
                        <MDBNavItem>
                          <MDBNavLink
                            to="#"
                            className={classnames({
                              active: this.state.activeItem === "1",
                            })}
                            onClick={() => {
                              this.toggle("1");
                            }}
                            role="tab"
                          >
                            <MDBIcon /> Users
                          </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                          <MDBNavLink
                            to="#"
                            className={classnames({
                              active: this.state.activeItem === "2",
                            })}
                            onClick={() => {
                              this.toggle("2");
                            }}
                            role="tab"
                          >
                            Guests
                          </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                          <MDBNavLink
                            to="#"
                            className={classnames({
                              active: this.state.activeItem === "3",
                            })}
                            onClick={() => {
                              this.toggle("3");
                            }}
                            role="tab"
                          >
                            Hosts
                          </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                          <MDBNavLink
                            to="#"
                            className={classnames({
                              active: this.state.activeItem === "4",
                            })}
                            onClick={() => {
                              this.toggle("4");
                            }}
                            role="tab"
                          >
                            Visits
                          </MDBNavLink>
                        </MDBNavItem>
                      </MDBNav>
                      <MDBTabContent
                        className="card"
                        activeItem={this.state.activeItem}
                      >
                        <MDBTabPane tabId="1" role="tabpanel">
                          <GeoChart
                            data={[
                              ["Country", "Users"],
                              ...dashboardUsers.map((country) => [
                                country.key,
                                parseInt(country.count),
                              ]),
                            ]}
                          />
                        </MDBTabPane>
                        <MDBTabPane tabId="2" role="tabpanel">
                          <GeoChart
                            data={[
                              ["Country", "Guests"],
                              ...Object.keys(mapGuests).map((country) => {
                                return [country, parseInt(mapGuests[country])];
                              }),
                            ]}
                          />
                        </MDBTabPane>
                        <MDBTabPane tabId="3" role="tabpanel">
                          <GeoChart
                            data={[
                              ["Country", "Hosts"],
                              ...Object.keys(mapHosts).map((country) => {
                                return [country, mapHosts[country]];
                              }),
                            ]}
                          />
                        </MDBTabPane>
                        <MDBTabPane tabId="4" role="tabpanel">
                          <GeoChart
                            data={[
                              ["Country", "Visits"],
                              ...Object.keys(mapVisits).map((country) => {
                                return [country, mapVisits[country]];
                              }),
                            ]}
                          />
                        </MDBTabPane>
                      </MDBTabContent>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <Card>
                        <CardBody>
                          <ToolkitProvider
                            keyField="id"
                            data={locations.offices.map((office) => {
                              var tempOffice = { ...office };
                              tempOffice.name = (
                                <Link to={`/office/${tempOffice.id}`}>
                                  {tempOffice.name}
                                </Link>
                              );
                              tempOffice.averageStay = parseFloat(
                                office.averageStay
                              ).toFixed(1);
                              tempOffice.facilityRating = parseFloat(
                                office.facilityRating
                              ).toFixed(1);
                              tempOffice.totalHosts = parseFloat(
                                office.totalHosts
                              ).toFixed(1);
                              tempOffice.totalVisits = parseFloat(
                                office.totalVisits
                              ).toFixed(1);
                              delete tempOffice.countryName;
                              delete tempOffice.countryId;
                              delete tempOffice.totalSuperHosts;
                              return tempOffice;
                            })}
                            columns={columns}
                            search={{
                              searchFormatted: true,
                            }}
                          >
                            {(props) => (
                              <div>
                                {/* <h3>Input something at below input field:</h3>
                        <SearchBar {...props.searchProps} />
                        <hr /> */}
                                <BootstrapTable
                                  {...props.baseProps}
                                  bootstrap4
                                  pagination={paginationFactory()}
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        </CardBody>
                      </Card>
                    </MDBCol>
                  </MDBRow>
                </div>
              </MDBTabPane>
              <MDBTabPane tabId="visits" role="tabpanel">
                {/*
                .########.....###.....######..##....##....########..#######......#######..########.########.####..######..########
                .##.....##...##.##...##....##.##...##........##....##.....##....##.....##.##.......##........##..##....##.##......
                .##.....##..##...##..##.......##..##.........##....##.....##....##.....##.##.......##........##..##.......##......
                .########..##.....##.##.......#####..........##....##.....##....##.....##.######...######....##..##.......######..
                .##.....##.#########.##.......##..##.........##....##.....##....##.....##.##.......##........##..##.......##......
                .##.....##.##.....##.##....##.##...##........##....##.....##....##.....##.##.......##........##..##....##.##......
                .########..##.....##..######..##....##.......##.....#######......#######..##.......##.......####..######..########
                */}
                {locationKPIs && (
                  <>
                    <Row>
                      <Col md="6">
                        <div style={{ margin: "1.5rem 0" }}>
                          <MDBBtnGroup>
                            <MDBBtn
                              color={
                                this.state.selectedRangeType === "day"
                                  ? "primary"
                                  : "blue-grey"
                              }
                              size="sm"
                              onClick={(e) => {
                                this.setState(
                                  {
                                    selectedRangeType: "day",
                                    startDate: moment(),
                                    endDate: moment(),
                                  },
                                  this.fetchOfficeKPIs
                                );
                              }}
                            >
                              Today
                            </MDBBtn>
                            <MDBBtn
                              color={
                                this.state.selectedRangeType === "week"
                                  ? "primary"
                                  : "blue-grey"
                              }
                              size="sm"
                              onClick={(e) => {
                                this.setState(
                                  {
                                    selectedRangeType: "week",
                                    startDate: moment().startOf("isoWeek"),
                                    endDate: moment().endOf("isoWeek"),
                                  },
                                  this.fetchOfficeKPIs
                                );
                              }}
                            >
                              This week
                            </MDBBtn>
                            <MDBBtn
                              color={
                                this.state.selectedRangeType === "month"
                                  ? "primary"
                                  : "blue-grey"
                              }
                              size="sm"
                              onClick={(e) => {
                                this.setState(
                                  {
                                    selectedRangeType: "month",
                                    startDate: moment().startOf("month"),
                                    endDate: moment().endOf("month"),
                                  },
                                  this.fetchOfficeKPIs
                                );
                              }}
                            >
                              This month
                            </MDBBtn>
                            <MDBBtn
                              color={
                                this.state.selectedRangeType === "custom"
                                  ? "primary"
                                  : "blue-grey"
                              }
                              size="sm"
                              onClick={(e) =>
                                this.setState({ selectedRangeType: "custom" })
                              }
                            >
                              Custom range
                            </MDBBtn>
                          </MDBBtnGroup>
                        </div>
                        {this.state.selectedRangeType === "custom" && (
                          <div style={{ margin: "1.5rem 0" }}>
                            <DateRangePicker
                              startDateId="startDate"
                              endDateId="endDate"
                              isOutsideRange={() => false}
                              numberOfMonths={1}
                              block
                              minimumNights={0}
                              showDefaultInputIcon={true}
                              startDate={this.state.startDate}
                              endDate={this.state.endDate}
                              onDatesChange={({ startDate, endDate }) => {
                                // if (startDate || endDate) {
                                this.setState(
                                  {
                                    startDate: startDate,
                                    endDate: endDate,
                                  },
                                  this.fetchOfficeKPIs
                                );
                                // }
                              }}
                              firstDayOfWeek={1}
                              focusedInput={this.state.focusedInput}
                              onFocusChange={(focusedInput) => {
                                this.setState({ focusedInput });
                              }}
                            />
                          </div>
                        )}
                      </Col>
                      <Col md="6">
                        <MDBSelect
                          multiple
                          options={fullLocationsList.map((location) => ({
                            text: location.name,
                            value: location.id,
                            checked:
                              this.state.selectedLocationIDs.indexOf(
                                location.id
                              ) > -1
                                ? true
                                : false,
                          }))}
                          getValue={(selectedLocationIDs) => {
                            var isEqual =
                              JSON.stringify(
                                this.state.selectedLocationIDs.sort()
                              ) === JSON.stringify(selectedLocationIDs.sort());
                            if (isEqual) return;
                            this.setState(
                              { selectedLocationIDs, selectedFloorIDs: [] },
                              this.fetchOfficeKPIs
                            );
                          }}
                          selected="All offices"
                          label="Select offices"
                        />
                        <MDBSelect
                          multiple
                          options={fullLocationsList.reduce((acc, location) => {
                            if (
                              this.state.selectedLocationIDs.length !== 0 &&
                              this.state.selectedLocationIDs.indexOf(
                                location.id
                              ) === -1
                            )
                              return acc;
                            location.buildings.forEach((building) => {
                              building.floors.forEach((floor) => {
                                acc.push({
                                  text: `${location.name} / ${building.name} / ${floor.label}`,
                                  value: floor.id,
                                  checked:
                                    this.state.selectedFloorIDs.indexOf(
                                      floor.id
                                    ) > -1
                                      ? true
                                      : false,
                                });
                              });
                            });
                            return acc;
                          }, [])}
                          getValue={(selectedFloorIDs) => {
                            var isEqual =
                              JSON.stringify(
                                this.state.selectedFloorIDs.sort()
                              ) === JSON.stringify(selectedFloorIDs.sort());
                            if (isEqual) return;
                            this.setState(
                              { selectedFloorIDs },
                              this.fetchOfficeKPIs
                            );
                          }}
                          selected="All floors"
                          label="Select floors"
                        />
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col md="6" lg="4">
                        <AverageDisplay
                          icon={media["locationRating"]}
                          name="Average Duration of the booking"
                          value={locationKPIs.averageBookingDuration}
                          units="days"
                        />
                      </Col>
                      <Col md="6" lg="4">
                        <AverageDisplay
                          icon={media["locationRating"]}
                          name="% of Booked Desks"
                          value={locationKPIs.bookedDesksPercentage}
                          units="%"
                        />
                      </Col>
                      <Col md="6" lg="4">
                        <AverageDisplay
                          icon={media["locationRating"]}
                          name="Users that booked"
                          value={locationKPIs.bookedUsersCount}
                          units="users"
                        />
                      </Col>
                      <Col md="6" lg="4">
                        <AverageDisplay
                          icon={media["locationRating"]}
                          name="Available desks in the office/day"
                          value={locationKPIs.availableDesksCount}
                          units="desks"
                        />
                      </Col>
                      <Col md="6" lg="4">
                        <AverageDisplay
                          icon={media["locationRating"]}
                          name="Total Desk bookings"
                          value={locationKPIs.bookedDesksCount}
                          units="desks"
                        />
                      </Col>
                    </Row>
                    {locationKPIs.weeklyAvailability && (
                      <>
                        <hr />
                        <Row>
                          <Col>
                            <Bar
                              data={{
                                datasets: [
                                  // {
                                  //   label: "Unavailable desks",
                                  //   data: [80, 80, 80, 80, 80, 50, 100],
                                  //   backgroundColor: "gray",
                                  // },
                                  {
                                    label: "Booked desks",
                                    data: [
                                      ...locationKPIs.weeklyAvailability.filter(
                                        (el, i) => i !== 0
                                      ),
                                      locationKPIs.weeklyAvailability[0],
                                    ].map((data) => data.bookedDesks),
                                    backgroundColor: "#f76b1c",
                                  },
                                  {
                                    label: "Available desks",
                                    data: [
                                      ...locationKPIs.weeklyAvailability.filter(
                                        (el, i) => i !== 0
                                      ),
                                      locationKPIs.weeklyAvailability[0],
                                    ].map((data) => data.availableDesks),
                                    backgroundColor: "#2c80d9",
                                  },
                                ],
                                labels: [
                                  "Monday",
                                  "Tuesday",
                                  "Wednesday",
                                  "Thursday",
                                  "Friday",
                                  "Saturday",
                                  "Sunday",
                                ],
                              }}
                              options={{
                                scales: {
                                  xAxes: [
                                    {
                                      stacked: true,
                                    },
                                  ],
                                  yAxes: [
                                    {
                                      stacked: true,
                                    },
                                  ],
                                },
                              }}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                    {locationKPIs.dailyAvailability && (
                      <>
                        <hr />
                        <Row>
                          <Col>
                            <Bar
                              data={{
                                datasets: [
                                  // {
                                  //   label: "Unavailable desks",
                                  //   data: [80, 80, 80, 80, 80, 50, 100],
                                  //   backgroundColor: "gray",
                                  // },
                                  {
                                    label: "Booked desks",
                                    data: locationKPIs.dailyAvailability.map(
                                      (entry) => entry.bookedDesks
                                    ),
                                    backgroundColor: "#f76b1c",
                                  },
                                  {
                                    label: "Available desks",
                                    data: locationKPIs.dailyAvailability.map(
                                      (data) => data.availableDesks
                                    ),
                                    backgroundColor: "#2c80d9",
                                  },
                                ],
                                labels: locationKPIs.dailyAvailability.map(
                                  (entry) => entry.date
                                ),
                              }}
                              options={{
                                scales: {
                                  xAxes: [
                                    {
                                      stacked: true,
                                    },
                                  ],
                                  yAxes: [
                                    {
                                      stacked: true,
                                    },
                                  ],
                                },
                              }}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </>
                )}
              </MDBTabPane>
            </MDBTabContent>
          </MDBCol>
        </MDBRow>
      </ErrorBoundary>
    );
  }
}
export default connect(combinedSelectors, { ...actions, ...resourcesActions })(
  withRouter(SampleModule)
);
