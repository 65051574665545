import React, { Component, Fragment } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  MDBMedia,
  MDBBadge,
  MDBIcon,
} from "mdbreact";
import ErrorBoundary from "../../errorBoundary";
import Averages from "./Averages";
import media from "../../../../media";
import Chart from "react-google-charts";
import { Rating } from "../../../../components";

class KPISection extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      data: { averages, bookings, totals, locations, resources },
    } = this.props;
    return (
      <Fragment>
        <Row>
          <Col style={{ marginBottom: 20 }} className="col-lg-4 col-xl-12">
            <Averages averages={averages} />
          </Col>
          <Col style={{ marginBottom: 20 }} size="lg-4">
            <Card style={{ minHeight: "100%" }}>
              <CardHeader>
                <Row>
                  <Col size="8">
                    {" "}
                    <h5>Total Users: </h5>
                  </Col>
                  <Col middle size="4">
                    <h4 className="font-weight-bold text-right">
                      <MDBBadge color="primary">{totals.users}</MDBBadge>
                    </h4>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Chart
                  width={"100%"}
                  height={"250px"}
                  chartType="BarChart"
                  loader={<div>Loading Chart</div>}
                  data={[
                    ["Label", "Users", "Hosts", "Guests"],
                    [
                      "",
                      parseInt(totals.users),
                      parseInt(totals.hosts),
                      parseInt(totals.guests),
                    ],
                  ]}
                  options={{
                    chartArea: { top: 20, width: "100%" },
                    legend: "bottom",
                  }}
                  // For tests
                  rootProps={{ "data-testid": "1" }}
                />
              </CardBody>
            </Card>
          </Col>
          <Col style={{ marginBottom: 20 }} size="lg-4">
            <Card style={{ minHeight: "100%" }}>
              <CardHeader>
                <Row>
                  <Col size="8">
                    {" "}
                    <h5>Total Locations: </h5>
                  </Col>
                  <Col middle size="4">
                    <h4 className="font-weight-bold text-right">
                      <MDBBadge color="primary">{locations.total}</MDBBadge>
                    </h4>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: 0 }}>
                <Chart
                  width={"100%"}
                  height={"250px"}
                  chartType="PieChart"
                  loader={<div>Loading...</div>}
                  data={[
                    ["Locations", "#"],
                    ["Visited Locations", locations.visited],
                    ["Not Visited Locations", locations.notVisited],
                  ]}
                  options={{
                    colors: ["#2C80D9", "#F76B1C"],
                    pieHole: 0.5,
                    pieSliceText: "value",
                    chartArea: { top: 20, width: "50%", height: "75%" },
                    legend: "bottom",
                  }}
                  rootProps={{ "data-testid": "3" }}
                />
              </CardBody>
            </Card>
          </Col>
          <Col style={{ marginBottom: 20 }} size="lg-4">
            <Card style={{ minHeight: "100%" }}>
              <CardHeader>
                <Row>
                  <Col size="9">
                    <h5>Total Hosting requests:</h5>
                  </Col>
                  <Col middle size="3">
                    <h4 className="font-weight-bold text-right">
                      <MDBBadge color="primary">{bookings.total}</MDBBadge>
                    </h4>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row style={{ marginBottom: "25px" }}>
                  <Col className="font-weight-bold" size="8">
                    <h3>
                      {/* {(function() {
                          throw new Error('Something went wrong');
                        })()} */}
                      {bookings.accepted} <small>Accepted</small>
                    </h3>
                  </Col>
                  <Col size="4" className="text-right">
                    <MDBIcon
                      className="text-success"
                      size="2x"
                      icon="thumbs-o-up"
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: "25px" }}>
                  <Col className="font-weight-bold" size="8">
                    <h3>
                      {" "}
                      {bookings.pending} <small>Pending</small>
                    </h3>
                  </Col>
                  <Col size="4" className="text-right">
                    <MDBIcon
                      className="text-warning"
                      size="2x"
                      icon="hourglass"
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: "25px" }}>
                  <Col className="font-weight-bold" size="8">
                    <h3>
                      {" "}
                      {bookings.canceled} <small>Rejected</small>
                    </h3>
                  </Col>
                  <Col size="4" className="text-right">
                    <MDBIcon
                      className="text-danger"
                      size="2x"
                      icon="thumbs-o-down"
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          {totals.benefits && totals.benefits.length > 0 && (
            <Col style={{ marginBottom: 20 }} className="col-md-8 col-xl-12">
              <Card style={{ minHeight: "100%" }}>
                <CardHeader>
                  <Row>
                    <Col size="8">
                      <h5>User benefits</h5>
                    </Col>
                    <Col middle size="4">
                      {/* <h4 className="font-weight-bold text-right">
                        Total reviews: <MDBBadge color="primary">{totals.reviews}</MDBBadge>
                      </h4> */}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Chart
                    width={"100%"}
                    height={"250px"}
                    chartType="BarChart"
                    loader={<div>Loading Chart</div>}
                    data={[
                      [
                        "label",
                        ...totals.benefits.map((benefit) => {
                          return (
                            resources.benefits.filter(
                              (res) => res.id === benefit.benefitId
                            )[0].name || "Other"
                          );
                        }),
                      ],
                      ["", ...totals.benefits.map((benefit) => benefit.count)],
                    ]}
                    options={{
                      chartArea: { top: 20, left: 20, width: "60%" },
                      legend: "right",
                    }}
                    // For tests
                    rootProps={{ "data-testid": "1" }}
                  />
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </Fragment>
    );
  }
}

export default KPISection;
