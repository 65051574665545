import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button, Row, Col } from "mdbreact";
import OfficeCard from "../Offices/components/OfficeCard";
import resourcesSelectors from "../Resources/selectors";
import selectors from "./selectors";
import actions from "./actions";

const StyledTabs = styled.ul`
  border-bottom: 1px solid #acacac;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 0 34px;
  padding: 0;
  text-align: center;
  li {
    color: #4a4a4a;
    100%display: inline-block;
    font-size: 22px;
    line-height: 1.2;
    list-style: none;
    padding-bottom: 18px;
    position: relative;
    @media (min-width: 768px) {
      font-size: 24px;
      font-size: 45px;
      padding-bottom: 30px;
    }
    + li {
      margin-left: 60px;
    }
    &.active:after {
      background: linear-gradient(90deg, #ed8949, #f76b1c);
      border-radius: 5px;
      bottom: 0;
      content: "";
      display: block;
      height: 10px;
      left: 50%;
      max-width: 200px;
      position: absolute;
      transform: translateX(-50%);
      width: 100%;
    }
  }
`;
class LocationsModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLocations: "topLocations"
    };
  }
  componentDidMount() {
    this.props.getTopLocations();
  }
  render() {
    const { selectedLocations } = this.state;
    return (
      <div>
        <StyledTabs>
          <li
            className={selectedLocations === "topLocations" ? "active" : ""}
            onClick={() => {
              this.setState({ selectedLocations: "topLocations" });
            }}
          >
            Top Locations
          </li>
        </StyledTabs>

        <React.Fragment>
          <Row style={{ marginBottom: 20 }}>
            <Col className="text-center">
              <p style={{ fontSize: 25 }}>
                {selectedLocations === "topLocations"
                  ? this.props.resources.texts &&
                    this.props.resources.texts[0].strings.filter(
                      text => text.key === "homeTopLocationsDescription"
                    )[0].translatedText
                  : "Nestlé would like to suggest bellow three locations"}
              </p>
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            {this.props[selectedLocations].map(office => (
              <Col className="col-12 col-sm-6 col-md-4">
                <OfficeCard office={office} />
              </Col>
            ))}
          </Row>
          <Row>
            <Col className="text-center">
              <Link
                style={{ color: "white", fontWeight: "bold" }}
                to="/offices"
              >
                <Button className="peach-gradient">More destinations</Button>
              </Link>
            </Col>
          </Row>
        </React.Fragment>
      </div>
    );
  }
}

export default connect(
  state => ({
    ...selectors(state),
    ...resourcesSelectors(state)
  }),
  actions
)(LocationsModule);
