import approved from "./approved.svg";
import averageDuration from "./averageDuration.svg";
import canceled from "./canceled.svg";
import desk from "./desk.png";
import deskoveryLogo from "./deskoveryLogo.svg";
import deskoveryLogoWhite from "./deskoveryLogoWhite.svg";
import destination from "./destination.svg";
import email from "./email.svg";
import experience from "./experience.svg";
import host from "./host.svg";
import hostRating from "./hostRating.svg";
import inGeniusLogo from "./InGeniusLogo.svg";
import locationRating from "./locationRating.svg";
import pending from "./pending.svg";
import phone from "./phone.svg";
import team from "./team.png";
import thankful from "./thankful.svg";
import workplace from "./workplace.svg";
import tour_1 from "./tour_1.jpg";
import tour_2 from "./tour_2.png";
import tour_3 from "./tour_3.png";
import tour_4 from "./tour_4.png";
import introTour from "./introTour.mp4";
import introTourCover from "./introTour_cover.jpg";
import introTourCoverOld from "./introTour_cover_old.jpg";
import hostIntro from "./hostIntro.jpg";

export default {
  approved,
  averageDuration,
  canceled,
  desk,
  deskoveryLogo,
  deskoveryLogoWhite,
  destination,
  email,
  experience,
  host,
  hostIntro,
  hostRating,
  inGeniusLogo,
  introTour,
  introTourCover,
  introTourCoverOld,
  locationRating,
  pending,
  phone,
  team,
  thankful,
  tour_1,
  tour_2,
  tour_3,
  tour_4,
  workplace,
};
