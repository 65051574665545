import React from "react";
import { MDBBtn as Button, Card, CardBody, Animation } from "mdbreact";
import { Link } from "react-router-dom";
import StyledLandingSection from "./StyledLandingSection.js";

export default props => (
  <StyledLandingSection>
    <div className="guest-container">
      <Animation type="fadeInRight">
        <Card>
          <CardBody>
            <h2>Visit another Nestle Facility worldwide</h2>
            <Link to="/offices">
              <Button gradient="peach">Explore Destinations</Button>
            </Link>
          </CardBody>
        </Card>
      </Animation>
    </div>
    <div className="host-container">
      <Animation type="fadeInLeft">
        <Card>
          <CardBody>
            <h2>Host other collegues in your country</h2>
            <Button gradient="blue">Become a Host</Button>
          </CardBody>
        </Card>
      </Animation>
    </div>
  </StyledLandingSection>
);
