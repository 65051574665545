import styled from 'styled-components'
const StyledDetails = styled.div`
padding:60px;
.files{
    background-color:rgb(235,235,235);
    height:110px;
    text-align:center;
    padding:40px;
}
.info{
    background-color:white;
    padding:30px;
}
.info-content{
    margin-bottom:20px;
}
h2{
    font-size:15px;
    font-weight:bold;
}
.description{
    margin-top:30px;
    background-color:white;
    padding:30px;
    margin-bottom:20px;
}
.continue-btn{
    float:right;
}
   
`

export default StyledDetails