import React, { Component } from 'react';
import MainLayout from '../layouts/MainLayout';
import UserDetailsContainer from '../modules/Users/containers/userDetailsContainer'

class UserDetailsView extends Component {
    render() {
        const { match } = this.props;
        return (
        <MainLayout>
            <UserDetailsContainer userId={match.params.id}></UserDetailsContainer>
        </MainLayout>
        )    
    }
}


export default UserDetailsView