import React, { Component } from "react";
import { connect } from "react-redux";
import Autosuggest from "react-autosuggest";
import {
  MDBRow as Row,
  MDBCol as Col,
  Card,
  CardBody,
  CardHeader,
  MDBBtn as Button,
  MDBBtnGroup,
} from "mdbreact";
import { selectors as resourseSelectors } from "../../../Resources";
import { adalApiFetch } from "../../../../modules/Auth";
import config from "../../../../config";
import StyledAutocomplete from "./styledAutoComplete";
import { history } from "../../../../App";
class BrowsingFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: this.props.filters.query || "",
      locationTypeIds: [],
      suggestions: [],
      limit: 8,
      offset: 0,
    };
  }
  onChange = (event, { newValue }) => {
    this.setState({ query: newValue });
  };
  handleKeyDown = (e) => {
    if (e.keyCode == 13) {
      this.props.onSubmit(this.state);
    }
  };
  onSuggestionsFetchRequested = ({ value }) => {
    this.getSuggestions();
  };
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };
  getSuggestions = () => {
    const { query } = this.state;
    adalApiFetch(
      fetch,
      config.api.endpoints.offices.suggest + `?text=${query}&top=30`
    )
      .then((res) => res.json())
      .then((suggestions) => this.setState({ suggestions }))
      .catch((error) => console.log(error));
  };

  render() {
    const { query, suggestions } = this.state;
    const { onSubmit } = this.props;
    const inputProps = {
      placeholder: "Explore all available Nestlé offices.",
      value: query,
      className: "form-control",
      onChange: this.onChange,
      onKeyDown: this.handleKeyDown,
    };

    return (
      <Row className="mb-5">
        <Col>
          <Card>
            <CardHeader>
              Browse destinations by City, Country or Building Name
            </CardHeader>
            <CardBody>
              <Row>
                <Col middle size="12">
                  <MDBBtnGroup style={{ width: "100%" }}>
                    <StyledAutocomplete>
                      <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={
                          this.onSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          this.onSuggestionsClearRequested
                        }
                        getSuggestionValue={(suggestion) => {
                          history.push(`/office/${suggestion.id}`);
                          return suggestion.name;
                        }}
                        renderSuggestion={(suggestion) => suggestion.name}
                        inputProps={inputProps}
                      />
                    </StyledAutocomplete>
                    <Button onClick={() => onSubmit(this.state)} color="orange">
                      Search
                    </Button>
                  </MDBBtnGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}
export default connect(resourseSelectors)(BrowsingFilters);
