import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  MDBBtn as Button,
  Container,
  Fa,
  MDBBtnGroup,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem
} from "mdbreact";
import styled from "styled-components";
import media from "../../../media";
import { SmallOfficeDetails } from "../components";
import { UserPicture } from "../../../components";
import loadingSelectors from "../../../modules/Loading/selectors";
import authSelectors from "../../../modules/Auth/selectors";
import { AddReviewButton } from "../../Reviews/components";
import selectors from "../selectors";
import actions from "../actions";
import { isRegExp } from "util";

const combinedSelectors = state => ({
  ...selectors(state),
  ...authSelectors(state),
  ...loadingSelectors(state)
});

const StyledVisits = styled.div`
  th {
    color: #2c80d9;
    text-transform: uppercase;
    font-weight: 300;
  }
`;

class MyGuestsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { } = this.state;
    const { bookings, params, updateBooking, match, userData } = this.props;
    const sortedBooking = Object.values(bookings).sort((a, b) => {
      if (
        a.bookingStatus.id == 1 &&
        a.startDateTimeStamp * 1000 < new Date().getTime()
      )
        return 1;
      if (a.bookingStatus.id > b.bookingStatus.id) return 1;
      if (a.bookingStatus.id < b.bookingStatus.id) return -1;
      if (a.startDateTimeStamp < b.startDateTimeStamp) return 1;
      if (a.startDateTimeStamp > b.startDateTimeStamp) return -1;
      return 0;
    });
    const data = {
      columns: [
        {
          label: "Office",
          field: "office",
          sort: "asc"
        },
        {
          label: "Guest",
          field: "guest",
          sort: "asc"
        },
        {
          label: "Guest Rating",
          field: "rating",
          sort: "asc"
        },
        {
          label: "Period",
          field: "period",
          sort: "asc"
        },
        {
          label: "Status",
          field: "status",
          sort: "asc"
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc"
        }
      ],
      rows: sortedBooking
        .filter(
          booking =>
            (booking.host === null && booking.guest.id !== userData.id) ||
            (booking.guest.id !== userData.id &&
              booking.host.id === userData.id)
        )
        .map((booking, i) => {
          return {
            office: <SmallOfficeDetails office={booking.location} />,
            guest: (
              <Link to={`/user/${booking.guest.id}`}>
                <UserPicture
                  avatar={booking.guest.photoUrl}
                  name={booking.guest.name}
                />
              </Link>
            ),
            rating:
              booking.guest.rating > 0 ? (
                <div>
                  <span style={{ color: "#FFBD02" }}>
                    <Fa icon="star" />
                  </span>{" "}
                  {parseFloat(booking.guest.rating).toFixed(1)}
                </div>
              ) : (
                  "not rated"
                ),
            period: (
              <div>
                {[
                  `${new Date(
                    booking.startDateTimeStamp * 1000
                  ).toLocaleDateString()}`,
                  `${new Date(
                    booking.endDateTimeStamp * 1000
                  ).toLocaleDateString()}`
                ].map((date, i) => (
                  <div
                    style={{ display: "block" }}
                    className="text-nowrap"
                  >
                    {date}
                  </div>
                ))}
              </div>
            ),
            status: (
              <span>
                {booking.bookingStatus.id == 1 &&
                  booking.startDateTimeStamp * 1000 > new Date().getTime() &&
                  booking.bookingStatus.name}
                {booking.bookingStatus.id == 1 &&
                  booking.startDateTimeStamp * 1000 < new Date().getTime() &&
                  "Expired"}
                {booking.bookingStatus.id == 3 && booking.bookingStatus.name}
                {booking.bookingStatus.id == 2 &&
                  booking.startDateTimeStamp * 1000 > new Date().getTime() &&
                  "Approved"}
                {booking.bookingStatus.id == 2 &&
                  booking.endDateTimeStamp * 1000 < new Date().getTime() &&
                  "Completed"}
              </span>
            ),
            actions: (
              <div>
                {booking.startDateTimeStamp * 1000 > new Date().getTime() &&
                  booking.bookingStatus.id < 3 && (
                    <MDBDropdown>
                      <MDBDropdownToggle
                        size="sm"
                        caret
                        color="info"
                        className="h-100"
                      >
                        Actions
                      </MDBDropdownToggle>
                      <MDBDropdownMenu basic color="info">
                        <MDBDropdownItem
                          href={`/user/${booking.guest.id}`}
                          target="_blank"
                        >
                          {booking.guest.name.split(" ")[0]}'s Profile
                        </MDBDropdownItem>
                        <MDBDropdownItem
                          href={booking.location.applicationUrl}
                          target="_blank"
                        >
                          Visitor Application
                        </MDBDropdownItem>
                        {booking.startDateTimeStamp * 1000 >
                          new Date().getTime() && (
                            <React.Fragment>
                              <MDBDropdownItem divider />
                              {booking.bookingStatus.id == 1 && (
                                <MDBDropdownItem
                                  onClick={() =>
                                    updateBooking({
                                      id: booking.id,
                                      statusId: 2,
                                      hostId: userData.id
                                    })
                                  }
                                >
                                  Approve Request
                              </MDBDropdownItem>
                              )}
                              <MDBDropdownItem
                                onClick={() => {
                                  updateBooking({ id: booking.id, statusId: 4 });
                                }}
                              >
                                Reject Request
                            </MDBDropdownItem>
                            </React.Fragment>
                          )}
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  )}
                {booking.bookingStatus.id == 2 &&
                  booking.endDateTimeStamp * 1000 < new Date().getTime() &&
                  booking.guestReviewDto === null && (
                    /* Users can add reviews only on approved requests, which have end Date before the currentTime, meaning the request is complete  */
                    <AddReviewButton
                      // component={MDBDropdownItem}
                      booking={booking}
                      showModal={
                        params.action === "review" && parseInt(params.id) === i
                      }
                      size="sm"
                    />
                  )}
              </div>
            )
          };
        })
    };
    return (
      <div>
        {data.rows.length > 0 && (
          <StyledVisits>
            <h1
              style={{
                fontSize: 45,
                color: "#4A4A4A",
                fontWeight: "500",
                margin: "30px auto"
              }}
            >
              My Guests
            </h1>
            {data.rows.length > 0 && (
              <MDBTable responsive btn>
                <MDBTableHead>
                  <tr>
                    <th style={{ width: 300 }}>Office</th>
                    <th>Guest</th>
                    <th>Guest rating</th>
                    <th>Period</th>
                    <th>Status</th>
                    <th />
                  </tr>
                </MDBTableHead>
                <MDBTableBody rows={data.rows} />
              </MDBTable>
            )}
          </StyledVisits>
        )}
        {data.rows.length == 0 && (
          <div
            style={{
              backgroundImage: `radial-gradient(closest-side, rgba(244, 249, 252, 0.48), rgb(244, 249, 252)), url("${
                media["team"]
                }")`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              justifyContent: "center",
              padding: "100px 30px",
              textAlign: "center"
            }}
          >
            <h1
              style={{
                textTransform: "capitalize",
                fontSize: 45,
                color: "#4A4A4A",
                fontWeight: "500",
                margin: "0 auto 20px"
              }}
            >
              You don't have any visitors yet.
            </h1>
          </div>
        )}
      </div>
    );
  }
}
export default connect(
  combinedSelectors,
  actions
)(MyGuestsContainer);
