import React from 'react';

import './UserConsentForm.scss';

import { Container, Row, Col, Input } from 'mdbreact';

export default function UserConsentForm({ onSubmit, isAccepted, onAccept }) {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit && onSubmit();
      }}
      className='userConsentForm'
    >
      <Container>
        <h3>Deskovery Terms of Use </h3>

        <span className='updateInfo'>Last updated: 3 Aug. 2020</span>

        <Row>
          <Col lg='6'>
            <p>
              Deskovery is a Nestlé internal platform (“
              <strong>Deskovery</strong>") for authorized users to book office
              space at their chosen Nestlé site (“<strong>Site</strong>”) based
              on the available capacity (“<strong>Purpose</strong>”). These
              Deskovery Terms of Use (“<strong>Terms</strong>”) govern your use
              of Deskovery. "<strong>We</strong>", "<strong>us</strong>", "
              <strong>our</strong>" and "<strong>Nestlé</strong>" mean the
              Nestlé company operating the Site or granting you access to
              Deskovery, as applicable; and “<strong>you</strong>” “
              <strong>your</strong>” and “<strong>yours</strong>” mean any
              person with Nestlé log-in credentials. Nestlé reserves the right
              to make changes to these Terms, effective when posted on
              Deskovery.{' '}
              <strong>
                By accessing and using Deskovery, you agree to these Terms.
              </strong>
            </p>

            <p>
              <strong>Your Use</strong>. Deskovery helps you to use office space
              efficiently. As a user, you can book office space, review and
              change your own bookings. You agree to (i) book a space only when
              you are sure that you will be present at that time, and not make
              any tentative reservations which may prevent others from using
              office space, and (ii) change or cancel your booking as soon as
              you no longer need it. If you are a site manager or providing IT
              support, you will be a ‘super user’. As a super user, you can
              additionally (i) configure office space on a given Site, (ii)
              generate reports to manage office space for efficient use of
              available slots, and (iii) make multiple bookings.
            </p>

            <p>
              You will use Deskovery solely for the Purpose, and for no other
              purpose. For example, you will only use reports to manage bookings
              and office space and not for workforce management purposes.
            </p>

            <p>
              On rare occasions, bookings may show as confirmed even though
              there is no longer any available office space. If this happens,
              please discuss with your Office Manager for possible options.
            </p>

            <p>
              <strong>
                It is your responsibility to learn about and comply with the
                policies and regulations applicable in the Site. Due to lower
                capacity, certain areas of the Site may be unattended. You may
                not have access to any area if you are not authorized.
              </strong>
            </p>

            <p>
              <strong>Accessibility</strong>. If you are a user with
              disabilities, and you need assistance or have questions about
              accessibility on Deskovery or the Site, please contact the Site
              Manager. Your browsing experience may improve by altering your
              software and device settings (e.g. text size) or using assistive
              technologies.
            </p>

            <p>
              <strong>Intellectual Property; Confidentiality</strong>. You
              acknowledge that Deskovery and all intellectual property rights
              thereof are owned by Nestlé or its respective licensors. As a
              super user, you may have access to bookings made by other users.
              You shall treat all contents of Deskovery as confidential and not
              disclose them to anyone. You shall not change, decompile, reverse
              engineer or copy or derive the source code of Deskovery.
            </p>
          </Col>

          <Col lg='6'>
            <p>
              <strong>Access and Security</strong>. Only authorized users may
              have access to and use Deskovery. You shall at all times comply
              with Nestlé’s security requirements communicated to you or
              published on Deskovery. You must keep your log-in credentials
              confidential and secure, and not share with anyone.{' '}
            </p>

            <p>
              <strong>Data Protection</strong>. Nestlé will process your
              personal data to process bookings and manage office space in
              accordance with the Nestlé Privacy Policy applicable to you (e.g.
              as an employee or as stated in your consultancy agreement). Nestlé
              reserves the right to monitor your access and activity in
              Deskovery for legitimate business interests.
            </p>

            <p>
              <strong>Disclaimer; Limitation of Liability; Indemnity</strong>.
              Deskovery is provided as-is and as available. To the fullest
              extent permitted by law, Nestlé expressly excludes any
              representation or warranty and disclaims all liability for any
              loss or damage in respect of Deskovery or its content, and our
              liability to you in connection with these is only to take such
              actions as are reasonably required (in our sole discretion) to
              delete or correct your booking. Nothing in these Terms excludes or
              limits our liability for fraud, fraudulent misrepresentation or
              for death or personal injury arising from our negligence or any
              other liability that cannot be excluded or restricted by
              applicable laws. You agree to indemnify Nestlé, its officers,
              directors and employees from any loss, damages, claims or expenses
              incurred by them due to or arising out of your breach of these
              Terms.
            </p>

            <p>
              <strong>Contact</strong>. In case of questions on Deskovery,
              please contact Michal Janowski. In case of questions on your
              booking or the Site, for example, you need accessibility
              assistance, please contact the Site Manager designated for the
              Site in question.
            </p>

            <p>
              <strong>General</strong>. Nestlé reserves the right to remove any
              content or restrict your access to or use of Deskovery at any
              time. Breach of these Terms may subject you to disciplinary action
              up to and including termination of your employment or contract
              with Nestlé, as well as legal action. These Terms are governed by
              the local laws where the Site is established, and any dispute or
              matter in relation to these Terms shall be submitted to the
              exclusive jurisdiction of the local courts where the Site is
              established.
            </p>
          </Col>
        </Row>

        <div className='checkboxContainer'>
          <Input
            label='I Accept Terms & Conditions'
            checked={isAccepted}
            onChange={onAccept}
            id="cb1"
            type='checkbox'
          />
        </div>
      </Container>
    </form>
  );
}
