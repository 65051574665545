import { adalApiFetch } from "../Auth";
import config from "../../config";

const getBookingsStart = () => ({
  type: "GET_BOOKINGS_START",
});

const getBookingsSuccess = (payload) => ({
  type: "GET_BOOKINGS_SUCCESS",
  payload,
});

const getBookingsError = (payload) => ({
  type: "GET_BOOKINGS_ERROR",
  payload,
});

export const getBookings = (filters) => async (dispatch, getState) => {
  if (getState().Bookings.isFetching) {
    return;
  }
  dispatch(getBookingsStart());
  try {
    const bookings = await adalApiFetch(
      fetch,
      config.api.endpoints.bookings.list
    ).then((res) => {
      if (!res.ok || res.status !== 200) {
        throw new Error(
          "Call to endpoint " +
            res.url +
            " failed with status code " +
            res.status
        );
      }
      if (res.headers.get("content-type").indexOf("application/json") === -1) {
        throw new TypeError(
          'Response from "' + res.url + '" has unexpected "content-type"'
        );
      }
      return res.json();
    });
    return dispatch(getBookingsSuccess(bookings));
  } catch (error) {
    console.log(error);
    return dispatch(getBookingsError(error));
  }
};

export const updateBooking = (booking) => async (dispatch) => {
  try {
    const updatedBooking = await adalApiFetch(
      fetch,
      config.api.endpoints.bookings.update,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(booking),
      }
    ).then((res) => {
      if (!res.ok || res.status !== 200) {
        throw new Error(
          "Call to endpoint " +
            res.url +
            " failed with status code " +
            res.status
        );
      }
      if (res.headers.get("content-type").indexOf("application/json") === -1) {
        throw new TypeError(
          'Response from "' + res.url + '" has unexpected "content-type"'
        );
      }
      return res.json();
    });
    dispatch({
      type: "UPDATE_BOOKING_SUCCESS",
      payload: updatedBooking,
    });
    return dispatch(getBookings());
  } catch (error) {
    console.log(error);
    return dispatch(getBookingsError(error));
  }
};

export const createBooking = (booking) => async (dispatch) => {
  try {
    await adalApiFetch(fetch, config.api.endpoints.bookings.create, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(booking),
    });
    return dispatch(getBookings());
  } catch (error) {
    console.log(error);
  }
};

/*
.########.##........#######...#######..########.....########...#######...#######..##....##.####.##....##..######..
.##.......##.......##.....##.##.....##.##.....##....##.....##.##.....##.##.....##.##...##...##..###...##.##....##.
.##.......##.......##.....##.##.....##.##.....##....##.....##.##.....##.##.....##.##..##....##..####..##.##.......
.######...##.......##.....##.##.....##.########.....########..##.....##.##.....##.#####.....##..##.##.##.##...####
.##.......##.......##.....##.##.....##.##...##......##.....##.##.....##.##.....##.##..##....##..##..####.##....##.
.##.......##.......##.....##.##.....##.##....##.....##.....##.##.....##.##.....##.##...##...##..##...###.##....##.
.##.......########..#######...#######..##.....##....########...#######...#######..##....##.####.##....##..######..
*/

const getFloorVisitsStart = () => ({
  type: "GET_FLOOR_VISITS_START",
});

const getFloorVisitsSuccess = (payload) => ({
  type: "GET_FLOOR_VISITS_SUCCESS",
  payload,
});

const getFloorVisitsError = (payload) => ({
  type: "GET_FLOOR_VISITS_ERROR",
  payload,
});

export const getFloorVisits = () => async (dispatch, getState) => {
  if (getState().Bookings.isFetchingFloorVisits) {
    return;
  }
  dispatch(getFloorVisitsStart());
  try {
    const bookings = await adalApiFetch(
      fetch,
      config.api.endpoints.floorBookings.list
    ).then((res) => {
      if (!res.ok || res.status !== 200) {
        throw new Error(
          "Call to endpoint " +
            res.url +
            " failed with status code " +
            res.status
        );
      }
      if (res.headers.get("content-type").indexOf("application/json") === -1) {
        throw new TypeError(
          'Response from "' + res.url + '" has unexpected "content-type"'
        );
      }
      return res.json();
    });
    return dispatch(getFloorVisitsSuccess(bookings));
  } catch (error) {
    console.log(error);
    return dispatch(getFloorVisitsError(error));
  }
};

export const visitFloor = (
  floorId,
  startDate,
  endDate,
  modifiedVisitId
) => async (dispatch) => {
  try {
    await adalApiFetch(
      fetch,
      `${
        config.api.endpoints.floorBookings.create
      }?floorId=${floorId}&startDate=${startDate}&endDate=${endDate}${
        modifiedVisitId ? `&modifiedVisitId=${modifiedVisitId}` : ""
      }`,
      {
        method: "POST",
      }
    ).then((res) => {
      if (!res.ok || res.status !== 200) {
        throw new Error(
          "Call to endpoint " +
            res.url +
            " failed with status code " +
            res.status
        );
      }
      return true;
    });
    return dispatch(getFloorVisits());
  } catch (error) {
    console.log(error);
    return dispatch(getFloorVisitsError(error));
  }
};

export const cancelVisit = (visitId, callback) => async (dispatch) => {
  try {
    const response = await adalApiFetch(
      fetch,
      `${config.api.endpoints.floorBookings.delete}?id=${visitId}`,
      {
        method: "POST",
      }
    );

    callback && response.ok && callback();
    return dispatch(getFloorVisits());
  } catch (error) {
    console.log(error);
  }
};

export default {
  getBookings,
  createBooking,
  updateBooking,
  getFloorVisits,
  visitFloor,
  cancelVisit,
};
