import React, { Component } from 'react';
import MainLayout from '../layouts/MainLayout';
import OfficeDetailsContainer from '../modules/Offices/containers/officeDetailsContainer'

const AddOfficeView = ({match}) => (
    <MainLayout>
        <OfficeDetailsContainer officeId={match.params.id}></OfficeDetailsContainer>
    </MainLayout>
)


export default AddOfficeView