import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { Button } from "mdbreact";
import media from "../../../../media";
import resourcesSelectors from "../../../Resources/selectors";

const SlidesContainer = styled.div`
  position: relative;
`;
const Slide = styled.div`
  width: 100%;
  opacity: ${(props) => (props.active ? 1 : 0)};
  top: 0;
  left: 0;
  padding-bottom: 30px;
  position: ${(props) => (props.active ? "relative" : "absolute")};
  visibility: ${(props) => (props.active ? "visible" : "hidden")};
  min-height: 500px;
  transition: 0.3s;
`;
const Pager = styled.ul`
  display: flex;
  max-width: 200px;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin: -15px auto 15px;
  padding: 0;
  li {
    display: inline-block;
    font-size: 0;
    width: 10px;
    height: 10px;
    background: #4285f4;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0.5;
    &.active {
      opacity: 1;
    }
    + li {
      margin-left: 5px;
    }
  }
`;

const SliderImage = styled.div`
  background-image: url('${(props) => props.src}');
  background-position: center;
  background-size: 100% 100%;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 10px 30px -10px rgba(0,0,0,.5);
  &:before {
    content: '';
    box-sizing: border-box;
    display: block;
    padding-bottom: 56.25%;
  }
`;

class CarouselPage extends Component {
  constructor(props) {
    super(props);

    const stringsHashmap = !this.props.resources.texts
      ? {}
      : this.props.resources.texts[0].strings.reduce((acc, curr) => {
          acc[curr.key] = curr;
          return acc;
        }, {});
    console.log(stringsHashmap);
    this.state = {
      modalOpen: true,
      activeSlide: 0,
      slides: [
        {
          id: 0,
          content: (
            <div>
              <h2 style={{ fontSize: 30, marginBottom: 10 }}>
                Welcome to Deskovery,{" "}
                <strong>{props.userData.name.split(" ")[0]}</strong>{" "}
              </h2>
              {/* {stringsHashmap["tourSlideOneTopDescription"] && (
                <p>
                  {stringsHashmap["tourSlideOneTopDescription"].translatedText}
                </p>
              )} */}
              <SliderImage src={media["introTourCover"]} />
              <p>
                <br />
                During COVID-19, we adjusted Deskovery to support our employees
                and site mangers planning the return to the office.
              </p>
              {/* {stringsHashmap["tourSlideOneBottomDescription"] && (
                <p>
                  {
                    stringsHashmap["tourSlideOneBottomDescription"]
                      .translatedText
                  }
                </p>
              )} */}
            </div>
          ),
        },
        {
          id: 1,
          content: (
            <div>
              {/* {stringsHashmap["tourSlideTwoTitle"] && (
                <h2 style={{fontSize: 30}}>{stringsHashmap["tourSlideTwoTitle"].translatedText} </h2>
              )} */}
              <h2 style={{ fontSize: 30, marginBottom: 10 }}>Find a Desk</h2>
              {/* {stringsHashmap["tourSlideTwoTopDescription"] && (
                <p>
                  {stringsHashmap["tourSlideTwoTopDescription"].translatedText}
                </p>
              )} */}
              <SliderImage src={media["tour_1"]} />
              <p>
                <br />
                Browse for your desired Nestlé location. To book a desk select
                the dates you wish to visit, the building and floor based on
                availability. A safe office environment will be prepared to
                receive you on the day.
              </p>
            </div>
          ),
        },
        {
          id: 2,
          content: (
            <div>
              {/* {stringsHashmap["tourSlideThreeTitle"] && (
                <h2 style={{fontSize: 30}}>{stringsHashmap["tourSlideThreeTitle"].translatedText} </h2>
              )} */}
              <h2 style={{ fontSize: 30, marginBottom: 10 }}>
                Post COVID-19 – Book a Host
              </h2>
              {/* {stringsHashmap["tourSlideThreeTopDescription"] && (
                <p>
                  {
                    stringsHashmap["tourSlideThreeTopDescription"]
                      .translatedText
                  }
                </p>
              )} */}
              <SliderImage src={media["hostIntro"]} />
              <p>
                <br />
                Deskovery helps you to find and access desk in Nestlé offices
                around the globe, whether you’re planning a vacation or a
                business trip! You will be able to book a colleague to host you.
                A host is a local employee that will ensure you get the most out
                of your visit.
              </p>
            </div>
          ),
        },
        {
          id: 3,
          content: (
            <div>
              {/* {stringsHashmap["tourSlideThreeTitle"] && (
                <h2 style={{fontSize: 30}}>{stringsHashmap["tourSlideThreeTitle"].translatedText} </h2>
              )} */}
              <h2 style={{ fontSize: 30, marginBottom: 10 }}>
                Post COVID-19 – Become a Host
              </h2>
              {/* {stringsHashmap["tourSlideThreeTopDescription"] && (
                <p>
                  {
                    stringsHashmap["tourSlideThreeTopDescription"]
                      .translatedText
                  }
                </p>
              )} */}
              <SliderImage src={media["introTourCoverOld"]} />
              <p>
                <br />
                Moreover, you will be able to become a host! It takes less than
                a minute! Your role is to welcome colleagues on the first day of
                their stay, get them an access badge and show them around. Just
                sign up to become a host yourself and choose your Nestlé
                location.
              </p>
            </div>
          ),
        },
        {
          id: 4,
          content: (
            <div>
              {/* {stringsHashmap["tourSlideFourTitle"] && (
                <h2 style={{fontSize: 30}}>{stringsHashmap["tourSlideFourTitle"].translatedText} </h2>
              )} */}
              <h2 style={{ fontSize: 30, marginBottom: 10 }}>
                Help improve Deskovery
              </h2>
              {/* {stringsHashmap["tourSlideFourTopDescription"] && (
                <p>
                  {stringsHashmap["tourSlideFourTopDescription"].translatedText}
                </p>
              )} */}
              <SliderImage src={media["tour_3"]} />
              <p>
                <br />
                Feedback helps us constantly improve. Give recognition to
                colleagues that have gone the extra mile and tell us how
                Deskovery has benefited you
              </p>
            </div>
          ),
        },
        {
          id: 5,
          content: (
            <div>
              {/* {stringsHashmap["tourSlideFiveTitle"] && (
                <h2 style={{fontSize: 30}}>{stringsHashmap["tourSlideFiveTitle"].translatedText} </h2>
              )} */}
              <h2 style={{ fontSize: 30, marginBottom: 10 }}>
                Follow the Deskovery growth
              </h2>
              {/* {stringsHashmap["tourSlideFiveTopDescription"] && (
                <p>
                  {stringsHashmap["tourSlideFiveTopDescription"].translatedText}
                </p>
              )} */}
              <SliderImage src={media["tour_4"]} />
              <p>
                <br />
                Use the Dashboard to track the growth of Deskovery users, hosts
                and visits
              </p>
            </div>
          ),
        },
      ],
    };
  }
  goToSlide = (id) => {
    if (this.state.slides.filter((slide) => slide.id === id).length > 0) {
      this.setState({ activeSlide: id });
    }
  };
  next = () => {
    if (this.state.activeSlide < this.state.slides.length) {
      this.setState({ activeSlide: this.state.activeSlide + 1 });
    }
  };
  prev = () => {
    if (this.state.activeSlide > 1) {
      this.setState({ activeSlide: this.state.activeSlide - 1 });
    }
  };
  render() {
    const { activeSlide, slides } = this.state;
    const { onFinish } = this.props;
    return (
      <div>
        <SlidesContainer>
          {slides.map((slide) => (
            <Slide active={activeSlide == slide.id}>{slide.content}</Slide>
          ))}
        </SlidesContainer>
        <Pager>
          {slides.map((slide) => (
            <li
              className={activeSlide === slide.id ? "active" : null}
              onClick={() => {
                this.goToSlide(slide.id);
              }}
            >
              {slide.id}
            </li>
          ))}
        </Pager>
        <div className="text-center">
          {/* {activeSlide > 1 && <Button onClick={this.prev}>Prev</Button>} */}
          <Button
            gradient="peach"
            onClick={activeSlide < slides.length - 1 ? this.next : onFinish}
          >
            {activeSlide < slides.length - 1 ? "Next" : "Finish"}
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(resourcesSelectors)(CarouselPage);
