import React, { Component } from "react";
import { adalApiFetch } from "../../../Auth";
import config from "../../../../config";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  MDBMedia,
  MDBBadge,
  MDBIcon,
} from "mdbreact";
import media from "../../../../media";

export const AverageDisplay = ({ icon, name, value, units }) => (
  <Card style={{ minWidth: 300 }}>
    <CardBody>
      <MDBMedia>
        <MDBMedia body>
          {name}
          <MDBMedia heading>
            {value} {units}
          </MDBMedia>
        </MDBMedia>
        <MDBMedia right className="ml-3">
          <img src={icon} alt={name} />
        </MDBMedia>
      </MDBMedia>
    </CardBody>
  </Card>
);

class Averages extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { averages } = this.props;
    return (
      <Row>
        <Col style={{ marginBottom: 20, flexBasis: 360, flexShrink: 0 }}>
          {averages.durationOfStay !== undefined && (
            <AverageDisplay
              icon={media["averageDuration"]}
              name="Average Duration of Stay"
              value={averages.durationOfStay.toFixed(1)}
              units="days"
            />
          )}
        </Col>
        <Col style={{ marginBottom: 20, flexBasis: 360, flexShrink: 0 }}>
          {averages.locationRating !== undefined && (
            <AverageDisplay
              icon={media["locationRating"]}
              name="Average Location Rating"
              value={averages.locationRating.toFixed(1)}
              units="/5 stars"
            />
          )}
        </Col>
        <Col style={{ marginBottom: 20, flexBasis: 360, flexShrink: 0 }}>
          {averages.hostRating !== undefined && (
            <AverageDisplay
              icon={media["hostRating"]}
              name="Average Hosts Rating"
              value={averages.hostRating.toFixed(1)}
              units="/5 stars"
            />
          )}
        </Col>
      </Row>
    );
  }
}

export default Averages;
