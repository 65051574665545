import React, { Component } from 'react';
import { MDBContainer } from 'mdbreact';
import MainLayout from '../layouts/MainLayout';
import OnboardingModule from '../modules/Onboarding/container';
import LocationsModule from '../modules/Locations/container';
import HomeDeskoverySection from '../components/HomeDeskoverySection';
import HomeHost from '../components/HomeHost';
import FindDesk from '../components/FindDesk';
import RecentVisits from '../modules/Bookings/containers/RecentVisits/RecentVisits';
class HomeView extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const { history } = this.props;
    if (!window.localStorage.getItem('userOnboarded')) {
      window.localStorage.setItem('userOnboarded', true);
      history.push('/tour');
    }
  }
  render() {
    const { match } = this.props;
    return (
      <MainLayout>
        <FindDesk />

        <RecentVisits />

        <MDBContainer>
          <HomeDeskoverySection />
        </MDBContainer>

        <div style={{ background: 'white', padding: '94px 0 55px' }}>
          <MDBContainer>
            <LocationsModule />
          </MDBContainer>
        </div>

        <MDBContainer>
          <OnboardingModule showModal={match.path === '/tour'} />
        </MDBContainer>

        {/* <HomeHost showModal={match.path==='/become-host'}/> */}
      </MainLayout>
    );
  }
}

export default HomeView;
