import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Container,
  Fa,
  MDBContainer,
  MDBBtn as Button,
  MDBIcon,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBTabContent,
  MDBTabPane,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import styled from "styled-components";
import media from "../../../media";
import { SmallOfficeDetails } from "../components";
import { UserPicture } from "../../../components";
import loadingSelectors from "../../Loading/selectors";
import authSelectors from "../../Auth/selectors";
import { AddReviewButton } from "../../Reviews/components";
import selectors from "../selectors";
import actions from "../actions";
import { userData } from "../../Auth/selectors";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import PlanVisitModal from "../../Offices/components/PlanVisit/Modal";
import { createDateWithHours } from "../../../utilities/dateHelpers";
import moment from "moment";
import { toast } from "mdbreact";

const combinedSelectors = (state) => ({
  ...selectors(state),
  ...authSelectors(state),
  ...loadingSelectors(state),
});

const StyledVisits = styled.div`
  th {
    color: #2c80d9;
    text-transform: uppercase;
    font-weight: 300;
  }

  .completedLabel {
    display: block;
    margin: 0.375rem;
    text-align: center;
  }

  .btnContainer {
    text-align: center;

    button {
      min-width: 120px;
    }
  }
`;

class MyVisitsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      confirmationModal: null,
      showPlanVisitModal: false,
      currentVisit: null,
      modification: false,
    };
  }

  toggleJustified = (tab) => (e) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    const {
      confirmationModal,
      showPlanVisitModal,
      currentVisit,
      modification,
    } = this.state;
    const { floorVisits, bookings, params, updateBooking, userData } =
      this.props;

    const sortedFloorVisits = [
      ...floorVisits
        .filter(
          (fv) => createDateWithHours(new Date(fv.endDate), 12) >= new Date()
        )
        .sort((a, b) => {
          const timestampA = new Date(a.startDate).getTime();
          const timestampB = new Date(b.startDate).getTime();
          return timestampA > timestampB ? -1 : timestampA < timestampB ? 1 : 0;
        }),
      ...floorVisits
        .filter(
          (fv) => createDateWithHours(new Date(fv.endDate), 12) < new Date()
        )
        .sort((a, b) => {
          const timestampA = new Date(a.startDate).getTime();
          const timestampB = new Date(b.startDate).getTime();
          return timestampA > timestampB ? -1 : timestampA < timestampB ? 1 : 0;
        }),
    ];

    const sortedBookings = Object.values(bookings).sort((a, b) => {
      if (
        a.bookingStatus.id === 1 &&
        a.startDateTimeStamp * 1000 < new Date().getTime()
      )
        return 1;
      if (a.bookingStatus.id > b.bookingStatus.id) return 1;
      if (a.bookingStatus.id < b.bookingStatus.id) return -1;
      if (a.startDateTimeStamp < b.startDateTimeStamp) return 1;
      if (a.startDateTimeStamp > b.startDateTimeStamp) return -1;
      return 0;
    });

    const visitsData = {
      columns: [
        {
          label: "Office",
          field: "location",
        },
        {
          label: "Building",
          field: "building",
        },
        {
          label: "Floor",
          field: "floor",
        },
        {
          label: "Start Date",
          field: "startDate",
        },
        {
          label: "End Date",
          field: "endDate",
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
        },
      ],
      rows: sortedFloorVisits.map((sortedFloorVisit) => {
        const { id, location, building, floor, startDate, endDate } =
          sortedFloorVisit;

        const endDateAtNoonObj = createDateWithHours(new Date(endDate), 12);

        return {
          location,
          building,
          floor,
          startDate: new Date(startDate).toLocaleDateString(),
          endDate: new Date(endDate).toLocaleDateString(),
          actions: (
            <>
              {endDateAtNoonObj <= new Date() ? (
                <span className="completedLabel">Completed</span>
              ) : (
                <>
                  <div className="btnContainer">
                    <Button
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          confirmationModal: {
                            id,
                            text: "You are about to cancel one of your visits.",
                            confirmText: "Confirm",
                            cancelText: "Back",
                            type: "cancellation",
                          },
                          currentVisit: sortedFloorVisit,
                        })
                      }
                      size="sm"
                      color="danger"
                    >
                      Cancel Visit
                    </Button>
                  </div>

                  <div className="btnContainer">
                    <Button
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          confirmationModal: {
                            id,
                            text: "You are about to modify one of your visits.",
                            confirmText: "Modify",
                            type: "modification",
                          },
                          currentVisit: sortedFloorVisit,
                          modification: true,
                        })
                      }
                      size="sm"
                      color="warning"
                    >
                      Modify visit
                    </Button>
                  </div>
                </>
              )}

              <div className="btnContainer">
                <Button
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      showPlanVisitModal: true,
                      currentVisit: sortedFloorVisit,
                      modification: false,
                    })
                  }
                  size="sm"
                  color="primary"
                >
                  Rebook Location
                </Button>
              </div>
            </>
          ),
        };
      }),
    };

    const data = {
      columns: [
        {
          label: "Office",
          field: "office",
          sort: "asc",
          width: 300,
        },
        {
          label: "Location Rating",
          field: "rating",
          sort: "asc",
        },
        {
          label: "Period",
          field: "period",
          sort: "asc",
        },
        {
          label: "Host",
          field: "host",
          sort: "asc",
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
        },
      ],
      rows: sortedBookings
        .filter((booking) => booking.guest && booking.guest.id === userData.id)
        .map((booking, i) => {
          return {
            office: <SmallOfficeDetails office={booking.location} />,
            rating: (
              <div>
                <span style={{ color: "#FFBD02" }}>
                  <Fa icon="star" />
                </span>{" "}
                {parseFloat(booking.location.facilityRating).toFixed(1)}
              </div>
            ),
            period: (
              <div>
                {[
                  `${new Date(
                    booking.startDateTimeStamp * 1000
                  ).toLocaleDateString()}`,
                  `${new Date(
                    booking.endDateTimeStamp * 1000
                  ).toLocaleDateString()}`,
                ].map((date, i) => (
                  <div style={{ display: "block" }} className="text-nowrap">
                    {date}
                  </div>
                ))}
              </div>
            ),
            host: (
              <div>
                {booking.host && (
                  <Link to={`/user/${booking.host.id}`}>
                    <UserPicture
                      avatar={booking.host.photoUrl}
                      name={booking.host.name}
                    />
                  </Link>
                )}
                {booking.host == null && "Random host"}
              </div>
            ),
            status: (
              <span>
                {booking.bookingStatus.id == 1 &&
                  booking.startDateTimeStamp * 1000 > new Date().getTime() &&
                  booking.bookingStatus.name}
                {booking.bookingStatus.id == 1 &&
                  booking.startDateTimeStamp * 1000 < new Date().getTime() &&
                  "Expired"}
                {booking.bookingStatus.id == 3 && booking.bookingStatus.name}
                {booking.bookingStatus.id == 2 &&
                  booking.startDateTimeStamp * 1000 > new Date().getTime() &&
                  "Approved"}
                {booking.bookingStatus.id == 2 &&
                  booking.endDateTimeStamp * 1000 < new Date().getTime() &&
                  "Completed"}
              </span>
            ),
            actions: (
              <div>
                {/* if the booking is upcoming but it is not yet canceled, you can cancel it */}
                {booking.startDateTimeStamp * 1000 > new Date().getTime() &&
                  booking.bookingStatus.id < 3 && (
                    <Button
                      onClick={() =>
                        updateBooking({
                          id: booking.id,
                          statusId: 3,
                          hostId: null,
                        })
                      }
                      size="sm"
                      color="danger"
                    >
                      Cancel
                    </Button>
                  )}
                {/* if the booking is upcoming but it is canceled, you can swap hosts
                {booking.startDateTimeStamp * 1000 > new Date().getTime() &&
                  booking.bookingStatus.id == 3 && (
                    <Link to={`/office/${booking.location.id}`}>
                      <Button size="sm" className="text-nowrap">
                        Pick another host
                      </Button>
                    </Link>
                  )} */}
                {booking.bookingStatus.id == 2 &&
                  booking.endDateTimeStamp * 1000 < new Date().getTime() &&
                  booking.hostLocationReviewDto === null && (
                    <AddReviewButton
                      booking={booking}
                      showModal={
                        params.action === "review" && parseInt(params.id) === i
                      }
                      size="sm"
                    />
                  )}
              </div>
            ),
          };
        }),
    };

    return (
      <div>
        <StyledVisits>
          <h1
            style={{
              fontSize: 45,
              color: "#4A4A4A",
              fontWeight: "500",
              margin: "30px auto",
            }}
          >
            My Visits
          </h1>
          <MDBContainer>
            <MDBNav tabs className="nav-justified" color="primary">
              <MDBNavItem>
                <MDBNavLink
                  link
                  to="#"
                  active={this.state.activeTab === "1"}
                  onClick={this.toggleJustified("1")}
                  role="tab"
                >
                  <MDBIcon far icon="building" /> Booked Desks
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink
                  link
                  to="#"
                  active={this.state.activeTab === "2"}
                  onClick={this.toggleJustified("2")}
                  role="tab"
                >
                  <MDBIcon far icon="user" /> Booked Hosts
                </MDBNavLink>
              </MDBNavItem>
            </MDBNav>
            <MDBTabContent className="card" activeItem={this.state.activeTab}>
              <MDBTabPane tabId="1" role="tabpanel">
                {visitsData.rows.length > 0 && (
                  <MDBTable responsive btn>
                    <MDBTableHead>
                      <tr>
                        <th style={{ width: 300 }}>Office</th>
                        <th>Building</th>
                        <th>Floor</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody rows={visitsData.rows} />
                  </MDBTable>
                )}
              </MDBTabPane>
              <MDBTabPane tabId="2" role="tabpanel">
                {data.rows.length > 0 && (
                  <MDBTable responsive btn>
                    <MDBTableHead>
                      <tr>
                        <th style={{ width: 300 }}>Office</th>
                        <th>Location rating</th>
                        <th>Period</th>
                        <th>Host</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody rows={data.rows} />
                  </MDBTable>
                )}
              </MDBTabPane>
            </MDBTabContent>
          </MDBContainer>
        </StyledVisits>
        <ConfirmationModal
          open={confirmationModal}
          bodyText={confirmationModal ? confirmationModal.text : ""}
          onClose={() => this.handleCancel()}
          onCancel={() => this.handleCancel()}
          confirmText={confirmationModal ? confirmationModal.confirmText : ""}
          cancelText={confirmationModal ? confirmationModal.cancelText : ""}
          onConfirm={() => this.handleConfirm()}
        />

        {currentVisit && showPlanVisitModal && (
          <PlanVisitModal
            key={currentVisit.locationId}
            office={undefined}
            officeId={currentVisit.locationId}
            buildingId={currentVisit.buildingId}
            buildingName={currentVisit.building}
            showModal={showPlanVisitModal}
            onToggle={() =>
              this.setState({
                ...this.state,
                showPlanVisitModal: !showPlanVisitModal,
              })
            }
            currentVisit={modification ? currentVisit : undefined}
            hideRebookModal
          />
        )}
      </div>
    );
  }

  handleConfirm = () => {
    const { cancelVisit } = this.props;
    const { confirmationModal } = this.state;

    if (!confirmationModal) {
      return;
    }

    switch (confirmationModal.type) {
      case "modification":
        this.setState({
          ...this.state,
          confirmationModal: null,
          showPlanVisitModal: true,
        });
        return;
      case "cancellation":
        if (
          this.state.currentVisit &&
          moment(this.state.currentVisit.startDate).isBefore(
            moment(`${moment().format("YYYY-MM-DD")} : 00:00`)
          )
        ) {
          this.props
            .visitFloor(
              this.state.currentVisit.floorId,
              this.state.currentVisit.startDate,
              moment(`${moment().format("YYYY-MM-DD")} : 00:00`)
                .subtract(1, "days")
                .format("YYYY-MM-DD"),
              this.state.currentVisit.id
            )
            .then((action) => {
              if (action.type === "GET_FLOOR_VISITS_ERROR") {
                toast.error(`Your was not cancelled. Please try again later`, {
                  position: "top-center",
                });
              }
              this.setState({
                ...this.state,
                confirmationModal: null,
                currentVisit: null,
              });
            });

          return;
        }

        cancelVisit(confirmationModal.id, () =>
          this.setState({
            ...this.state,
            confirmationModal: null,
            currentVisit: null,
          })
        );

        return;

      default:
        return;
    }
  };

  handleCancel = () => {
    this.setState({
      ...this.state,
      confirmationModal: null,
      currentVisit: null,
    });
  };
}

export default connect(combinedSelectors, actions)(MyVisitsContainer);
