import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card, CardBody, Spinner } from "mdbreact";
import styled from "styled-components";
import { adalApiFetch } from "../../Auth";
import config from "../../../config";
import { } from "../components";
import selectors from "../selectors";
import actions from "../actions";
import { selectors as authSelectors } from "../../Auth";
import { actions as authActions } from "../../Auth";
import { container as ReviewsContainer } from "../../Reviews";
import UserHeader from "../components/UserHeader";
import { UserOffices, AddOfficesButton } from "../../Offices/components";

const StyledStats = styled.div`
  margin-bottom: 20px;
  strong {
    line-height: 1;
    display: block;
    font-size: 50px;
    color: #f76b1c;
  }
`;

const StyledTabs = styled.div`
  font-weight: 500;
  font-size: 30px;
  padding-top: 55px;
  > span {
    opacity: 0.5;
    transition: 0.2s;
    &:hover,
    &.active {
      opacity: 1;
    }
    + span {
      margin-left: 30px;
    }
  }
`;

const combinedSelectors = state => ({
  ...selectors(state),
  ...authSelectors(state)
});
const combinedActions = {
  ...actions,
  ...authActions
};

class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserId: props.userId,
      tab: "received",
      userOffices: []
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const { userId, getUsers } = nextProps;
    if (userId !== prevState.currentUserId) {
      return {
        currentUserId: userId
      };
    } else {
      return prevState;
    }
  }
  componentDidMount() {
    this.getUserProfileData(this.state.currentUserId);
  }
  getSnapshotBeforeUpdate(prevProps, prevState) {
    const { currentUserId } = prevState;
    if (this.state.currentUserId !== currentUserId) {
      this.getUserProfileData(this.state.currentUserId);
    }
  }
  getUserProfileData = userId => {
    const { getUsers } = this.props;
    getUsers({ userIds: [userId] });
    adalApiFetch(fetch, config.api.endpoints.offices.list, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userId,
        limit: 100
      })
    })
      .then(res => res.json())
      .then(data => {
        this.setState({
          userOffices: data.offices.map(result => result.office)
        });
      })
      .catch(e => console.log(e));
  };
  render() {
    const { userOffices, tab } = this.state;
    const { users, userId, userData } = this.props;
    const currentUser = users[userId];
    if (!currentUser) {
      return (
        <div className="text-center pt-5 pb-5">
          <Spinner />
        </div>
      );
    }
    return (
      <div>
        <Container>
          <Row>
            <Col size="lg-4">
              <UserHeader currentUser={currentUser} />
              <StyledStats>
                <Card>
                  <Row className="pl-3 pr-3">
                    <Col className="pt-3 pb-3" size="6">
                      Visited Locations
                      <strong>{currentUser.visitedLocationIds.length}</strong>
                    </Col>
                    <Col className="pt-3 pb-3" size="6">
                      Hosted Guests
                      <strong>{currentUser.hostedGuests.length}</strong>
                    </Col>
                  </Row>
                </Card>
              </StyledStats>
              {userOffices && (
                <div>
                  <UserOffices
                    offices={userOffices}
                    user={currentUser}
                    authenticatedUser={userData}
                    getUserProfileData={this.getUserProfileData}
                  />
                </div>
              )}
            </Col>
            <Col size="lg-8">
              <StyledTabs>
                <span
                  className={tab === "received" ? "active" : null}
                  onClick={() => this.setState({ tab: "received" })}
                >
                  Reviews about {currentUser.name.split(" ")[0]}
                </span>{" "}
                <span
                  className={tab === "given" ? "active" : null}
                  onClick={() => this.setState({ tab: "given" })}
                >
                  {currentUser.name.split(" ")[0]}'s Reviews
                </span>
              </StyledTabs>
              <Card
                style={{
                  display: tab === "received" ? "block" : "none",
                  minHeight: "calc(100% - 100px)",
                  overflow: "auto"
                }}
              >
                <CardBody>
                  <ReviewsContainer
                    // reviewTypeId={1}
                    userId={currentUser.id}
                    author="reviewer" // reviewer - reviews about me; receiver - reviews from me 
                  />
                </CardBody>
              </Card>
              <Card
                style={{
                  display: tab === "given" ? "block" : "none",
                  minHeight: "calc(100% - 100px)",
                  overflow: "auto"
                }}
              >
                <CardBody>
                  <ReviewsContainer
                    // reviewTypeId={2} //1 - reviews written as a host; 2 - reviews written as guest
                    userId={currentUser.id}
                    author="receiver"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default connect(
  combinedSelectors,
  combinedActions
)(UserDetails);
