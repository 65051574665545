import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import { Card, CardBody, CardFooter, Row, Col, Fa } from "mdbreact";
import "./styles.scss";
import media from "../../../../media";
import { actions, selectors } from "../../../Auth";

const StyledUserPicture = styled.div`
    max-width: 120px;
    margin: 0 auto;
    border-radius: 50%;
    box-shadow: 0 6px 10px rgba(0,0,0,.5);
    background: #ccc url('${props =>
      props.avatar}') center center / cover no-repeat;
    &:before {
        content: '';
        display: block;
        box-sizing: border-box;
        width: 100%;
        padding-bottom: 100%;
    }
`;

class UserHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSelectChange = e => {
    this.setState({});
  };
  render() {
    const { currentUser } = this.props;
    return (
      <div className="user-header">
        <Card style={{ marginTop: 80 }}>
          <CardBody className="text-center">
            <StyledUserPicture
              style={{ marginTop: -80 }}
              avatar={currentUser.photoUrl}
            />
            <h2>{currentUser.name}</h2>
            {currentUser.jobTitle && (
              <p className="text-muted">{currentUser.jobTitle}</p>
            )}
            <div>
              {currentUser.phone && (
                <a href={`tel:${currentUser.phone}`} target="_blank">
                  <img src={media["phone"]} />
                </a>
              )}
              {currentUser.workplaceUrl && (
                <a href={`${currentUser.workplaceUrl}`} target="_blank">
                  <img src={media["workplace"]} />
                </a>
              )}
              {currentUser.email && (
                <a href={`mailto:${currentUser.email}`} target="_blank">
                  <img src={media["email"]} />
                </a>
              )}
            </div>
          </CardBody>
          <CardFooter style={{ paddingTop: 0, paddingBottom: 0 }}>
            <Row>
              <Col size="6" className="pt-2 pb-2 text-left">
                {currentUser.userType.name}
              </Col>
              <Col
                size="6"
                className="pt-2 pb-2 text-right"
                style={{ borderLeft: "1px solid #ccc" }}
              >
                <span
                  style={{
                    fontSize: "16px"
                  }}
                >
                  <Fa icon="star" className="amber-text" />{" "}
                  {currentUser.overallRating
                    ? `${parseFloat(currentUser.overallRating).toFixed(
                        2
                      )} Rating`
                    : "Not rated yet"}
                </span>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </div>
    );
  }
}
export default connect(
  selectors,
  actions
)(UserHeader);
