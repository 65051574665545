import styled from 'styled-components'
const StyledDetails = styled.div`
.row{
    margin-right: 0px;
}
.col-md-6{
    padding: 0px;
}
.office-header-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 150px;
    width: 100%;
    @media (min-width: 768px) {
        height: 400px;
    }
}
.map-container{
    position:relative;
    display: none;
    @media (min-width: 768px) {
        display: block;
    }
    .btn{
        position:absolute;
        bottom:20px;
        right:20px;
    }
}
`

export default StyledDetails