import styled from "styled-components";

const StyledSection = styled.div`
  background: rgb(244, 249, 252);
  padding: 60px 0;
  h1 {
    font-weight: bold;
    text-align: center;
  }
  hr {
    background-color: rgb(240, 127, 59);
    width: 190px;
    height: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  h2 {
    font-weight: bold;
    font-size: 20px;
    padding: 20px 0;
  }
  .description {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 70px;
    text-align: center;
    @media (min-width: 768px) {
      width: 50%;
    }
  }
  .home-host {
    background-color: rgb(57, 66, 194);
  }
  h3 {
    font-weight: bold;
    color: white;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  button {
    margin: 0 auto;
    margin-bottom: 40px;
    background-color: rgb(238, 132, 66);
    border: none;
    padding: 8px;
    color: white;
  }
`;

export default StyledSection;
