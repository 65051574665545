import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  MDBContainer,
  MDBSpinner as Loader,
  MDBBtn as Button,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBIcon,
} from "mdbreact";
import {
  SelectedOfficeMap,
  SelectedOfficeDetails,
  OfficeHosts,
  OfficeBuildings,
} from "../components";
import selectors from "../selectors";
import actions from "../actions";
import usersActions from "../../Users/actions";
import usersSelectors from "../../Users/selectors";
import currentUserSelectors from "../../Auth/selectors";
import { container as ReviewsContainer } from "../../Reviews";

const combinedSelectors = (state) => ({
  ...selectors(state),
  ...usersSelectors(state),
  ...currentUserSelectors(state),
});
const combinedActions = {
  ...actions,
  ...usersActions,
};

class OfficeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentOfficeId: props.officeId,
      activeTab: "1",
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const { officeId } = nextProps;
    if (officeId != prevState.currentOfficeId) {
      return {
        currentOfficeId: officeId,
      };
    } else {
      return prevState;
    }
  }
  componentDidMount() {
    this.getOfficeDetailsData(this.state.currentOfficeId);
  }
  getSnapshotBeforeUpdate(prevProps, prevState) {
    const { currentOfficeId } = prevState;
    if (this.state.currentOfficeId !== currentOfficeId) {
      this.getOfficeDetailsData(this.state.currentOfficeId);
    }
  }
  getOfficeDetailsData = (officeId) => {
    const { offices, getOffices, getUsers } = this.props;
    getOffices({ locationIds: [officeId], activeOnly: false });
    getUsers({ locationIds: [officeId], userTypeIds: [2] }, true);
  };
  toggleJustified = (tab) => (e) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };
  render() {
    const {} = this.state;
    const { officeId, offices, users, userData, fetchingOffices } = this.props;

    const selectedOffice =
      offices && Object.values(offices).length
        ? offices[officeId] || null
        : null;
    const hosts = Object.values(users).filter((user) => {
      return (
        // user.id !== userData.id && // remove current user from hosts list
        user.hostedLocationIds.indexOf(parseInt(officeId, 10)) > -1 // show only users that are hosts in that location
      );
    });
    return (
      <div>
        {fetchingOffices && (
          <div className="text-center pt-5 pb-5">
            <Loader />
          </div>
        )}
        {selectedOffice && (
          <div>
            <SelectedOfficeMap office={selectedOffice} />
            <SelectedOfficeDetails office={selectedOffice} />

            <MDBContainer>
              <MDBNav tabs className="nav-justified" color="primary">
                <MDBNavItem>
                  <MDBNavLink
                    link
                    to="#"
                    active={this.state.activeTab === "1"}
                    onClick={this.toggleJustified("1")}
                    role="tab"
                  >
                    <MDBIcon far icon="building" /> Book a Desk
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    link
                    to="#"
                    active={this.state.activeTab === "2"}
                    onClick={this.toggleJustified("2")}
                    role="tab"
                  >
                    <MDBIcon far icon="user" /> Book a Host
                  </MDBNavLink>
                </MDBNavItem>
              </MDBNav>
              <MDBTabContent className="card" activeItem={this.state.activeTab}>
                <MDBTabPane tabId="1" role="tabpanel">
                  <OfficeBuildings
                    office={selectedOffice}
                    hosts={hosts}
                    userData={userData}
                  />
                </MDBTabPane>
                <MDBTabPane tabId="2" role="tabpanel">
                  {hosts.length == 0 ? (
                    <div className="text-center">
                      <Button
                        gradient="peach"
                        href={`mailto:deskovery@nestle.com?subject=Requesting%20Hosts%20For%20${
                          selectedOffice.name
                        }&body=${encodeURI(`Dear Deskovery team,

 
I would like to visit the ${selectedOffice.name} at ${selectedOffice.address.address1}, but there are currently no hosts there. Can you please help me to find a host in this place?
 

Regards,
${userData.name}`)}`}
                      >
                        Request a host
                      </Button>
                    </div>
                  ) : (
                    <OfficeHosts
                      office={selectedOffice}
                      hosts={hosts}
                      userData={userData}
                    />
                  )}
                </MDBTabPane>
              </MDBTabContent>
            </MDBContainer>
            <Container>
              <ReviewsContainer
                title={`Host reviews at ${selectedOffice.name}`}
                locationId={officeId}
                reviewTypeId={2}
              />
            </Container>
          </div>
        )}
      </div>
    );
  }
}

export default connect(combinedSelectors, combinedActions)(OfficeDetails);
