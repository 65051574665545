import { adalApiFetch } from './index';
import config from '../../config';
import { getUsers } from '../Users/actions';

export const updateUserData = (payload) => ({
  type: 'UPDATE_USER_DATA',
  payload,
});

const getUserDataStart = () => ({
  type: 'GET_USER_DATA_START',
});

const getUserDataSuccess = (userData) => ({
  type: 'GET_USER_DATA_SUCCESS',
  userData,
});

const getUserDataError = (error) => ({
  type: 'GET_USER_DATA_ERROR',
  error,
});

export const getUserData = () => async (dispatch) => {
  dispatch(getUserDataStart());
  try {
    const userData = await adalApiFetch(
      fetch,
      config.api.endpoints.users.me
    ).then((res) => {
      if (!res.ok || res.status !== 200) {
        throw new Error(
          'Call to endpoint ' +
            res.url +
            ' failed with status code ' +
            res.status
        );
      }
      if (res.headers.get('content-type').indexOf('application/json') === -1) {
        throw new TypeError(
          'Response from "' + res.url + '" has unexpected "content-type"'
        );
      }
      return res.json();
    });
    dispatch(getUsers({ userIds: [userData.id] }));
    return dispatch(getUserDataSuccess(userData));
  } catch (error) {
    return dispatch(getUserDataError(error));
  }
};

const updateUserStart = () => ({
  type: 'UPDATE_USER_START',
});

const updateUserSuccess = (payload) => ({
  type: 'UPDATE_USER_SUCCESS',
  payload,
});

const updateUserError = (error) => ({
  type: 'UPDATE_USER_ERROR',
  error,
});

export const updateUser = (details) => async (dispatch) => {
  dispatch(updateUserStart());
  try {
    const user = await adalApiFetch(fetch, config.api.endpoints.users.update, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(details),
    }).then((res) => {
      if (!res.ok || res.status !== 200) {
        throw new Error(
          'Call to endpoint ' +
            res.url +
            ' failed with status code ' +
            res.status
        );
      }
      if (res.headers.get('content-type').indexOf('application/json') === -1) {
        throw new TypeError(
          'Response from "' + res.url + '" has unexpected "content-type"'
        );
      }
      return res.json();
    });

    dispatch(getUserData());

    return dispatch(updateUserSuccess(user));
  } catch (error) {
    return dispatch(updateUserError(error));
  }
};

const addUserConsentStart = () => ({
  type: 'ADD_USERCONSENT_START',
});

const addUserConsentSuccess = () => ({
  type: 'ADD_USERCONSENT_SUCCESS',
});

const addUserConsentError = (error) => ({
  type: 'ADD_USERCONSENT_ERROR',
  error,
});

export const addUserConsent = () => async (dispatch) => {
  dispatch(addUserConsentStart());

  try {
    await adalApiFetch(fetch, config.api.endpoints.users.addUserConsent, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (!res.ok || res.status !== 200) {
        throw new Error(
          'Call to endpoint ' +
            res.url +
            ' failed with status code ' +
            res.status
        );
      }
    });

    dispatch(getUserData());

    return dispatch(addUserConsentSuccess());
  } catch (error) {
    return dispatch(addUserConsentError(error));
  }
};

export default {
  getUserData,
  updateUserData,
  addUserConsent,
  updateUser,
};
