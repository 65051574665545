import styled from "styled-components";

export default styled.div`
  background: #ccc;
  display: flex;
  flex-flow: row wrap;
  > div {
    align-items: center;
    background-color: white;
    display: flex;
    flex: 1 0 500px;
    flex-direction: column;
    height: calc(50vh);
    justify-content: center;
    position: relative;
    text-align: center;
    width: 50%;
    overflow: hidden;
    &:before {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      bottom: -10px;
      content: "";
      display: block;
      left: -10px;
      opacity: 0.3;
      position: absolute;
      right: -10px;
      top: -10px;
      z-index: 0;
      transition: 0.3s;
      filter: blur(5px);
    }
    &:hover {
      &:before {
        filter: blur(0px);
      }
    }
    > * {
      z-index: 1;
    }
    .card {
      width: 430px;
      max-width: 100%;
    }
    h2 {
      font-weight: bold !important;
      color: #454545;
    }
    &.guest-container {
      background: linear-gradient(40deg, #ffd86f, #fc6262);
      &:before {
        background-image: url("https://d29o3xhmukmusn.cloudfront.net/images/photos/000/017/576/original/1524640402_compressed.jpg");
      }
    }
    &.host-container {
      background: linear-gradient(40deg, #45cafc, #303f9f);
      &:before {
        background-image: url("http://www.projectmanagers.net/i/wp-content/uploads/2013/02/bigstock-Group-of-happy-young-business-50927732.jpg");
      }
    }
  }
`;
