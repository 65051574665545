import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  MDBListGroup,
  MDBListGroupItem,
  MDBIcon,
  MDBMedia
} from "mdbreact";
class UserRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSelectChange = e => {
    this.setState({});
  };
  render() {
    return (
      <div class="user-request">
        <Card>
          <CardHeader>
            <CardTitle>Requests by Guests</CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <MDBListGroup>
                <MDBListGroupItem
                  style={{ borderLeft: "none", borderRight: "none" }}
                >
                  <MDBMedia>
                    <MDBMedia left>
                      <MDBMedia
                        object
                        style={{ width: 41, height: 41, borderRadius: "50%" }}
                        src="https://ingenius-accelerator.nestle.com/wp-content/themes/ingenius/img/bg_anim01_mobile_front.png"
                        alt=""
                      />
                    </MDBMedia>
                    <MDBMedia body>
                      <Row>
                        <Col middle>
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: 16,
                              color: "#F76B1C"
                            }}
                          >
                            Nicola De Blasio
                          </div>
                          <div style={{ color: "#646464", fontSize: 14 }}>
                            Global Inovator
                          </div>
                        </Col>
                        <Col
                          middle
                          className="text-right flex-grow-0 text-nowrap"
                        >
                          <MDBIcon
                            className="green-text"
                            icon="thumbs-up"
                            style={{ fontSize: 20, marginRight: "20px" }}
                          />
                          <MDBIcon
                            className="red-text"
                            icon="thumbs-down"
                            style={{ fontSize: 20 }}
                          />
                        </Col>
                      </Row>
                    </MDBMedia>
                  </MDBMedia>
                </MDBListGroupItem>
                <MDBListGroupItem
                  style={{ borderLeft: "none", borderRight: "none" }}
                >
                  <MDBMedia>
                    <MDBMedia left>
                      <MDBMedia
                        object
                        style={{ width: 41, height: 41, borderRadius: "50%" }}
                        src="https://ingenius-accelerator.nestle.com/wp-content/themes/ingenius/img/bg_anim01_mobile_front.png"
                        alt=""
                      />
                    </MDBMedia>
                    <MDBMedia body>
                      <Row>
                        <Col middle>
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: 16,
                              color: "#F76B1C"
                            }}
                          >
                            Nicola De Blasio
                          </div>
                          <div style={{ color: "#646464", fontSize: 14 }}>
                            Global Inovator
                          </div>
                        </Col>
                        <Col
                          middle
                          className="text-right flex-grow-0 text-nowrap"
                        >
                          <MDBIcon
                            className="green-text"
                            icon="thumbs-up"
                            style={{ fontSize: 20, marginRight: "20px" }}
                          />
                          <MDBIcon
                            className="red-text"
                            icon="thumbs-down"
                            style={{ fontSize: 20 }}
                          />
                        </Col>
                      </Row>
                    </MDBMedia>
                  </MDBMedia>
                </MDBListGroupItem>
                <MDBListGroupItem
                  style={{ borderLeft: "none", borderRight: "none" }}
                >
                  <MDBMedia>
                    <MDBMedia left>
                      <MDBMedia
                        object
                        style={{ width: 41, height: 41, borderRadius: "50%" }}
                        src="https://ingenius-accelerator.nestle.com/wp-content/themes/ingenius/img/bg_anim01_mobile_front.png"
                        alt=""
                      />
                    </MDBMedia>
                    <MDBMedia body>
                      <Row>
                        <Col middle>
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: 16,
                              color: "#F76B1C"
                            }}
                          >
                            Nicola De Blasio
                          </div>
                          <div style={{ color: "#646464", fontSize: 14 }}>
                            Global Inovator
                          </div>
                        </Col>
                        <Col
                          middle
                          className="text-right flex-grow-0 text-nowrap"
                        >
                          <MDBIcon
                            className="green-text"
                            icon="thumbs-up"
                            style={{ fontSize: 20, marginRight: "20px" }}
                          />
                          <MDBIcon
                            className="red-text"
                            icon="thumbs-down"
                            style={{ fontSize: 20 }}
                          />
                        </Col>
                      </Row>
                    </MDBMedia>
                  </MDBMedia>
                </MDBListGroupItem>
              </MDBListGroup>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}
export default UserRequest;
