import React from "react";
import styled from "styled-components";
import media from "../../media";

const StyledDesk = styled.div`
  background-image: linear-gradient(90deg, #dedede, rgba(255, 255, 255, 0.5)),
    url(${media["desk"]});
  background-size: cover;
  background-position: center center;
  height: auto;

  > .container {
    padding: 30px 10px 50px;
    @media (min-width: 768px) {
      padding: 60px 0;
    }
  }
  h1 {
    font-weight: 400;
    color: #4a4a4a;
    font-size: 30px;
    margin: 10px auto;
    @media (min-width: 768px) {
      font-size: 64px;
      margin: 20px auto;
    }
  }
  .desk-inputs-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    .md-form {
      margin-bottom: 0;
    }
    .react-autosuggest__container {
      flex: 1 1 auto;
      margin-right: 20px;
      input {
        padding: 11px;
        height: auto;
      }
    }
    button {
      flex: 0 0 auto;
    }
  }
  .form-control form-control-sm {
    padding: 20px 0 !important;
  }
  .react-autosuggest {
    &__container {
      display: block;
      position: relative;
      z-index: 100;
      .form-input {
        display: block;
        width: 100%;
        border: 0;
        padding: 5px;
      }
    }
    &__suggestions-container {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      margin: 0;
      padding: 0;
      background: white;
      border-radius: 5px;
      box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.3);
      overflow: hidden;
    }
    &__suggestions-list {
      list-type: none;
      margin: 0;
      padding: 0;
      display: block;
    }
    &__suggestion {
      margin: 0;
      padding: 5px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      &--highlighted {
        background: rgba(66, 133, 244, 0.5);
      }
    }
  }
`;

const StyledDeskContainer = (props) => {
  return <StyledDesk {...props} />;
};

export default StyledDeskContainer;
