import React, { Component } from "react";
import {Header, Footer} from './components/';

class MainLayout extends Component {
  render() {
    return (
      <div style={{backgroundColor: '#f4f9fc'}}>
        <Header/>
        <div style={{paddingTop: 57}}>{this.props.children}</div>
        <Footer/>
      </div>
    );
  }
}

export default MainLayout;
