import React, { Component } from "react";
import { connect } from "react-redux";
import media from "../../../../media";
import hour from "../../../../media/hour.png";
import location from "../../../../media/location.png";
import phone from "../../../../media/phone.png";
import star from "../../../../media/star.png";
import visit from "../../../../media/visit.png";
import {
  MDBBtn,
  MDBMedia,
  Card,
  CardBody,
  CardFooter,
  CardImage,
  CardTitle,
  CardText,
  Row,
  Col,
  Container,
  Fa,
  MDBInput,
  MDBDatePicker,
  MDBBtnGroup,
  MDBIcon,
} from "mdbreact";
import authSelectors from "../../../Auth/selectors";
import usersSelectors from "../../../Users/selectors";
import BookNowButton from "../../../Bookings/components/BookNowButton";
import StyledDetails from "./styledDetails";
import AddOfficeButton from "../AddOfficeButton";
import EditOfficeButton from "../EditOfficeButton";
import BecomeHostButton from "../../../Auth/components/BecomeHostButton";
import config from "../../../../config.js";

const contactValueStyle = {
  fontSize: 16,
  display: "block",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  maxWidth: 200,
};

class SelectedOfficeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOffice: null,
    };
  }
  handleSelectChange = (e) => {
    this.setState({
      selectedOffice: e.target.value,
    });
  };
  render() {
    const { selectedOffice } = this.state;
    const { offices, onClick, office, userData, users } = this.props;
    var sortedOpeningTimes = [...office.openingTimes] || [];
    sortedOpeningTimes.push(sortedOpeningTimes.shift());
    return (
      <StyledDetails>
        <Container>
          <Row>
            <Col size="md-12">
              <h1>{office.name}</h1>
            </Col>
            <Col size="md-6">
              <span className="address">
                {" "}
                <img src={location} /> {office.address.city},{" "}
                {office.address.country}
              </span>
              <p>{office.description}</p>
            </Col>
            <Col size="md-6 text-right">
              <img src={star} />
              <span className="raiting">
                {office.facilityRating > 0
                  ? `${parseFloat(office.facilityRating).toFixed(1)}/5 Rating`
                  : `not rated yet`}
              </span>
              <div>
                <MDBBtnGroup size="sm">
                  {userData.userType.id === 1 && (
                    <BecomeHostButton
                      gradient={null}
                      color="primary"
                      size="sm"
                      office={office}
                    />
                  )}

                  {userData.userType.id === 2 &&
                    userData.country.name === office.address.country && (
                      <AddOfficeButton
                        gradient={null}
                        color="primary"
                        size="sm"
                        office={office}
                      />
                    )}
                  {((userData.userType.id === 2 &&
                    users[userData.id] &&
                    users[userData.id].hostedLocationIds.indexOf(office.id) >
                      -1) ||
                    userData.isAdmin ||
                    office.siteManagers.map((u) => u.id).indexOf(userData.id) >
                      -1) && (
                    <EditOfficeButton
                      gradient={null}
                      color="primary"
                      size="sm"
                      office={office}
                    />
                  )}
                </MDBBtnGroup>
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Row className="office-info">
                <Col size="md-4">
                  <MDBMedia>
                    <MDBMedia left className="mr-3">
                      <MDBIcon
                        icon="sign-in"
                        className="red-text"
                        style={{
                          textAlign: "center",
                          width: "1em",
                          height: "1em",
                          fontSize: "35px",
                        }}
                      />
                    </MDBMedia>
                    <MDBMedia body>
                      <MDBMedia heading style={{ fontSize: "18px" }}>
                        Book Random Host:
                      </MDBMedia>
                      <BookNowButton
                        size="sm"
                        office={office}
                        disabled={
                          true
                          // users[userData.id] &&
                          // users[userData.id].hostedLocationIds.indexOf(
                          //   office.id
                          // ) > -1
                        }
                        title={
                          "Due to current pandemic you cannot book hosts"
                          // users[userData.id] &&
                          // users[userData.id].hostedLocationIds.indexOf(
                          //   office.id
                          // ) === -1
                          //   ? "Book a visit"
                          //   : "You cannot book a visit in your location."
                        }
                      />
                    </MDBMedia>
                  </MDBMedia>
                  {office.officePolicyUrl && (
                    <React.Fragment>
                      <hr />
                      <MDBMedia>
                        <MDBMedia left className="mr-3">
                          <MDBIcon
                            icon="info"
                            className="red-text"
                            style={{
                              textAlign: "center",
                              width: "1em",
                              height: "1em",
                              fontSize: "35px",
                            }}
                          />
                        </MDBMedia>
                        <MDBMedia body>
                          <MDBMedia heading style={{ fontSize: "18px" }}>
                            Site information
                          </MDBMedia>
                          <MDBBtn
                            target="_blank"
                            href={office.officePolicyUrl}
                            color="orange"
                            size="sm"
                          >
                            Read more
                          </MDBBtn>
                        </MDBMedia>
                      </MDBMedia>
                    </React.Fragment>
                  )}
                </Col>
                <Col size="md-4">
                  {!!office.openingTimes && (
                    <MDBMedia>
                      <MDBMedia
                        left
                        className="mr-3"
                        style={{ fontSize: "35px" }}
                      >
                        <MDBIcon icon="clock-o" className="amber-text" />
                      </MDBMedia>
                      <MDBMedia body>
                        <MDBMedia heading style={{ fontSize: "18px" }}>
                          Reception Hours and Capacity:
                        </MDBMedia>
                        {/* <span style={{ fontSize: "16px" }}>
                        {office.openingHours[0]}
                      </span> */}
                        <table style={{ width: "100%" }}>
                          {sortedOpeningTimes.map((entry, index) => (
                            <tr
                              style={{
                                backgroundColor:
                                  entry.day === new Date().getDay()
                                    ? "#dbe8ff"
                                    : "transparent",
                              }}
                            >
                              <td>{config.enums.weekDays[entry.day]}:</td>
                              <td style={{ textAlign: "right" }}>
                                {entry.startTime.slice(-8, -3)} -{" "}
                                {entry.endTime.slice(-8, -3)}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {entry.capacity}%
                              </td>
                            </tr>
                          ))}
                        </table>
                      </MDBMedia>
                    </MDBMedia>
                  )}
                </Col>
                <Col size="md-4">
                  <MDBMedia>
                    <MDBMedia
                      left
                      className="mr-3"
                      style={{ fontSize: "35px" }}
                    >
                      <MDBIcon icon="phone" className="cyan-text" />
                    </MDBMedia>
                    <MDBMedia body>
                      <MDBMedia heading style={{ fontSize: "18px" }}>
                        Contacts:
                      </MDBMedia>
                      <table>
                        {office.siteManagers.length > 0 && (
                          <tr>
                            <td>Site Manager:</td>
                            <td style={{ textAlign: "right" }}>
                              <a
                                title={office.siteManagers[0].name}
                                style={contactValueStyle}
                                href={`mailto:${office.siteManagers[0].email}`}
                              >
                                {office.siteManagers[0].name}
                              </a>
                            </td>
                          </tr>
                        )}
                        {office.email && (
                          <tr>
                            <td>Site email:</td>
                            <td style={{ textAlign: "right" }}>
                              <a
                                title={office.email}
                                style={contactValueStyle}
                                href={`mailto:${office.email}`}
                              >
                                {office.email}
                              </a>
                            </td>
                          </tr>
                        )}
                        {office.frontDeskPhoneNumber && (
                          <tr>
                            <td>Site phone:</td>
                            <td style={{ textAlign: "right" }}>
                              <a
                                title={office.frontDeskPhoneNumber}
                                style={contactValueStyle}
                                href={`tel:${office.frontDeskPhoneNumber}`}
                              >
                                {office.frontDeskPhoneNumber}
                              </a>
                            </td>
                          </tr>
                        )}
                        {office.securityNumber && (
                          <tr>
                            <td>Security:</td>
                            <td style={{ textAlign: "right" }}>
                              <a
                                title={office.securityNumber}
                                style={contactValueStyle}
                                href={`tel:${office.securityNumber}`}
                              >
                                {office.securityNumber}
                              </a>
                            </td>
                          </tr>
                        )}
                        {office.firstAidNumber && (
                          <tr>
                            <td>First Aid:</td>
                            <td style={{ textAlign: "right" }}>
                              <a
                                title={office.firstAidNumber}
                                style={contactValueStyle}
                                href={`tel:${office.firstAidNumber}`}
                              >
                                {office.firstAidNumber}
                              </a>
                            </td>
                          </tr>
                        )}
                        {office.fireNumber && (
                          <tr>
                            <td>Fire Brigade:</td>
                            <td style={{ textAlign: "right" }}>
                              <a
                                title={office.fireNumber}
                                style={contactValueStyle}
                                href={`tel:${office.fireNumber}`}
                              >
                                {office.fireNumber}
                              </a>
                            </td>
                          </tr>
                        )}
                        {office.policeNumber && (
                          <tr>
                            <td>Police:</td>
                            <td style={{ textAlign: "right" }}>
                              <a
                                title={office.policeNumber}
                                style={contactValueStyle}
                                href={`tel:${office.policeNumber}`}
                              >
                                {office.policeNumber}
                              </a>
                            </td>
                          </tr>
                        )}
                      </table>
                    </MDBMedia>
                  </MDBMedia>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </StyledDetails>
    );
  }
}
export default connect((state) => ({
  ...authSelectors(state),
  ...usersSelectors(state),
}))(SelectedOfficeDetails);
