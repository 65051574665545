import React, { Component } from "react";
import { connect } from "react-redux";
import { Review, AddReviewButton } from "./components";
import { Rating } from "../../components";
import loadingSelectors from "../Loading/selectors";
import selectors from "./selectors";
import actions from "./actions";

const combinedSelectors = state => ({
  ...selectors(state),
  ...loadingSelectors(state)
});

class Reviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.userId,
      locationId: this.props.locationId,
      author: "reviewer",
      reviews: [],
      limit: 5,
      offset: 0
    };
  }
  componentDidMount() {
    this.getReviews();
  }
  getReviews = () => {
    const { limit, offset } = this.state;
    const { userId, locationId, reviewTypeId } = this.props;
    this.props
      .getReviews({
        userIds: userId ? [userId] : null,
        locationIds: locationId ? [parseInt(locationId)] : null,
        reviewTypeId,
        limit,
        offset
      })
      .then(action => {
        this.setState({
          reviews: Object.values(action.payload.reviews),
          offset: offset + limit
        });
      });
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    const { userId, locationId, reviewTypeId, author } = nextProps;
    if (
      userId !== prevState.currentUserId ||
      locationId !== prevState.locationId ||
      author !== prevState.author ||
      reviewTypeId !== prevState.reviewTypeId
    ) {
      return {
        userId,
        locationId,
        reviewTypeId,
        author
      };
    } else {
      return prevState;
    }
  }
  getSnapshotBeforeUpdate(prevProps, prevState) {
    const { userId, locationId } = prevState;
    if (this.state.userId !== userId || this.state.locationId !== locationId) {
      this.getReviews();
    }
  }
  render() {
    const { reviews, author } = this.state;
    const { userId, title } = this.props;
    const filteredReviews = Object.values(reviews).filter(review => {
      if (!userId) {
        return true;
      }
      switch (author) {
        case "reviewer":
          return review.reviewerUser.id !== userId;
        case "receiver":
          return review.reviewerUser.id === userId;
      }
    });

    return reviews.length > 0 ? (
      <div>
        {title && <h2>{title}</h2>}
        {filteredReviews.length === 0
          ? "No reviews have been added yet"
          : filteredReviews.map(review => <Review review={review} />)}
      </div>
    ) : (
        ""
      );
  }
}

export default connect(
  combinedSelectors,
  actions
)(Reviews);
