export const offices = (state) => state.Offices.offices;
export const buildings = (state) => state.Offices.buildings;
export const fetchingOffices = (state) => state.Offices.isFetching;
export const fetchedOffices = (state) => state.Offices.fetched;
export const totalResults = (state) => state.Offices.totalResults;

export default (state) => ({
  offices: offices(state),
  buildings: buildings(state),
  fetchingOffices: fetchingOffices(state),
  fetchedOffices: fetchedOffices(state),
  totalResults: totalResults(state),
});
