import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import authSelectors from "../../../../modules/Auth/selectors";
import bookingSelectors from "../../../../modules/Bookings/selectors";
import {
  MDBBadge,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBMedia,
  MDBNavItem,
  MDBNavLink,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
} from "mdbreact";
import { InGeniusLogo, UserPicture } from "../../../../components";

const StyledHeader = styled.header`
  .ingenius-menu {
    padding: 0;
    > a {
      padding: 0;
    }
  }
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNavOpen: false,
    };
  }
  render() {
    const { mobileNavOpen } = this.state;
    const { floorVisits, bookings, match, userData } = this.props;
    const pendingVisits = Object.values(bookings).filter((booking) => {
      return (
        booking.bookingStatus.id === 1 &&
        booking.startDateTimeStamp * 1000 > new Date().getTime() &&
        ((booking.host == null &&
          booking.guest &&
          booking.guest.id === userData.id) ||
          (booking.host && booking.host.id !== userData.id))
      );
    });
    const pendingFloorVisits = floorVisits.filter((visit) => {
      const startDateTimestamp = new Date(visit.startDate).getTime();
      const endDateTimestamp = new Date(visit.endDate).getTime();
      const todayTimestamp = new Date().getTime();
      return (
        todayTimestamp < startDateTimestamp || todayTimestamp < endDateTimestamp
      );
    });
    const pendingGuests = Object.values(bookings).filter((booking) => {
      return (
        booking.bookingStatus.id === 1 &&
        booking.startDateTimeStamp * 1000 > new Date().getTime() &&
        ((booking.host == null &&
          booking.guest &&
          booking.guest.id !== userData.id) ||
          (booking.host && booking.host.id === userData.id))
      );
    });
    return (
      <StyledHeader>
        <MDBNavbar
          style={{ padding: 0 }}
          color="primary-color"
          dark
          fixed="top"
          expand="md"
        >
          <MDBDropdown className="ingenius-menu">
            <MDBDropdownToggle nav>
              <InGeniusLogo />
            </MDBDropdownToggle>
            <MDBDropdownMenu className="MDBdropdown-default" right>
              <MDBNavLink className="dropdown-item" to="/tour">
                Tour of Deskovery
              </MDBNavLink>
              <MDBDropdownItem
                target="_blank"
                href="https://ingenius.nestle.com/projects/6"
              >
                More about Deskovery
              </MDBDropdownItem>
              <MDBDropdownItem
                target="_blank"
                href="https://ingenius.nestle.com/projects/6#projectFaqs"
              >
                FAQ
              </MDBDropdownItem>
              <MDBDropdownItem
                target="_blank"
                href="https://nestle.facebook.com/groups/833627076986923/"
              >
                Community support
              </MDBDropdownItem>
              <MDBDropdownItem
                target="_blank"
                href="https://ingenius.nestle.com/projects"
              >
                Visit InGenius Projects
              </MDBDropdownItem>
              <MDBDropdownItem
                target="_blank"
                href="https://ingenius.nestle.com/challenges"
              >
                Submit an Idea
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
          <MDBNavbarBrand href="/">
            <strong style={{ fontFamily: '"Bree Serif", serif', fontSize: 23 }}>
              deskovery
            </strong>
          </MDBNavbarBrand>
          <MDBNavbarToggler
            onClick={() => this.setState({ mobileNavOpen: !mobileNavOpen })}
          />
          <MDBCollapse isOpen={mobileNavOpen} navbar>
            <MDBNavbarNav left />
            <MDBNavbarNav right className="align-items-center">
              <MDBNavItem
                className="text-center"
                active={match.path == "/offices"}
                style={{ marginLeft: 10 }}
              >
                <MDBNavLink to="/offices">Destinations</MDBNavLink>
              </MDBNavItem>
              <MDBNavItem
                className="text-center"
                active={match.path == "/dashboard"}
                style={{ marginLeft: 10 }}
              >
                <MDBNavLink to="/dashboard">Dashboard</MDBNavLink>
              </MDBNavItem>
              <MDBNavItem
                className="text-center"
                title={`You have ${pendingVisits.length +
                  pendingFloorVisits.length} pending requests.`}
                active={match.url == "/trips"}
                style={{ marginLeft: 10 }}
              >
                <MDBNavLink to="/trips">
                  <span className="text-nowrap">
                    My Bookings
                    {pendingVisits.length + pendingFloorVisits.length > 0 && (
                      <MDBBadge
                        style={{ verticalAlign: "middle" }}
                        color="danger"
                        className="ml-1"
                      >
                        {pendingVisits.length + pendingFloorVisits.length}
                      </MDBBadge>
                    )}
                  </span>
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem
                className="text-center"
                title={`You have ${pendingGuests.length} pending requests.`}
                active={match.path == "/guests"}
                style={{ marginLeft: 10 }}
              >
                <MDBNavLink to="/guests">
                  <span
                    className="text-nowrap"
                    onClick={() =>
                      alert(
                        "This feature is disabled due to the current pandemic situation."
                      )
                    }
                  >
                    My Guests
                    {pendingGuests.length > 0 && (
                      <MDBBadge
                        style={{ verticalAlign: "middle" }}
                        color="danger"
                        className="ml-1"
                      >
                        {pendingGuests.length}
                      </MDBBadge>
                    )}
                  </span>
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem style={{ marginLeft: 10 }}>
                <MDBDropdown>
                  <MDBDropdownToggle nav>
                    <MDBMedia>
                      <MDBMedia middle left className="pb-0">
                        <UserPicture
                          avatar={this.props.userData.photoUrl}
                          name={this.props.userData.name}
                        />
                      </MDBMedia>
                      <MDBMedia
                        middle
                        body
                        style={{ flex: "1 0 auto", lineHeight: "1" }}
                      >
                        {this.props.userData.name}
                        <br />
                        <small>
                          <strong>{this.props.userData.userType.name}</strong>
                        </small>
                      </MDBMedia>
                    </MDBMedia>
                  </MDBDropdownToggle>
                  <MDBDropdownMenu className="MDBdropdown-default" right>
                    <NavLink
                      className="dropdown-item"
                      to={`/user/${this.props.userData.id}`}
                    >
                      My Profile
                    </NavLink>
                    {this.props.userData.isAdmin && (
                      <NavLink className="dropdown-item" to="/admin">
                        Admin Panel
                      </NavLink>
                    )}
                    <NavLink className="dropdown-item" to="/logout">
                      Log Out
                    </NavLink>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBNavbar>
      </StyledHeader>
    );
  }
}

export default connect((state) => ({
  ...authSelectors(state),
  ...bookingSelectors(state),
}))(withRouter(Header));
