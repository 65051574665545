import styled from 'styled-components'
const StyledOfficeHosts = styled.div`
background-color:white;
padding:40px 0 60px;
h1{
    font-weight:bold;
}
h2{
    font-weight:bold;
    font-size:15px;
}

span{
    font-weight:bold;
    
}

img{
    margin-right:5px;
}
.host-avatar{
    width:50px;
    height:50px;
    border-radius:50%;
}
   
`

export default StyledOfficeHosts