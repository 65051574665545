import { adalApiFetch } from "../Auth/index";
import config from "../../config";

const getReviewsStart = () => ({
  type: "GET_REVIEWS_START"
});

const getReviewsSuccess = payload => ({
  type: "GET_REVIEWS_SUCCESS",
  payload
});

const getReviewsError = error => ({
  type: "GET_REVIEWS_ERROR",
  error
});

export const getReviews = details => async dispatch => {
  dispatch(getReviewsStart());
  try {
    const payload = await adalApiFetch(
      fetch,
      config.api.endpoints.reviews.list,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(details)
      }
    ).then(res => {
      if (!res.ok || res.status !== 200) {
        throw new Error(
          "Call to endpoint " +
            res.url +
            " failed with status code " +
            res.status
        );
      }
      if (res.headers.get("content-type").indexOf("application/json") === -1) {
        throw new TypeError(
          'Response from "' + res.url + '" has unexpected "content-type"'
        );
      }
      return res.json();
    });
    return dispatch(getReviewsSuccess(payload));
  } catch (error) {
    return dispatch(getReviewsError(error));
  }
};

export default {
  getReviews
};
