import React, { Component } from 'react';
import MainLayout from '../layouts/MainLayout';
import AdminContainer from '../modules/Admin/container'

const AdminView = (props) => (
    <MainLayout>
        <AdminContainer {...props}/>
    </MainLayout>
)


export default AdminView