import React, { Component } from "react";

import { Container, Row, Col, MDBBtn as Button, Spinner } from "mdbreact";
import { connect } from "react-redux";
import { OfficeCard, BrowsingFilters } from "../components";
import { Map } from "../../../components";
import actions from "../actions";
import selectors from "../selectors";
import PlanVisitModal from "../../Offices/components/PlanVisit/Modal";
import moment from "moment";

function getSearchParam(search, param) {
  var query = search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) == param) {
      return decodeURIComponent(pair[1]);
    }
  }
}
class BrowseContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        query: getSearchParam(this.props.location.search, "q") || "",
        locationTypeIds: [],
        limit: 12,
        onlyActive: true,
        offset: 0,
        orderBy: 3, //this number comes from resources
        orderByAscDesc: 2,
      },
      selectedOffice: null,
      selectedBuilding: null,
    };
  }
  componentDidMount() {
    this.props.getOffices(this.state.filters, true);
  }
  handleFiltersSubmit = (filters) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          ...filters,
        },
      },
      () => {
        this.props.getOffices(this.state.filters, true);
      }
    );
  };
  handleLoadMore = () => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          offset: this.state.filters.offset + this.state.filters.limit,
        },
      },
      () => {
        this.props.getOffices(this.state.filters);
      }
    );
  };
  render() {
    const { filters, showPlanVisitModal, selectedOffice, selectedBuilding } =
      this.state;
    const { offices, location, fetchingOffices, totalResults, buildings } =
      this.props;
    const officesCollection = Object.keys(offices)
      .map((key) => offices[key])
      .sort((a, b) => {
        if (a.totalHosts < b.totalHosts) return 1;
        if (a.totalHosts > b.totalHosts) return -1;
        return 0;
      });

    const selectOptions = Object.keys(buildings)
      .map((key) => buildings[key])
      .map((b) => ({ name: b.name, id: b.id }));
    return (
      <React.Fragment>
        <Map
          maxZoom={12}
          geojson={{
            type: "FeatureCollection",
            features: offices
              ? officesCollection
                  .filter((office) => office.coordinates.split(",").length == 2)
                  .map((office) => ({
                    type: "Feature",
                    properties: office,
                    geometry: {
                      type: "Point",
                      coordinates: office.coordinates.split(",").reverse(),
                    },
                  }))
              : [],
          }}
        />
        <Container style={{ marginTop: -120, paddingTop: 36 }}>
          <BrowsingFilters
            filters={filters}
            onSubmit={this.handleFiltersSubmit}
          />
          <Row>
            {offices &&
              officesCollection.map((office) => {
                return (
                  <Col
                    className="col-sm-6 col-md-4 col-xl-3"
                    id={"office-" + office.id}
                  >
                    <OfficeCard
                      office={office}
                      selectOptions={selectOptions}
                      onSelectClick={() => {
                        this.setState(
                          {
                            ...this.state,
                            selectedOffice: office,
                          },
                          () => this.fetchBuildings(office.id)
                        );
                      }}
                      onSelect={(buildingId) => {
                        this.setState({
                          ...this.state,
                          selectedBuilding: buildings[buildingId],
                          showPlanVisitModal: true,
                        });
                      }}
                      selectLoading={
                        fetchingOffices &&
                        selectedOffice &&
                        office.id === selectedOffice.id
                      }
                      active={selectedOffice && office.id === selectedOffice.id}
                    />
                  </Col>
                );
              })}
          </Row>
          {fetchingOffices && (
            <Row>
              <Col className="text-center">
                <Spinner />
              </Col>
            </Row>
          )}
          {!fetchingOffices &&
            offices &&
            Object.keys(offices).length < totalResults && (
              <Row>
                <Col className="text-center">
                  <Button color="orange" onClick={this.handleLoadMore}>
                    Load More
                  </Button>
                </Col>
              </Row>
            )}
        </Container>

        {selectedOffice && selectedBuilding && (
          <PlanVisitModal
            showModal={showPlanVisitModal}
            office={selectedOffice}
            buildingId={selectedBuilding.id}
            buildingName={selectedBuilding.name}
            onToggle={() =>
              this.setState({
                ...this.state,
                showPlanVisitModal: !showPlanVisitModal,
              })
            }
            hideRebookModal
          />
        )}
      </React.Fragment>
    );
  }

  fetchBuildings = (locationId) => {
    const { getBuildings } = this.props;
    getBuildings(locationId, moment().format("YYYY-MM-DD"));
  };
}

export default connect(selectors, actions)(BrowseContainer);
