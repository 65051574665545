import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SampleComponent } from './components';
import loadingSelectors from '../Loading/selectors';
import selectors from './selectors';
import actions from './actions';

const combinedSelectors = state => ({
    ...selectors(state),
    ...loadingSelectors(state)
})

class SampleModule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: props.name
        }
    }
    handleInputChange = e => {
        this.setState({
            inputValue: e.target.value
        })
    }
    handleButtonClick = e => {
        if(!this.props.loaders.name) {
            this.props.changeName(this.state.inputValue);
        }
    }
    handleInputKeyUp = e => {
        switch(e.keyCode) {
            case 13: this.handleButtonClick(); break; //on Enter key press
            case 27: this.setState({ //on Escape key press
                inputValue: this.props.name
            }); break;
            default: break;
        }
    }
    render() {
        const {
            inputValue
        } = this.state;
        const {
            name,
            loaders
        } = this.props
        return (
            <div>
                <b>From State: {inputValue}</b><br/>
                <b>From Redux: {loaders.name ? '...loading' : name}</b><br/>
                <SampleComponent 
                    inputValue={inputValue}
                    onInputKeyUp={this.handleInputKeyUp}
                    onInputChange={this.handleInputChange}
                    onButtonClick={this.handleButtonClick}/>
            </div>
        )
    }
}

export default connect(combinedSelectors, actions)(SampleModule);