export const userData = state => state.Auth.userData;
export const isUserLoggedIn = state => state.Auth.isUserLoggedIn;
export const addConsentLoading = state => state.Auth.addConsentLoading
export const error = state => state.Auth.error

export default state => ({
    isUserLoggedIn: isUserLoggedIn(state),
    userData: userData(state),
    addConsentLoading: addConsentLoading(state),
    error: error(state)
})