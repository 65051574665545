import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactAI from "react-appinsights";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "react-dates/lib/css/_datepicker.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import config from "./config";
import "./App.scss";
import * as serviceWorker from "./serviceWorker";
import Store, { store } from "./store";
import * as views from "./views";
import { authContext, adalApiFetch } from "./modules/Auth";
import AuthContainer from "./modules/Auth/container";
import bookingsActions from "./modules/Bookings/actions";
import "airbnb-browser-shims";
import { UserConsentModal } from "./modules/Auth/containers";
import { ToastContainer } from "mdbreact";

export const history = createBrowserHistory();
ReactAI.init({ instrumentationKey: config.AIInstrumentationKey }, history);

const GARoute = (page) => {
  return (
    <Route
      exact={page.exact}
      path={page.path}
      render={(props) => {
        document.title = page.title + " | Deskovery";
        ReactGA.pageview(page.path, [], page.title);
        if (page.admin) {
          return store.getState().Auth.userData.isAdmin ? (
            <page.component {...props} />
          ) : (
            <Redirect to="/" />
          );
        }
        return <page.component {...props} />;
      }}
    />
  );
};

class Tracker extends Component {
  componentDidMount() {
    ReactGA.set({ userId: store.getState().Auth.userData.id });
  }
  render() {
    return this.props.children;
  }
}
class App extends Component {
  componentDidMount() {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
  }
  render() {
    return (
      <Store>
        <AuthContainer>
          <Tracker>
            <UserConsentModal />
            <ToastContainer
              hideProgressBar={true}
              newestOnTop={true}
              autoClose={5000}
              style={{ textAlign: "center" }}
            />

            <Router history={history}>
              <Switch>
                <GARoute
                  title="Home"
                  exact
                  path="/"
                  component={views["Home"]}
                />
                <GARoute
                  title="Home2"
                  exact
                  path="/home"
                  component={views["Home2"]}
                />
                <GARoute
                  title="Home"
                  exact
                  path="/tour"
                  component={views["Home"]}
                />
                <GARoute
                  title="Home"
                  exact
                  path="/become-host"
                  component={views["Home"]}
                />
                <GARoute
                  title="Dashboard"
                  path="/dashboard"
                  component={views["Dashboard"]}
                />
                <GARoute
                  title="Profile"
                  path="/profile"
                  component={views["Profile"]}
                />
                <GARoute
                  title="MyTrips"
                  path="/trips/:id?/:action?"
                  component={views["MyTrips"]}
                />
                <GARoute
                  title="MyGuests"
                  path="/guests"
                  component={views["MyGuests"]}
                />
                <GARoute
                  title="Browse"
                  exact
                  path="/offices"
                  component={views["Browse"]}
                />
                <GARoute
                  title="AddOffice"
                  path="/offices/add"
                  component={views["AddOffice"]}
                />
                <GARoute
                  title="OfficeDetails"
                  path="/office/:id"
                  component={views["OfficeDetails"]}
                />
                <GARoute
                  title="UserDetails"
                  path="/user/:id"
                  component={views["UserDetails"]}
                />
                <GARoute
                  title="Admin"
                  path="/admin"
                  admin={true}
                  component={views["Admin"]}
                />
                <GARoute
                  title="Logout"
                  path="/logout"
                  component={() => authContext.logOut()}
                />
              </Switch>
            </Router>
          </Tracker>
        </AuthContainer>
      </Store>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

export default App;
