import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  MDBMedia,
  MDBIcon,
  MDBBtn as Button
} from "mdbreact";
import styled from "styled-components";
import { AddOfficesModalButton } from "../../../Offices/components";
import { BookNowButton } from "../../../Bookings/components";
import {
  selectors as authSelectors,
  actions as authActions
} from "../../../Auth";

const StyledOfficeCover = styled.div`
    width: 50px;
    display: inline-block;
    background-image: url('${({ cover }) => cover}');
    background-position: center center;
    background-size: cover;
    background-color: #ccc;
    background-repeat: no-repeat;
    border-radius: 5px;
    overflow: hidden;
    &:before {
        content: '';
        display: block;
        padding-bottom: 100%;
    }
`;

const SingleUserOffice = ({ office, user, authenticatedUser, onRemove }) => {
  return (
    <MDBMedia>
      <MDBMedia left>
        <StyledOfficeCover cover={office.coverPhotoUrl} />
      </MDBMedia>
      <MDBMedia body style={{ maxWidth: "80%" }}>
        <Row>
          <Col size="8">
            <Link
              to={`/office/${office.id}`}
              style={{
                display: "block",
                color: "#454545",
                fontSize: 18,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              {office.name}
            </Link>
            <div style={{ fontSize: 12 }}>
              {office.address.city}, {office.address.country}
            </div>
          </Col>
          <Col size="4" className="text-right">
            {user.id !== authenticatedUser.id && (
              <BookNowButton
                size="sm"
                office={office}
                host={user}
                style={{ padding: "5px" }}
              />
            )}
            {user.id == authenticatedUser.id && (
              <Button
                size="sm"
                style={{ padding: "5px" }}
                className="btn-danger"
                color="danger"
                onClick={() => onRemove(office.id)}
              >
                <MDBIcon icon="trash" size="2x" />
              </Button>
            )}
          </Col>
        </Row>
      </MDBMedia>
    </MDBMedia>
  );
};

class UserOffices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }
  static propTypes = {
    propOne: PropTypes.string
  };
  static defaultProps = {
    propOne: ""
  };
  componentDidMount() {}
  handleRemove = officeId => {
    const { updateUser, authenticatedUser, getUserProfileData } = this.props;
    this.setState({ loading: true }, () => {
      updateUser({
        removeOffices: [officeId]
      }).then(action => {
        this.setState({ loading: false });
        getUserProfileData(authenticatedUser.id);
      });
    });
  };
  render() {
    const { loading } = this.state;
    const { offices, user, authenticatedUser, getUserProfileData } = this.props;
    return (
      <div className={{ loading: loading ? "loading" : null }}>
        <Card>
          <CardHeader>
            <div style={{ fontSize: 18 }}>
              Host in {offices.length} locations
            </div>
          </CardHeader>
          <CardBody>
            {offices.length > 0 &&
              offices.map(office => (
                <SingleUserOffice
                  user={user}
                  authenticatedUser={authenticatedUser}
                  office={office}
                  onRemove={this.handleRemove}
                />
              ))}
          </CardBody>
          {user.id == authenticatedUser.id && user.userType.id == 2 && (
            <CardFooter className="text-center">
              <AddOfficesModalButton
                size="sm"
                getUserProfileData={() =>
                  getUserProfileData(authenticatedUser.id)
                }
              />
            </CardFooter>
          )}
        </Card>
      </div>
    );
  }
}

export default connect(
  authSelectors,
  authActions
)(UserOffices);
