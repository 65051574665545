import React from "react";

import {
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBBtn as Button,
} from "mdbreact";
import { Link } from "react-router-dom";

// TODO: this could be used everywhere where we display booked desks
export default function BookedDeskTable({
  data,
  onBooking,
  selectedLocation,
  buildings,
  onBuildingSelection,
  buildingFetching,
}) {
  return (
    <MDBTable responsive btn>
      <MDBTableHead>
        <tr>
          <th>Office</th>
          <th>Building</th>
          <th>Floor</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>
            <span className="srOnly">Actions</span>
          </th>
        </tr>
      </MDBTableHead>

      <MDBTableBody>
        {data.length > 0 ? (
          data.map((row) => {
            const {
              location,
              building,
              floor,
              startDate,
              endDate,
              locationId,
            } = row;

            return (
              <tr>
                <td>
                  <Link to={`/office/${locationId}`}>{location}</Link>
                </td>
                <td>{building}</td>
                <td>{floor}</td>
                <td>{new Date(startDate).toLocaleDateString()}</td>
                <td>{new Date(endDate).toLocaleDateString()}</td>
                <td className="actions">
                  {selectedLocation === locationId && !buildingFetching ? (
                    <select
                      className="browser-default custom-select"
                      onChange={(e) =>
                        onBuildingSelection(
                          e.target.value === "not-selected"
                            ? undefined
                            : buildings[+e.target.value]
                        )
                      }
                    >
                      <option value="not-selected">Select a building </option>
                      {Object.keys(buildings).map((key) => (
                        <option value={key}>{buildings[key].name}</option>
                      ))}
                    </select>
                  ) : (
                    <Button
                      size="sm"
                      color="warning"
                      onClick={() => onBooking(locationId)}
                      disabled={
                        selectedLocation === locationId || buildingFetching
                      }
                    >
                      {selectedLocation === locationId && buildingFetching
                        ? "loading..."
                        : "Book again"}
                    </Button>
                  )}
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={5}>Empty result... </td>
          </tr>
        )}
      </MDBTableBody>
    </MDBTable>
  );
}
