import { adalApiFetch } from "../Auth/index";
import config from "../../config";

const getSuggestedLocationsStart = () => ({
  type: "GET_SUGGESTED_LOCATIONS_START"
});

const getSuggestedLocationsSuccess = (response, clear) => ({
  type: "GET_SUGGESTED_LOCATIONS_SUCCESS",
  payload: response,
  clear
});

const getSuggestedLocationsError = error => ({
  type: "GET_SUGGESTED_LOCATIONS_ERROR",
  error
});

export const getSuggestedLocations = (filters, clear) => async dispatch => {
  dispatch(getSuggestedLocationsStart());
  try {
    const offices = await adalApiFetch(
      fetch,
      config.api.endpoints.offices.list,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          suggested: true,
          limit: 3,
          offset: 0,
          onlyActive: true
        })
      }
    ).then(res => {
      if (!res.ok || res.status !== 200) {
        throw new Error(
          "Call to endpoint " +
            res.url +
            " failed with status code " +
            res.status
        );
      }
      if (res.headers.get("content-type").indexOf("application/json") === -1) {
        throw new TypeError(
          'Response from "' + res.url + '" has unexpected "content-type"'
        );
      }
      return res.json();
    });
    return dispatch(getSuggestedLocationsSuccess(offices, clear));
  } catch (error) {
    console.log(error);
    return dispatch(getSuggestedLocationsError(error));
  }
};

const getTopLocationsStart = () => ({
  type: "GET_TOP_LOCATIONS_START"
});

const getTopLocationsSuccess = (response, clear) => ({
  type: "GET_TOP_LOCATIONS_SUCCESS",
  payload: response,
  clear
});

const getTopLocationsError = error => ({
  type: "GET_TOP_LOCATIONS_ERROR",
  error
});

export const getTopLocations = (filters, clear) => async dispatch => {
  dispatch(getTopLocationsStart());
  try {
    const offices = await adalApiFetch(
      fetch,
      config.api.endpoints.offices.list,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          top: true,
          limit: 3,
          offset: 0,
          onlyActive: true
        })
      }
    ).then(res => {
      if (!res.ok || res.status !== 200) {
        throw new Error(
          "Call to endpoint " +
            res.url +
            " failed with status code " +
            res.status
        );
      }
      if (res.headers.get("content-type").indexOf("application/json") === -1) {
        throw new TypeError(
          'Response from "' + res.url + '" has unexpected "content-type"'
        );
      }
      return res.json();
    });
    return dispatch(getTopLocationsSuccess(offices, clear));
  } catch (error) {
    console.log(error);
    return dispatch(getTopLocationsError(error));
  }
};

export default {
  getSuggestedLocations,
  getTopLocations
};
