import { runWithAdal } from "react-adal";
import { authContext } from "./modules/Auth";
// TODO: This was needed to polyfill for IE but was removed when node-sass was
// updated for the NodeJs 16+
// import "core-js";
import "whatwg-fetch";
import "promise-polyfill/src/polyfill";
import "es7-object-polyfill";
import "./utilities/nodeListForEachPolyfill";

const DO_NOT_LOGIN = false;

runWithAdal(
  authContext,
  () => {
    // eslint-disable-next-line
    require("./App.js");
  },
  DO_NOT_LOGIN
);
