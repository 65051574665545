import React from "react";
import { Card, CardBody, Fa, Col, Row, MDBMedia, Input } from "mdbreact";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Rating } from "../../../../components";

const StyledReviewHeader = styled.div`
  font-size: 16px;
`;
export default ({ review }) => {
  const {
    reviewerUser,
    locationRating,
    userRating,
    receiverUser,
    reviewLocationDto,
    reviewUnixTimeStamp,
    comments
  } = review;
  let rating = 0;
  if (locationRating === 0 && userRating > 0) {
    rating = userRating;
  } else if (userRating === 0 && locationRating > 0) {
    rating = userRating;
  } else if (userRating > 0 && locationRating > 0) {
    rating = (userRating + locationRating) / 2;
  } else {
    rating = 0;
  }
  return (
    <Card className="mb-3">
      <CardBody className="pt-1 pb-3">
        <MDBMedia>
          <MDBMedia left className="mr-3" href="#">
            <Link to={`/user/${reviewerUser.id}`}>
              <MDBMedia
                style={{ borderRadius: "50%", width: 50 }}
                object
                src={reviewerUser.photoUrl}
                alt={reviewerUser.name}
              />
            </Link>
          </MDBMedia>
          <MDBMedia body>
            <MDBMedia heading>
              <Row>
                <Col size="lg-10" middle>
                  <StyledReviewHeader>
                    By{" "}
                    <Link to={`/user/${reviewerUser.id}`}>
                      <strong>{reviewerUser.name}</strong>
                    </Link>{" "}
                    <small>
                      On{" "}
                      {new Date(
                        reviewUnixTimeStamp * 1000
                      ).toLocaleDateString()}
                    </small>
                  </StyledReviewHeader>
                </Col>
                <Col size="lg-2" middle className="text-lg-right">
                  <small>
                    <Rating readOnly value={rating} />
                  </small>
                </Col>
              </Row>
              <Row style={{ opacity: 0.5 }}>
                <Col>
                  {review.reviewType.id == 1 && (<div
                    style={{
                      fontSize: 14,
                      color: "#333"
                    }}
                  >
                    hosting{" "}
                    <Link to={`/user/${receiverUser.id}`}>
                      {receiverUser.name}
                    </Link>{" "}
                    at{" "}
                    <Link to={`/office/${reviewLocationDto.id}`}>
                      {reviewLocationDto.name}
                    </Link>
                  </div>)}
                  {review.reviewType.id == 2 && (<div
                    style={{
                      fontSize: 14,
                      color: "#333"
                    }}
                  >
                    visiting{" "}
                    <Link to={`/office/${reviewLocationDto.id}`}>
                      {reviewLocationDto.name}
                    </Link>{" "}
                    with{" "}
                    <Link to={`/user/${receiverUser.id}`}>
                      {receiverUser.name}
                    </Link>
                  </div>)}
                </Col>
              </Row>
            </MDBMedia>
            {comments}
          </MDBMedia>
        </MDBMedia>
      </CardBody>
    </Card>
  );
};
