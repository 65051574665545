import React, { Component } from 'react';
import StyledThankful from './styledThankful'
import { NavLink } from 'react-router-dom';
import { Map } from '../../../../components'
import Media from '../../../../media'
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardImage,
    CardTitle,
    CardText,
    Row,
    Col,
    Container,
    Fa,
    MDBInput,
    MDBDatePicker
} from "mdbreact";
class Thankful extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedOffice: null
        }
    }
    render() {
        const { selectedOffice } = this.state;
        const { offices, onClick } = this.props;
        return (

            <StyledThankful>
                <div class="thank-conatiner">  <img src={Media["thankful"]} />
                    <h1>Thank You!</h1>
                    <p>Thanks for adding location.</p></div>

            </StyledThankful>
        )
    }
}
export default Thankful;