const defaultState = {
  bookings: {},
  floorVisits: [],
  isFetching: false,
  isFetchingFloorVisits: false,
  fetched: false,
  fetchedFloorVisits: false,
  error: null,
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case "GET_BOOKINGS_SUCCESS": {
      return {
        ...state,
        bookings: action.payload,
        fetched: true,
        isFetching: false,
      };
    }
    case "GET_BOOKINGS_START": {
      return {
        ...state,
        fetched: false,
        isFetching: true,
      };
    }
    case "GET_BOOKINGS_ERROR": {
      return {
        ...state,
        fetched: false,
        isFetching: false,
        error: action.payload,
      };
    }
    case "GET_FLOOR_VISITS_SUCCESS": {
      return {
        ...state,
        floorVisits: action.payload,
        fetchedFloorVisits: true,
        isFetchingFloorVisits: false,
      };
    }
    case "GET_FLOOR_VISITS_START": {
      return {
        ...state,
        fetchedFloorVisits: false,
        isFetchingFloorVisits: true,
      };
    }
    case "GET_FLOOR_VISITS_ERROR": {
      return {
        ...state,
        fetchedFloorVisits: false,
        isFetchingFloorVisits: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};
