import React, { Component } from 'react';
import { connect } from 'react-redux';
import loadingSelectors from '../Loading/selectors';
import selectors from './selectors';
import actions from './actions';

const combinedSelectors = state => ({
    ...selectors(state),
    ...loadingSelectors(state)
})

class UsersModule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }
    render() {
        const {
        } = this.state;
        const {
        } = this.props
        return (
            <div>
                
            </div>
        )
    }
}


export default connect(combinedSelectors, actions)(UsersModule);