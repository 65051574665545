import styled from "styled-components";

export default styled.div`
  display: block;
  flex-grow: 1;
  flex-basis: calc(100% - 130px);
  border: 1px solid #ccc;
  border-radius: 3px;
  + .btn {
    box-shadow: none !important;
  }
  .react-autosuggest {
    &__container {
      display: flex;
      position: relative;
      z-index: 100;
      min-height: 100%;
      .form-control {
        display: block;
        width: 100%;
        height: auto;
        padding: 5px;
        min-height: 100%;
        border: 0;
        border-radius: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }
    }
    &__suggestions-container {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      margin: 0;
      padding: 0;
      background: white;
      border-radius: 5px;
      box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.3);
      overflow: auto;
      max-height: 300px;
    }
    &__suggestions-list {
      list-type: none;
      margin: 0;
      padding: 0;
      display: block;
    }
    &__suggestion {
      margin: 0;
      padding: 5px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      &--highlighted {
        background: rgba(66, 133, 244, 0.5);
      }
    }
  }
`;
