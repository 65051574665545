import React from "react";

import "./OfficeCard.scss";

import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImage,
  CardTitle,
  CardText,
  Row,
  Col,
  Fa,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
} from "mdbreact";

export default ({
  office,
  selectOptions,
  onSelectClick,
  onSelect,
  selectLoading,
  active,
}) => {
  return (
    <div className="officeCard">
      <Link
        style={{ color: "#4A4A4A", padding: "0 10px 20px" }}
        to={`/office/${office.id}`}
      >
        <Card style={{ maxWidth: 300, margin: "0 auto" }}>
          <CardImage
            style={{
              paddingBottom: "60%",
              margin: "-1px -10px 0",
              borderRadius: 10,
              backgroundColor: "#eee",
              backgroundImage:
                office.coverPhotoUrl == "TODO"
                  ? "linear-gradient(120deg,#a1c4fd 0,#c2e9fb 100%)"
                  : `url('${office.coverPhotoUrl}')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            tag="div"
          />
          <CardBody className="text-center">
            <Row>
              <Col>
                <CardTitle
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {office.name}
                </CardTitle>
              </Col>
            </Row>
            <Row>
              <Col>
                {" "}
                <CardText style={{ marginBottom: "15px", minHeight: 42 }}>
                  {office.address.country}, {office.address.city}
                </CardText>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row style={{ minHeight: 38 }}>
              <Col
                className="text-left font-weight-bold"
                style={{ lineHeight: 1.2 }}
              >
                {office.totalHosts > 0 && (
                  <React.Fragment>
                    <Fa icon="user" /> {office.totalHosts} hosts
                  </React.Fragment>
                )}
                {office.totalHosts == 0 && (
                  <React.Fragment>Hosts needed!</React.Fragment>
                )}
              </Col>
              <Col
                className="text-right font-weight-bold"
                style={{ lineHeight: 1.2 }}
              >
                {office.totalVisits > 0 && (
                  <React.Fragment>
                    <Fa icon="briefcase" /> {office.totalVisits} visits
                  </React.Fragment>
                )}
                {office.totalVisits == 0 && (
                  <React.Fragment>Be the first to visit!</React.Fragment>
                )}
              </Col>
            </Row>
            {onSelect && selectOptions && (
              <div style={{ marginTop: 15 }}>
                <MDBSelect
                  onClick={(e) => {
                    e.preventDefault();
                    if (selectLoading || active) {
                      return;
                    }

                    onSelectClick && onSelectClick(office);
                  }}
                  getValue={(value) => onSelect(value)}
                >
                  <MDBSelectInput
                    selected={
                      selectLoading
                        ? "Loading..."
                        : active && selectOptions.length === 0
                        ? "No Buildings found"
                        : "Select a building"
                    }
                  />
                  <MDBSelectOptions>
                    {!selectLoading &&
                      selectOptions.map((o) => (
                        <MDBSelectOption value={o.id}>{o.name}</MDBSelectOption>
                      ))}
                  </MDBSelectOptions>
                </MDBSelect>
              </div>
            )}
          </CardFooter>
        </Card>
      </Link>
    </div>
  );
};
