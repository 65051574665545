const apiBase = process.env.REACT_APP_API_BASE;
export default {
  AIInstrumentationKey: process.env.REACT_APP_AI_INSTRUMENTATION_KEY,
  appSettings: {
    userCanSelectPreviousDates: false,
  },
  api: {
    tenant: process.env.REACT_APP_TENANT_ID,
    clientId: process.env.REACT_APP_CLIENT_ID,
    endpoints: {
      base: `${apiBase}`,
      users: {
        me: `${apiBase}/api/Users/Me`,
        myPhoto: `${apiBase}/api/Users/MyPhoto`,
        photo: `${apiBase}/api/Users/Photo`,
        update: `${apiBase}/api/Users/UpdateUser`,
        list: `${apiBase}/api/Users/GetUsers`,
        addUserConsent: `${apiBase}/api/Users/AddUserConsent`,
      },
      bookings: {
        list: `${apiBase}/api/Bookings/GetBookings`,
        create: `${apiBase}/api/Bookings/CreateBooking`,
        update: `${apiBase}/api/Bookings/UpdateBooking`,
      },
      floorBookings: {
        list: `${apiBase}/api/Bookings/GetFloorVisits`,
        create: `${apiBase}/api/Bookings/VisitFloor`,
        delete: `${apiBase}/api/Bookings/CancelFloorVisit`,
      },
      offices: {
        suggest: `${apiBase}/api/Offices/GetSuggestions`,
        list: `${apiBase}/api/Offices/GetOffices`,
        details: `${apiBase}/api/Offices/GetOffice`,
        locations: `${apiBase}/api/Offices/GetCitiesAndCountries`,
        update: `${apiBase}/api/Offices/UpdateLocation`,
        upload: `${apiBase}/api/Offices/UploadLocationPhoto`,
        uploadPolicy: `${apiBase}/api/Offices/UploadLocationPolicy`,
      },
      buildings: {
        list: `${apiBase}/api/Offices/GetBuildings`,
        edit: `${apiBase}/api/Offices/EditBuilding`,
        create: `${apiBase}/api/Offices/CreateBuilding`,
        delete: `${apiBase}/api/Offices/DeleteBuilding`,
      },
      floors: {
        list: `${apiBase}/api/Offices/GetBuildingFloors`,
      },
      reviews: {
        list: `${apiBase}/api/Reviews/GetReviews`,
        create: `${apiBase}/api/Reviews/AddReview`,
      },
      dashboard: {
        averages: `${apiBase}/api/Dashboard/GetAverages`,
        bookings: `${apiBase}/api/Dashboard/GetBookings`,
        totals: `${apiBase}/api/Dashboard/GetTotals`,
        locations: `${apiBase}/api/Dashboard/GetLocations`,
        locationKPIs: `${apiBase}/api/Dashboard/GetLocationKpis`,
        users: `${apiBase}/api/Dashboard/GetUsersBy`,
      },
      resources: {
        all: `${apiBase}/api/Resources/GetAllResources`,
        allLocations: `${apiBase}/api/Resources/GetAllLocation`,
        languages: `${apiBase}/api/Resources/GetLanguages`,
        countries: `${apiBase}/api/Resources/GetCountries`,
        userTypes: `${apiBase}/api/Resources/GetUserTypes`,
        benefitTypes: `${apiBase}/api/Resources/GetBenefitTypes`,
        bookingStatuses: `${apiBase}/api/Resources/GetBookingStatuses`,
        locationTypes: `${apiBase}/api/Resources/GetLocationTypes`,
      },
    },
  },
  enums: {
    weekDays: {
      0: "Sunday",
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday",
    },
  },
};
