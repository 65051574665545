const defaultState = {
  offices: {},
  isFetching: false,
  fetched: false,
  totalResults: 0,
  error: null
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case "GET_ADMIN_OFFICES_START":
      return {
        ...state,
        isFetching: true,
        fetched: false,
        error: null
      };
    case "GET_ADMIN_OFFICES_ERROR":
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case "GET_ADMIN_OFFICES_SUCCESS":
      return {
        ...state,
        isFetching: false,
        fetched: true,
        totalResults: action.payload.totalResults,
        offices: action.clear
          ? action.payload.offices.reduce((acc, curr) => {
              acc[curr.id] = acc[curr.id] ? acc[curr.id] : curr.office;
              return acc;
            }, {})
          : {
              ...state.offices,
              ...action.payload.offices.reduce((acc, curr) => {
                acc[curr.id] = acc[curr.id] ? acc[curr.id] : curr.office;
                return acc;
              }, {})
            }
      };
    default:
      return state;
  }
};
