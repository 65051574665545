import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { adalApiFetch } from "../../../Auth";
import config from "../../../../config";
import resourcesSelectors from "../../../Resources/selectors";
import resourcesActions from "../../../Resources/actions";
import authSelectors from "../../../Auth/selectors";
import selectors from "../../selectors";
import actions from "../../actions";
import bookingsActions from '../../../Bookings/actions';
import {
  Row,
  Col,
  Container,
  MDBMedia,
  MDBInput,
  MDBBtn as Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Input
} from "mdbreact";
import styled from "styled-components";
import { Rating } from "../../../../components";
const combinedSelectors = state => ({
  ...selectors(state),
  ...resourcesSelectors(state),
  ...authSelectors(state),
});
const combinedActions = {
  ...actions,
  ...resourcesActions,
  ...bookingsActions,
};

const StyledOfficeCover = styled.div`
    width: 50px;
    display: inline-block;
    background-image: url('${({ cover }) => cover}');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;
    overflow: hidden;
    &:before {
        content: '';
        display: block;
        padding-bottom: 100%;
    }
`;
class AddReviewButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal || false,
      status: "pending",
      review: {
        bookingId: this.props.booking.id,
        userRating: 0,
        locationRating: 0,
        benefitIds: [],
        comments: ""
      }
    };
  }

  handleUserRatingChange = userRating => {
    this.setState({ review: { ...this.state.review, userRating } });
  };

  handleTempOfficeRatingChange = locationRating => {
    this.setState({ review: { ...this.state.review, locationRating } });
  };

  handleBenefitChange = e => {
    const benefitIds = [...this.state.review.benefitIds];
    const index = benefitIds.indexOf(e.target.id);
    if (e.target.checked && index === -1) {
      benefitIds.push(e.target.id);
    } else if (!e.target.checked && index > -1) {
      benefitIds.splice(index, 1);
    }
    this.setState({ review: { ...this.state.review, benefitIds } });
  };

  handleReviewSubmit = () => {
    this.setState({ loading: true }, () => {
      adalApiFetch(fetch, config.api.endpoints.reviews.create, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(this.state.review)
      }).then(res => {
        this.setState({ showModal: false }, () => {
          this.props.getBookings();
        });
      });
    });
  };

  render() {
    const { showModal, review, loading } = this.state;
    const {
      size,
      resources,
      booking,
      userData,
      component: CustomComponent
    } = this.props;
    return (
      <div>
        {CustomComponent ? (
          <CustomComponent
            onClick={() => this.setState({ showModal: true })}
            size={size}
          >
            Add Review!
          </CustomComponent>
        ) : (
          <Button
            onClick={() => this.setState({ showModal: true })}
            size={size}
          >
            Add Review
          </Button>
        )}
        <Modal
          backdrop={true}
          centered
          isOpen={showModal}
          toggle={() =>
            this.setState({
              showModal: this.props.showModal || false,
              status: "pending",
              review: {
                bookingId: booking.id,
                userRating: 0,
                locationRating: 0,
                benefitIds: [],
                comments: ""
              }
            })
          }
        >
          <ModalHeader
            toggle={() =>
              this.setState({
                showModal: this.props.showModal || false,
                status: "pending",
                review: {
                  bookingId: booking.id,
                  userRating: 0,
                  locationRating: 0,
                  benefitIds: [],
                  comments: ""
                }
              })
            }
          >
            Write A Review
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <p>
                  Give recognition to colleagues that have gone the extra mile
                  and tell us how Deskovery has benefited you
                </p>
              </Col>
            </Row>
            <Row className="mb-4">
              {booking.host && booking.host.id !== userData.id && (
                <React.Fragment>
                  <Col>
                    <MDBMedia>
                      <MDBMedia left className="mr-3" href="#">
                        <Link
                          to={`/office/${booking.location.id}`}
                          title={booking.location.name}
                        >
                          <StyledOfficeCover
                            cover={booking.location.coverPhotoUrl}
                          />
                        </Link>
                      </MDBMedia>
                      <MDBMedia body>
                        <h6>Rate the Facility:</h6>
                        <Rating
                          style={{ fontSize: "20px" }}
                          onChange={this.handleTempOfficeRatingChange}
                          value={review.locationRating}
                        />
                      </MDBMedia>
                    </MDBMedia>
                  </Col>
                  <Col>
                    <MDBMedia>
                      <MDBMedia left className="mr-3" href="#">
                        <Link
                          to={`/user/${booking.host.id}`}
                          title={booking.host.name}
                        >
                          <StyledOfficeCover cover={booking.host.photoUrl} />
                        </Link>
                      </MDBMedia>
                      <MDBMedia body>
                        <h6>Rate your Host:</h6>
                        <Rating
                          style={{ fontSize: "20px" }}
                          onChange={this.handleUserRatingChange}
                          value={review.userRating}
                        />
                      </MDBMedia>
                    </MDBMedia>
                  </Col>
                </React.Fragment>
              )}
              {booking.guest && booking.guest.id !== userData.id && (
                <React.Fragment>
                  <Col>
                    <MDBMedia>
                      <MDBMedia left className="mr-3" href="#">
                        <Link
                          to={`/user/${booking.guest.id}`}
                          title={booking.guest.name}
                        >
                          <StyledOfficeCover cover={booking.guest.photoUrl} />
                        </Link>
                      </MDBMedia>
                      <MDBMedia body>
                        <h6>Rate your Guest:</h6>
                        <Rating
                          style={{ fontSize: "20px" }}
                          onChange={this.handleUserRatingChange}
                          value={review.userRating}
                        />
                      </MDBMedia>
                    </MDBMedia>
                  </Col>
                </React.Fragment>
              )}
            </Row>
            <Row>
              <Col>
                <h6>What benefits did you get from the visit?</h6>
                {resources.benefits &&
                  resources.benefits
                    .filter(
                      benefit =>
                        benefit.benefitType.id == 3 // benefit is relevant to a host and guest
                          ? true
                          : booking.host // if there is a host in the booking data, it means that the current user is booking's guest
                          ? benefit.benefitType.id == 1 // benefit is relevant to booking's guest
                          : benefit.benefitType.id == 2 // benefit is relevant to booking's host
                    )
                    .map(benefit => (
                      <Input
                        onChange={this.handleBenefitChange}
                        id={benefit.id}
                        type="checkbox"
                        label={benefit.name}
                      />
                    ))}
              </Col>
            </Row>

            <MDBInput
              onChange={e =>
                this.setState({
                  review: { ...this.state.review, comments: e.target.value }
                })
              }
              type="textarea"
              label="How was your overall visit?"
              rows="5"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              gradient="peach"
              disabled={loading}
              onClick={this.handleReviewSubmit}
            >
              Submit
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default connect(
  combinedSelectors,
  combinedActions
)(AddReviewButton);
