import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBDataTable,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  Card,
  CardBody,
  MDBIcon
} from "mdbreact";
import selectors from "./selectors";
import actions from "./actions";
import UsersAdminContainer from './containers/usersAdminContainer';
import OfficesAdminContainer from './containers/officesAdminContainer';

class AdminContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItemClassicTabs1: "1"
    };
  }
  componentDidMount() {}
  toggleClassicTabs1 = (activeItemClassicTabs1) => this.setState({activeItemClassicTabs1})
  render() {
    const {activeItemClassicTabs1} = this.state;
    const {} = this.props;
    return (
      <MDBContainer style={{padding: "30px 0"}}>
        <h1>Admin panel</h1>
        <div className="classic-tabs">
          <MDBNav classicTabs color="primary">
            <MDBNavItem>
              <MDBNavLink
                to="#"
                className={
                  activeItemClassicTabs1 === "1" ? "active" : ""
                }
                onClick={() => this.toggleClassicTabs1("1")}
              >
                Locations
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink
                to="#"
                className={
                  activeItemClassicTabs1 === "2" ? "active" : ""
                }
                onClick={() => this.toggleClassicTabs1("2")}
              >
                Users
              </MDBNavLink>
            </MDBNavItem>
          </MDBNav>
          <MDBTabContent
            className="card"
            activeItem={activeItemClassicTabs1}
          >
            <MDBTabPane tabId="1">
              <OfficesAdminContainer/>
            </MDBTabPane>
            <MDBTabPane tabId="2">
                <UsersAdminContainer/>
            </MDBTabPane>
          </MDBTabContent>
        </div>
      </MDBContainer>
    );
  }
}

const combinedSelectors = state => ({
  // ...selectors(state)
});
const combinedActions = {
  // ...actions
};

export default connect(
  combinedSelectors,
  combinedActions
)(AdminContainer);
