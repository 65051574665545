import React, { Component } from "react";
import { connect } from "react-redux";
import StyledSection from "./styledSection";
import media from "../../media";
import { Row, Col } from "mdbreact";
import resourcesSelectors from "../../modules/Resources/selectors";
class HomeDeskoverySection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <StyledSection id="home-deskovery-section">
        <Row>
          <Col>
            <h1>
              {this.props.resources.texts &&
                this.props.resources.texts[0].strings.filter(
                  (text) => text.key === "homeIntroHeading"
                )[0].translatedText}
            </h1>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <p className="description">
              {this.props.resources.texts &&
                this.props.resources.texts[0].strings.filter(
                  (text) => text.key === "homeIntroDescription"
                )[0].translatedText}
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="text-center" size="md-4">
            <div
              style={{
                backgroundImage: `url(${media.destination})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: 80,
                height: 80,
                margin: "0 auto",
              }}
            />
            <h2>
              {this.props.resources.texts &&
                this.props.resources.texts[0].strings.filter(
                  (text) => text.key === "homeTeaserOneHeading"
                )[0].translatedText}
            </h2>
            <p>
              {this.props.resources.texts &&
                this.props.resources.texts[0].strings.filter(
                  (text) => text.key === "homeTeaserOneDescription"
                )[0].translatedText}
            </p>
          </Col>
          <Col className="text-center" size="md-4">
            <div
              style={{
                backgroundImage: `url(${media.host})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: 80,
                height: 80,
                margin: "0 auto",
              }}
            />
            <h2>
              {this.props.resources.texts &&
                this.props.resources.texts[0].strings.filter(
                  (text) => text.key === "homeTeaserTwoHeading"
                )[0].translatedText}
            </h2>
            <p>
              {this.props.resources.texts &&
                this.props.resources.texts[0].strings.filter(
                  (text) => text.key === "homeTeaserTwoDescription"
                )[0].translatedText}
            </p>
          </Col>
          <Col className="text-center" size="md-4">
            <div
              style={{
                backgroundImage: `url(${media.experience})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: 80,
                height: 80,
                margin: "0 auto",
              }}
            />
            <h2>
              {this.props.resources.texts &&
                this.props.resources.texts[0].strings.filter(
                  (text) => text.key === "homeTeaserThreeHeading"
                )[0].translatedText}
            </h2>
            <p>
              {this.props.resources.texts &&
                this.props.resources.texts[0].strings.filter(
                  (text) => text.key === "homeTeaserThreeDescription"
                )[0].translatedText}
              <br />
              <strong>(Currently disabled)</strong>
            </p>
          </Col>
        </Row>
      </StyledSection>
    );
  }
}

export default connect(resourcesSelectors)(HomeDeskoverySection);
