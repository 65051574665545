import React, { Component } from 'react';
import MainLayout from '../layouts/MainLayout';
import AddOfficeContainer from '../modules/Offices/containers/addOfficeContainer'

const AddOfficeView = (props) => (
    <MainLayout>
        <AddOfficeContainer></AddOfficeContainer>
    </MainLayout>
)


export default AddOfficeView