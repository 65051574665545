import styled from 'styled-components'

const StyledHost = styled.div`

.home-host{
    background-color:rgb(57,66,194);
    padding:60px 0;
}
h3{
    font-weight:bold;
    color:white;
    margin:0 auto;
    padding-bottom:40px;
}

.host-row{
    margin-right:0px;
    margin-left:0px;
}

`

export default StyledHost