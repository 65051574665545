import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn as Button,
  MDBDataTable,
  MDBSwitch,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBIcon,
} from "mdbreact";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import adminSelectors from "../selectors";
import adminActions from "../actions";
import usersActions from "../../Users/actions";
import usersSelectors from "../../Users/selectors";
import officesActions from "../../Offices/actions";
import officesSelectors from "../../Offices/selectors";
import EditOfficeButton from "../../Offices/components/EditOfficeButton";
import CreateOfficeButton from "../components/CreateOfficeButton";

const { SearchBar } = Search;
class OfficesAdminContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const { getAdminOffices } = this.props;
    getAdminOffices({
      activeOnly: false,
    });
  }
  render() {
    const { adminOffices, updateOffice, getAdminOffices } = this.props;
    const {} = this.state;

    const columns = [
      {
        dataField: "id",
        text: "ID",
        sort: true,
        headerAttrs: {
          width: "65px",
        },
      },
      {
        dataField: "name",
        text: "Location title",
        sort: true,
      },
      {
        dataField: "address",
        text: "Country",
        sort: true,
        formatter: (cell) => {
          return cell.country;
        },
      },
      {
        dataField: "address",
        text: "City",
        sort: true,
        formatter: (cell) => {
          return cell.city;
        },
      },
      {
        dataField: "locationType",
        text: "Type",
        sort: true,
        formatter: (cell) => {
          return cell.name;
        },
        headerAttrs: {
          width: "75px",
        },
      },
      {
        dataField: "isActive",
        text: "Status",
        sort: true,
        formatter: (cell, row) => {
          return (
            <div>
              <MDBSwitch
                labelLeft={null}
                labelRight={null}
                checked={row.isActive}
                onChange={(e) => {
                  updateOffice({
                    id: row.id,
                    isActive: !row.isActive,
                  });
                }}
              />
            </div>
          );
        },
        headerAttrs: {
          width: "100px",
        },
      },
      {
        dataField: "id",
        text: "Actions",
        formatter: (cell, row) => {
          return (
            <div>
              <EditOfficeButton
                office={row}
                gradient={null}
                color="primary"
                size="sm"
                style={{ margin: 0 }}
                text="Edit"
                onSubmit={(updatedOffice) =>
                  getAdminOffices({ activeOnly: false })
                }
              />
            </div>
          );
        },
        headerAttrs: {
          width: "100px",
        },
      },
    ];

    return (
      <div>
        <ToolkitProvider
          keyField="id"
          data={Object.keys(adminOffices).map((key) => adminOffices[key])}
          columns={columns}
          search={{
            searchFormatted: true,
          }}
        >
          {(props) => (
            <div>
              <h3>Input something at below input field:</h3>
              <SearchBar {...props.searchProps} />
              {/* <CreateOfficeButton /> */}
              <hr />
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                pagination={paginationFactory()}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}

const combinedSelectors = (state) => ({
  ...usersSelectors(state),
  ...officesSelectors(state),
  ...adminSelectors(state),
});

const combinedActions = {
  ...usersActions,
  ...officesActions,
  ...adminActions,
};

export default connect(
  combinedSelectors,
  combinedActions
)(OfficesAdminContainer);
