const defaultState = {
    loaders: {}
}
export default (state = defaultState, action) => {
    switch(action.type) {
        case 'LOADING': return {
            ...state,
            loaders: {
                ...state.loaders,
                ...action.payload,
            }
        }
        default: return state;
    }
}