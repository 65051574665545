import React, { Component } from "react";
import StyledDetails from "./styledDetails";
import { NavLink } from "react-router-dom";
import { Map } from "../../../../components";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImage,
  CardTitle,
  CardText,
  Row,
  Col,
  Container,
  Fa,
  MDBInput,
  MDBDatePicker
} from "mdbreact";
class OfficeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOffice: null
    };
  }
  handleSelectChange = e => {
    this.setState({
      selectedOffice: e.target.value
    });
  };
  render() {
    const { selectedOffice } = this.state;
    const { offices, onClick } = this.props;
    return (
      <StyledDetails>
        <Row>
          <Col size="md-6">
            <Row>
              <Col size="md-6">
                <h1>Office Details</h1>
                <div className="form-group">
                  <label htmlFor="formGroupExampleInput">
                    Visit for a Application:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="formGroupExampleInput"
                  />
                </div>
                <div class="files">Drag And Drop Images here</div>
              </Col>
              <Col size="md-6">
                <div class="info">
                  <div class="info-content">
                    <h2>Location:</h2>
                    <span>
                      {this.props.selectedOffice.address.city},{" "}
                      {this.props.selectedOffice.address.country}
                    </span>
                  </div>
                  <div class="info-content">
                    <h2>Email:</h2>
                    <span>{this.props.selectedOffice.email}</span>
                  </div>
                  <div class="info-content">
                    <h2>Front Desk Phone Number:</h2>
                    <span>{this.props.selectedOffice.phone}</span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col size="md-12">
                <div class="description">
                  <p>
                    Lorem Ipsum Lorem IpsumLorem Ipsum Lorem Ipsum Lorem Ipsum
                    Lorem Ipsum Lorem IpsumvLorem Lorem IpsumLorem IpsumLorem
                    IpsumLorem IpsumLorem IpsumIpsum Lorem Ipsum
                  </p>
                  <div class="info-content">
                    <h2>Opening Hours:</h2>
                    <span>
                      Monday - Friday{" "}
                      {this.props.selectedOffice.openingHours["Monday-Friday"]}
                    </span>
                  </div>
                </div>
                <NavLink class=" btn grey-gradient cancel-btn" to="/">
                  Cancel
                </NavLink>
                <Button
                  class=" btn peach-gradient continue-btn"
                  onClick={() => onClick()}
                >
                  Continue
                </Button>
              </Col>
            </Row>
          </Col>
          <Col size="md-6">
            <Map
              style={{ height: "100%" }}
              geojson={{
                type: "FeatureCollection",
                features: [
                  {
                    type: "Feature",
                    properties: {
                      name: "Van Dorn Street",
                      "marker-color": "#0000ff",
                      "marker-symbol": "rail-metro",
                      line: "blue"
                    },
                    geometry: {
                      type: "Point",
                      coordinates: [-77.12911152370515, 38.79930767201779]
                    }
                  }
                ]
              }}
            />
          </Col>
        </Row>
      </StyledDetails>
    );
  }
}
export default OfficeDetails;
