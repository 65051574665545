import { adalApiFetch } from "../Auth";
import config from "../../config";
/**
 * GET AVERAGES
 */
const getAveragesStart = () => ({
  type: "GET_AVERAGES_START",
});

const getAveragesSuccess = (payload) => ({
  type: "GET_AVERAGES_SUCCESS",
  payload,
});

const getAveragesError = (error) => ({
  type: "GET_AVERAGES_ERROR",
  error,
});

export const getAverages = () => async (dispatch) => {
  dispatch(getAveragesStart());
  try {
    const payload = await adalApiFetch(
      fetch,
      config.api.endpoints.dashboard.averages
    ).then((res) => {
      if (!res.ok || res.status !== 200) {
        throw new Error(
          "Call to endpoint " +
            res.url +
            " failed with status code " +
            res.status
        );
      }
      if (res.headers.get("content-type").indexOf("application/json") === -1) {
        throw new TypeError(
          'Response from "' + res.url + '" has unexpected "content-type"'
        );
      }
      return res.json();
    });
    return dispatch(getAveragesSuccess(payload));
  } catch (error) {
    return dispatch(getAveragesError(error));
  }
};

/**
 * GET BOOKINGS
 */
const getDashboardBookingsStart = () => ({
  type: "GET_DASHBOARD_BOOKINGS_START",
});

const getDashboardBookingsSuccess = (payload) => ({
  type: "GET_DASHBOARD_BOOKINGS_SUCCESS",
  payload,
});

const getDashboardBookingsError = (error) => ({
  type: "GET_DASHBOARD_BOOKINGS_ERROR",
  error,
});

export const getDashboardBookings = () => async (dispatch) => {
  dispatch(getDashboardBookingsStart());
  try {
    const payload = await adalApiFetch(
      fetch,
      config.api.endpoints.dashboard.bookings
    ).then((res) => {
      if (!res.ok || res.status !== 200) {
        throw new Error(
          "Call to endpoint " +
            res.url +
            " failed with status code " +
            res.status
        );
      }
      if (res.headers.get("content-type").indexOf("application/json") === -1) {
        throw new TypeError(
          'Response from "' + res.url + '" has unexpected "content-type"'
        );
      }
      return res.json();
    });
    return dispatch(getDashboardBookingsSuccess(payload));
  } catch (error) {
    return dispatch(getDashboardBookingsError(error));
  }
};

/**
 * GET TOTALS
 */
const getDashboardTotalsStart = () => ({
  type: "GET_DASHBOARD_TOTALS_START",
});

const getDashboardTotalsSuccess = (payload) => ({
  type: "GET_DASHBOARD_TOTALS_SUCCESS",
  payload,
});

const getDashboardTotalsError = (error) => ({
  type: "GET_DASHBOARD_TOTALS_ERROR",
  error,
});

export const getDashboardTotals = () => async (dispatch) => {
  dispatch(getDashboardTotalsStart());
  try {
    const payload = await adalApiFetch(
      fetch,
      config.api.endpoints.dashboard.totals
    ).then((res) => {
      if (!res.ok || res.status !== 200) {
        throw new Error(
          "Call to endpoint " +
            res.url +
            " failed with status code " +
            res.status
        );
      }
      if (res.headers.get("content-type").indexOf("application/json") === -1) {
        throw new TypeError(
          'Response from "' + res.url + '" has unexpected "content-type"'
        );
      }
      return res.json();
    });
    return dispatch(getDashboardTotalsSuccess(payload));
  } catch (error) {
    return dispatch(getDashboardTotalsError(error));
  }
};

/**
 * GET LOCATIONS
 */
const getDashboardLocationsStart = () => ({
  type: "GET_DASHBOARD_LOCATIONS_START",
});

const getDashboardLocationsSuccess = (payload) => ({
  type: "GET_DASHBOARD_LOCATIONS_SUCCESS",
  payload,
});

const getDashboardLocationsError = (error) => ({
  type: "GET_DASHBOARD_LOCATIONS_ERROR",
  error,
});

export const getDashboardLocations = () => async (dispatch) => {
  dispatch(getDashboardLocationsStart());
  try {
    const payload = await adalApiFetch(
      fetch,
      config.api.endpoints.dashboard.locations
    ).then((res) => {
      if (!res.ok || res.status !== 200) {
        throw new Error(
          "Call to endpoint " +
            res.url +
            " failed with status code " +
            res.status
        );
      }
      if (res.headers.get("content-type").indexOf("application/json") === -1) {
        throw new TypeError(
          'Response from "' + res.url + '" has unexpected "content-type"'
        );
      }
      return res.json();
    });
    return dispatch(getDashboardLocationsSuccess(payload));
  } catch (error) {
    return dispatch(getDashboardLocationsError(error));
  }
};
/**
 * GET LOCATION KPIS
 */
const getDashboardLocationKPIsStart = () => ({
  type: "GET_DASHBOARD_LOCATION_KPIS_START",
});

const getDashboardLocationKPIsSuccess = (payload) => ({
  type: "GET_DASHBOARD_LOCATION_KPIS_SUCCESS",
  payload,
});

const getDashboardLocationKPIsError = (error) => ({
  type: "GET_DASHBOARD_LOCATION_KPIS_ERROR",
  error,
});

export const getDashboardLocationKPIs = (params) => async (dispatch) => {
  dispatch(getDashboardLocationKPIsStart());
  try {
    const paramsString = Object.keys(params)
      .filter((key) => {
        if (
          !params[key] ||
          (Array.isArray(params[key]) && params[key].length === 0)
        )
          return false;
        return true;
      })
      .map((key) => {
        if (
          typeof params[key] === "string" ||
          typeof params[key] === "number"
        ) {
          return `${key}=${encodeURIComponent(params[key])}`;
        }
        if (Array.isArray(params[key])) {
          return params[key].map((param) => `${key}=${param}`).join("&");
        }
        return;
      })
      .join("&");
    const payload = await adalApiFetch(
      fetch,
      `${config.api.endpoints.dashboard.locationKPIs}/?${paramsString}`
    ).then((res) => {
      if (!res.ok || res.status !== 200) {
        throw new Error(
          "Call to endpoint " +
            res.url +
            " failed with status code " +
            res.status
        );
      }
      if (res.headers.get("content-type").indexOf("application/json") === -1) {
        throw new TypeError(
          'Response from "' + res.url + '" has unexpected "content-type"'
        );
      }
      return res.json();
    });
    return dispatch(getDashboardLocationKPIsSuccess(payload));
  } catch (error) {
    return dispatch(getDashboardLocationKPIsError(error));
  }
};

/**
 * GET USERS
 */
const getDashboardUsersStart = () => ({
  type: "GET_DASHBOARD_USERS_START",
});

const getDashboardUsersSuccess = (payload) => ({
  type: "GET_DASHBOARD_USERS_SUCCESS",
  payload,
});

const getDashboardUsersError = (error) => ({
  type: "GET_DASHBOARD_USERS_ERROR",
  error,
});

export const getDashboardUsers = () => async (dispatch) => {
  dispatch(getDashboardUsersStart());
  try {
    const payload = await adalApiFetch(
      fetch,
      config.api.endpoints.dashboard.users
    ).then((res) => {
      if (!res.ok || res.status !== 200) {
        throw new Error(
          "Call to endpoint " +
            res.url +
            " failed with status code " +
            res.status
        );
      }
      if (res.headers.get("content-type").indexOf("application/json") === -1) {
        throw new TypeError(
          'Response from "' + res.url + '" has unexpected "content-type"'
        );
      }
      return res.json();
    });
    return dispatch(getDashboardUsersSuccess(payload));
  } catch (error) {
    return dispatch(getDashboardUsersError(error));
  }
};

export default {
  getAverages,
  getDashboardBookings,
  getDashboardTotals,
  getDashboardLocations,
  getDashboardLocationKPIs,
  getDashboardUsers,
};
