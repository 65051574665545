import React from 'react';
import styled from 'styled-components';
import { Fa } from 'mdbreact';

const StyledRating = styled.span`
    direction: rtl;
    display: inline-block;
    white-space: nowrap;
    .fa {
        font-size: ${({externalStyle}) => externalStyle ? externalStyle.fontSize || 'inherit' : 'inherit'}; 
        color: ${({externalStyle}) => externalStyle ? externalStyle.backgroundColor || '#d8d2d2' : '#d8d2d2'};
        &.active {
            color: ${({externalStyle}) => externalStyle ? externalStyle.color || '#ffbd02' : '#ffbd02'};
            ~ .fa {
                color: ${({externalStyle}) => externalStyle ? externalStyle.color || '#ffbd02' : '#ffbd02'};
            }
        }
        ${({readOnly}) => !readOnly ? `&:hover {
            color: ${({externalStyle}) => externalStyle ? externalStyle.color || '#ffbd02' : '#ffbd02'};
            ~ .fa {
                color: ${({externalStyle}) => externalStyle ? externalStyle.color || '#ffbd02' : '#ffbd02'};
            }
        }` : ''}
    }
`
const Rating = ({ value, onChange, readOnly, style }) => (
    <StyledRating externalStyle={style} readOnly={readOnly}>
        <Fa onClick={() => !readOnly && onChange(5)} className={value > 4 ? 'active':''} icon="star" />
        <Fa onClick={() => !readOnly && onChange(4)} className={value > 3 ? 'active':''} icon="star" />
        <Fa onClick={() => !readOnly && onChange(3)} className={value > 2 ? 'active':''} icon="star" />
        <Fa onClick={() => !readOnly && onChange(2)} className={value > 1 ? 'active':''} icon="star" />
        <Fa onClick={() => !readOnly && onChange(1)} className={value > 0 ? 'active':''} icon="star" />
    </StyledRating>
)

export default Rating