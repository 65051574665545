const defaultState = {
    onboarded: false
}
export default (state = defaultState, action) => {
    switch(action.type) {
        case 'ONBOARDING': return {
            ...state,
            onboarded: action.payload
        }
        default: return state;
    }
}