import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn as Button,
  MDBDataTable,
  MDBSwitch,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBIcon,
} from "mdbreact";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import authActions from "../../Auth/actions"; // TODO move updateUser action to Users Module
import usersActions from "../../Users/actions";
import usersSelectors from "../../Users/selectors";
import officesActions from "../../Offices/actions";
import officesSelectors from "../../Offices/selectors";
import EditUserButton from "../../Users/components/EditUserButton";

const { SearchBar } = Search;
class OfficesAdminContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const { getUsers } = this.props;
    getUsers({});
  }
  render() {
    const { users, updateUser, getUsers } = this.props;
    const {} = this.state;

    const columns = [
      {
        dataField: "id",
        text: "ID",
        sort: true,
        headerAttrs: {
          width: "65px",
        },
      },
      {
        dataField: "name",
        text: "User Name",
        sort: true,
      },
      {
        dataField: "email",
        text: "E-mail",
        sort: true,
      },
      {
        dataField: "lastLoginDateTimeStamp",
        text: "Last Login",
        sort: true,
        formatter: (cell) => {
          var timestamp = new Date(cell * 1000);
          return cell ? (
            <div>
              {timestamp.toLocaleDateString()}
              <br />
              {timestamp.toLocaleTimeString()}
            </div>
          ) : (
            "-"
          );
        },
      },
      {
        dataField: "isAdmin",
        text: "Admin",
        sort: true,
        formatter: (cell, row) => {
          return row.isAdmin ? (
            "User is admin."
          ) : (
            <Button
              color="primary"
              size="sm"
              style={{ whiteSpace: "nowrap", margin: 0 }}
              onClick={() => {
                updateUser({
                  id: row.id,
                  isAdmin: !row.isAdmin,
                });
              }}
            >
              Create Admin
            </Button>
          );
        },
      },
    ];
    return (
      <div>
        <ToolkitProvider
          keyField="id"
          data={Object.keys(users).map((key) => users[key])}
          columns={columns}
          search={{
            searchFormatted: true,
          }}
        >
          {(props) => (
            <div>
              <h3>Input something at below input field:</h3>
              <SearchBar {...props.searchProps} />
              <hr />
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                pagination={paginationFactory()}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}

const combinedSelectors = (state) => ({
  ...usersSelectors(state),
  ...officesSelectors(state),
});

const combinedActions = {
  ...authActions,
  ...usersActions,
  ...officesActions,
};

export default connect(
  combinedSelectors,
  combinedActions
)(OfficesAdminContainer);
