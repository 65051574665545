import styled from "styled-components";
const StyledDetails = styled.div`
  background-color: white;
  padding: 50px 10px 10px;
  .card {
    margin-top: 15px;
  }
  h1 {
    font-weight: bold;
  }
  h2 {
    font-weight: bold;
    font-size: 15px;
  }
  .address {
    color: rgb(247, 107, 28);
  }
  span {
    color: #a6a6a6;
  }
  .office-info {
    background-color: white !important;
  }
  img {
    margin-right: 5px;
  }
`;

export default StyledDetails;
