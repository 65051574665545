import React, { Component, Fragment } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  MDBMedia,
} from "mdbreact";
import Chart from "react-google-charts";
import { Rating } from '../../../../components';
import media from '../../../../media';
/**
 totalHosts: 0,
totalGuests: 0,
totalSuperHosts: 0,
acceptedRequests: 0,
pendingRequests: 0,
canceledRequests: 0,
facilityRating: 0,
hostsRating: 0
 */

class KPISection extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { data } = this.props;
    console.log(data);
    return (
      <Fragment>
        <Row style={{ paddingBottom: 30 }}>
          <Col>
            <Card style={{ minHeight: "100%" }}>
              <CardBody>
                Card 1
                <Rating />
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card style={{ minHeight: "100%" }}>
            <CardHeader>
                <h5>Total Locations: {data.totalLocations}</h5>
              </CardHeader>
              <CardBody style={{padding: 0}}>
              <Chart
                width={'100%'}
                height={'250px'}
                chartType="PieChart"
                loader={<div>Loading...</div>}
                data={[
                  ['Locations', '#'],
                  ['Visited Locations', data.totalVisitedLocations],
                  ['Not Visited Locations', (data.totalLocations - data.totalVisitedLocations)],
                ]}
                options={{
                  colors: ['#2C80D9', '#F76B1C'],
                  pieHole: 0.5,
                  pieSliceText: 'value',
                  chartArea:{top:20,width:'50%',height:'75%'},
                  legend: 'bottom',
                }}
                rootProps={{ 'data-testid': '3' }}
              />
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card style={{ minHeight: "100%" }}>
              <CardHeader>
                <h5>Total requests</h5>
              </CardHeader>
              <CardBody>
                <h5>{data.acceptedRequests}</h5> Accepted <br />
                <h5>{data.pendingRequests}</h5> Pending <br />
                <h5>{data.canceledRequests}</h5> Rejected <br />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row style={{ paddingBottom: 30 }}>
          <Col>
            <Card>
              <CardBody>
                <MDBMedia>
                  <MDBMedia body>
                    Average Duration of Stay
                    <MDBMedia heading>{data.averageVisitDuration}</MDBMedia>
                  </MDBMedia>
                  <MDBMedia right className="ml-3">
                    <img
                      src={media["averageDuration"]}
                      alt="average duration of stay"
                    />
                  </MDBMedia>
                </MDBMedia>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardBody>
                <MDBMedia>
                  <MDBMedia body>
                    Average Location Rating
                    <MDBMedia heading>{data.facilityRating / data.ratedLocations} / 5</MDBMedia>
                  </MDBMedia>
                  <MDBMedia right className="ml-3">
                    <img
                      src={media["locationRating"]}
                      alt="average location rating"
                    />
                  </MDBMedia>
                </MDBMedia>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardBody>
                <MDBMedia>
                  <MDBMedia body>
                    Average Hosts Rating
                    <MDBMedia heading>{data.hostsRating} / 5</MDBMedia>
                  </MDBMedia>
                  <MDBMedia right className="ml-3">
                    <img src={media["hostRating"]} alt="Average Host Rating" />
                  </MDBMedia>
                </MDBMedia>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default KPISection;
