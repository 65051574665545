const defaultState = {
  users: {},
  loading: false,
  error: null
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case "GET_USERS_START": {
      return { ...state, loading: true };
    }
    case "GET_USERS_ERROR": {
      return { ...state, loading: false, error: action.payload };
    }
    case "GET_USERS_SUCCESS": {
      return {
        ...state,
        loading: false,
        users: { ...state.users, ...action.payload }
      };
    }
    case "UPDATE_USER_SUCCESS": {
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.id]: action.payload
        }
      };
    }
    default:
      return state;
  }
};
