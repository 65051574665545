import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBBtn as Button, toast } from 'mdbreact';
import authSelectors from '../../../Auth/selectors';
import authActions from '../../../Auth/actions';
import usersSelectors from '../../../Users/selectors';
import usersActions from '../../../Users/actions';
import { adalApiFetch } from '../../../Auth';
import config from '../../../../config';

class AddOfficeButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  addOffice = (userId, officeId) => {
    const { getUsers } = this.props;
    this.setState({ loading: true }, () => {
      adalApiFetch(fetch, config.api.endpoints.users.update, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: userId,
          addOffices: [officeId],
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          getUsers({
            userIds: [userId],
          }).then((action) => {
            toast.success(
              `You have successfully added this office to the list of locations you host in.`,
              {
                position: 'top-center',
              }
            );
            this.setState({ loading: false });
          });
        });
    });
  };

  removeOffice = (userId, officeId) => {
    const { getUsers } = this.props;
    this.setState({ loading: true }, () => {
      adalApiFetch(fetch, config.api.endpoints.users.update, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: userId,
          removeOffices: [officeId],
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          getUsers({
            userIds: [userId],
          }).then((action) => {
            toast.success(
              `You have successfully removed this office from the list of locations you host in.`,
              {
                position: 'top-center',
              }
            );
            this.setState({ loading: false });
          });
        });
    });
  };

  render() {
    const { loading } = this.state;
    const { users, userData, office, action } = this.props;
    return userData.userType.id !== 2 ? null : ( //If you're not a host you should not be able to see this button
      <React.Fragment>
        {users[userData.id] &&
          users[userData.id].hostedLocationIds.indexOf(office.id) === -1 && (
            <Button
              {...this.props}
              gradient={
                this.props.gradient === undefined
                  ? 'peach'
                  : this.props.gradient
              }
              color='success'
              onClick={() => this.addOffice(userData.id, office.id)}
            >
              {loading ? 'loading' : `Become Host here`}{' '}
            </Button>
          )}
        {users[userData.id] &&
          users[userData.id].hostedLocationIds.indexOf(office.id) !== -1 && (
            <Button
              {...this.props}
              gradient={
                this.props.gradient === undefined
                  ? 'peach'
                  : this.props.gradient
              }
              color='danger'
              onClick={() => this.removeOffice(userData.id, office.id)}
            >
              {loading ? 'loading' : `Stop hosting here`}{' '}
            </Button>
          )}
      </React.Fragment>
    );
  }
}

const combinedSelectors = (state) => ({
  ...authSelectors(state),
  ...usersSelectors(state),
});

const combinedActions = {
  ...authActions,
  ...usersActions,
};

export default connect(combinedSelectors, combinedActions)(AddOfficeButton);
