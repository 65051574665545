import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  MDBBtn as Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  toast,
} from 'mdbreact';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import selectors from '../../selectors';
import actions from '../../actions';
import config from '../../../../config';
const {
  appSettings: { userCanSelectPreviousDates },
} = config;
class BookNowButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal || false,
      startDate: null,
      endDate: null,
      focusedInput: null,
    };
  }

  handleBookingSubmit = () => {
    const { host, office, createBooking } = this.props;
    this.setState({ showModal: false }, () => {
      createBooking({
        locationId: office.id,
        hostId: host ? host.id : null,
        startDateTimestamp: new Date(this.state.startDate).getTime() / 1000,
        endDateTimestamp: new Date(this.state.endDate).getTime() / 1000,
      }).then((action) => {
        switch (action.type) {
          case 'GET_BOOKINGS_SUCCESS': {
            toast.success(
              `You have successfully booked a visit to ${office.name} with ${
                host ? host.name : 'random Host'
              }`,
              {
                position: 'top-center',
              }
            );
            return;
          }
          case 'GET_BOOKINGS_ERROR': {
            toast.error(
              `Your booking was not created. Please try again later.`,
              {
                position: 'top-center',
              }
            );
            return;
          }
        }
      });
    });
  };

  render() {
    const { showModal, review } = this.state;
    const { size, office, host, style } = this.props;
    return (
      <Fragment>
        <Button
          onClick={() => {
            alert(
              'This feature is disabled due to the current pandemic situation.'
            );
            return;
            this.setState({ showModal: true });
          }}
          // gradient="peach"
          color='orange'
          {...this.props}
          style={{ opacity: 0.5 }}
          disabled={false}
        >
          Book now
        </Button>
        <Modal
          backdrop={true}
          centered
          backdropClassName='modalZFix'
          isOpen={showModal}
          toggle={() => this.setState({ showModal: false })}
        >
          <ModalHeader
            className='text-left'
            toggle={() => this.setState({ showModal: false })}
          >
            Visit in <strong>{office.name}</strong>
            {host && (
              <React.Fragment>
                with <strong>{host.name}</strong>
              </React.Fragment>
            )}
          </ModalHeader>
          <ModalBody className='text-left'>
            <h6>Please select the dates of your visit:</h6>
            <div className='d-flex align-content-center justify-content-between'>
              <DateRangePicker
                startDateId='startDate'
                endDateId='endDate'
                {...(userCanSelectPreviousDates
                  ? { isOutsideRange: () => false }
                  : {})} // enable or disable past dates (toggle from config file)
                numberOfMonths={1}
                showDefaultInputIcon={true}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onDatesChange={({ startDate, endDate }) => {
                  if (startDate || endDate) {
                    this.setState({
                      startDate: startDate || endDate,
                      endDate: endDate || startDate,
                    });
                  }
                }}
                focusedInput={this.state.focusedInput}
                onFocusChange={(focusedInput) => {
                  this.setState({ focusedInput });
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className='mt-0'
              disabled={!this.state.startDate && !this.state.endDate}
              color='orange'
              onClick={this.handleBookingSubmit}
            >
              Book now
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default connect(selectors, actions)(BookNowButton);
