const defaultState = {
  averages: {},
  totals: {},
  bookings: {},
  users: [],
  locations: {
    offices: [],
  },
  locationKPIs: null,
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case "GET_AVERAGES_SUCCESS":
      return {
        ...state,
        averages: action.payload,
      };
    case "GET_DASHBOARD_BOOKINGS_SUCCESS":
      return {
        ...state,
        bookings: action.payload,
      };
    case "GET_DASHBOARD_TOTALS_SUCCESS":
      return {
        ...state,
        totals: action.payload,
      };
    case "GET_DASHBOARD_LOCATIONS_SUCCESS":
      return {
        ...state,
        locations: action.payload,
      };
    case "GET_DASHBOARD_USERS_SUCCESS":
      return {
        ...state,
        users: action.payload,
      };
    case "GET_DASHBOARD_LOCATION_KPIS_SUCCESS":
      return {
        ...state,
        locationKPIs: {
          ...action.payload,
        },
      };

    case "GET_DASHBOARD_LOCATION_KPIS_START":
      return {
        ...state,
        locationKPIs: null,
      };
    case "GET_DASHBOARD_LOCATION_KPIS_ERROR":
      return {
        ...state,
        locationKPIs: null,
      };

    default:
      return state;
  }
};
