import React, { useState } from 'react';

import './UserConsentModal.scss';

import { UserConsentForm } from '../../components';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  MDBBtn as Button,
  MDBSpinner as Spinner,
} from 'mdbreact';
import { connect } from 'react-redux';
import { selectors as authSelector } from '../../../Auth';
import authActions from '../../../Auth/actions';

function UserConsentModal({
  userData,
  addUserConsent,
  addConsentLoading,
  error,
}) {
  const { userConsent } = userData;
  const [accepted, setAccepted] = useState(false);

  const open = !userConsent;

  return (
    <Modal
      isOpen={open}
      size='lg'
      backdropClassName='modalZFix modalSelfZfix'
      className='userConsentModal'
      centered
    >
      <ModalHeader tag='h2'>Welcome to Deskovery! </ModalHeader>

      <ModalBody>
        <UserConsentForm
          isAccepted={accepted}
          onAccept={(e) => {
            setAccepted(e.target.checked);
          }}
        />
      </ModalBody>

      <ModalFooter>
        {addConsentLoading && <Spinner />}

        {error && (
          <p className='red-text'>
            Sorry, something went wrong. Our developer team is working on it.
          </p>
        )}

        <Button onClick={addUserConsent} disabled={!accepted} color='primary'>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default connect((state) => ({ ...authSelector(state) }), {
  ...authActions,
})(UserConsentModal);
