import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { adalApiFetch } from '../../index';
import media from '../../../../media';
import { debounce } from '../../../../utilities';
import config from '../../../../config';
import actions from '../../actions';
import selectors from '../../selectors';
import officeActions from '../../../Offices/actions';
import officeSelectors from '../../../Offices/selectors';
import resourcesActions from '../../../Resources/actions';
import resourcesSelectors from '../../../Resources/selectors';
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Fa,
  Input,
  MDBBtn as Button,
  MDBChip,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
  MDBMedia,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
  MDBStep,
  MDBStepper,
  MDBTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  toast,
} from 'mdbreact';
import { SlidesContainer, Slide, Pager } from './styledComponents';

const combinedSelectors = (state) => ({
  ...selectors(state),
  ...officeSelectors(state),
  ...resourcesSelectors(state),
});
const combinedActions = {
  ...actions,
  ...officeActions,
  ...resourcesActions,
};

class BecomeHostButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal || false,
      activeSlide: 0,
      selectedCountry: null,
      selectedCity: null,
      officeDtos: [],
      selectedOffices: [],
      dto: {
        termsAndConditionsAccepted: false,
        addOffices: [],
        spokenLanguagesIds: [],
      },
      filter: '',
      focusedInput: null,
      loading: false,
      error: null,
    };
  }

  getOffices = () => {
    const { dto, filter } = this.state;
    this.setState(
      {
        loading: true,
        officeDtos: [],
      },
      () => {
        adalApiFetch(fetch, config.api.endpoints.offices.list, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: filter,
            onlyActive: true,
          }),
        })
          .then((res) => res.json())
          .then(({ totalResults, offices }) =>
            this.setState({
              loading: false,
              officeDtos: offices
                .map((item) => item.office)
                .filter((office) => dto.addOffices.indexOf(office.id) == -1),
            })
          )
          .catch((error) => {
            console.log(error);
            this.setState({ loading: false, error });
          });
      }
    );
  };
  handleHostSubmit = () => {
    const { dto } = this.state;
    const { updateUser } = this.props;
    updateUser(dto).then((action) => {
      switch (action.type) {
        case 'UPDATE_USER_SUCCESS': {
          toast.success(`Congratulations! You are now a Host.`, {
            position: 'top-center',
          });
          break;
        }
      }
      this.setState({ showModal: false, activeSlide: 1 });
    });
  };
  handleNextSlide = () => {
    if (this.state.activeSlide < 3) {
      this.setState({
        activeSlide: this.state.activeSlide + 1,
      });
    }
  };

  handleFilterChange = (e) => {
    this.setState({ filter: e.target.value });
  };
  handleFilterKeyUp = (e) => {
    if (e.keyCode === 13) {
      this.getOffices();
    }
  };

  handleToggleOffice = (office) => {
    const { dto, selectedOffices, officeDtos } = this.state;
    let tempOffices = [...selectedOffices];
    if (tempOffices.map((office) => office.id).indexOf(office.id) > -1) {
      tempOffices.splice(
        tempOffices.map((office) => office.id).indexOf(office.id),
        1
      );
    } else {
      tempOffices.push(office);
    }
    this.setState({
      dto: {
        ...dto,
        addOffices: tempOffices.map((office) => office.id),
      },
      selectedOffices: tempOffices,
    });
  };

  handleCheck = (e) => {
    const { dto } = this.state;
    this.setState({
      dto: {
        ...dto,
        termsAndConditionsAccepted: e.target.checked,
      },
    });
  };
  render() {
    const {
      loading,
      officeDtos,
      selectedOffices,
      showModal,
      review,
      activeSlide,
      dto,
    } = this.state;
    const { size, resources } = this.props;
    return (
      <Fragment>
        <Button
          onClick={() => {
            alert(
              'This feature is disabled due to the current pandemic situation.'
            );
            return;
            this.setState({ showModal: true });
          }}
          // disabled={Object.keys(resources).lenght === 0}
          gradient='peach'
          size={size}
          {...this.props}
          style={{ opacity: 0.5 }}
          disabled={false}
        >
          {this.props.text || 'Become A Host'}
        </Button>

        <Modal
          backdrop={true}
          centered
          backdropClassName='modalZFix'
          isOpen={showModal}
          toggle={() => this.setState({ showModal: false })}
        >
          <ModalHeader
            className='text-left'
            toggle={() => this.setState({ showModal: false })}
          >
            Become a Host in 3 easy steps
          </ModalHeader>
          <ModalBody className='text-left'>
            {activeSlide > 0 && (
              <MDBStepper form>
                <MDBStep form>
                  <MDBTooltip placement='bottom' tooltipContent='Your details'>
                    <Button
                      onClick={() => {
                        this.setState({ activeSlide: 1 });
                      }}
                      size='lg'
                      gradient={activeSlide === 1 ? 'peach' : 'heavy-rain'}
                      circle
                    >
                      <Fa icon='user-o' />
                    </Button>
                  </MDBTooltip>
                </MDBStep>
                <MDBStep form>
                  <MDBTooltip placement='bottom' tooltipContent='Your offices'>
                    <Button
                      onClick={() => {
                        this.setState({ activeSlide: 2 });
                      }}
                      size='lg'
                      gradient={activeSlide === 2 ? 'peach' : 'heavy-rain'}
                      circle
                    >
                      <Fa icon='building-o' />
                    </Button>
                  </MDBTooltip>
                </MDBStep>
                <MDBStep form>
                  <MDBTooltip
                    placement='bottom'
                    tooltipContent='Terms and conditions'
                  >
                    <Button
                      onClick={() => {
                        this.setState({ activeSlide: 3 });
                      }}
                      disabled={activeSlide <= 2 && dto.addOffices.length == 0}
                      size='lg'
                      gradient={activeSlide === 3 ? 'peach' : 'heavy-rain'}
                      circle
                    >
                      <Fa icon='file-o' />
                    </Button>
                  </MDBTooltip>
                </MDBStep>
              </MDBStepper>
            )}
            <SlidesContainer>
              <Slide active={activeSlide == 0}>
                <p>
                  {this.props.resources.texts &&
                    this.props.resources.texts[0].strings.filter(
                      (text) => text.key === 'becomHostIntroDescription'
                    )[0].translatedText}
                </p>
                <img
                  style={{
                    borderRadius: '5px',
                    boxShadow: '0 10px 30px -10px rgba(0,0,0,.5)',
                    maxWidth: '100%',
                  }}
                  src={media['hostIntro']}
                />
              </Slide>
              <Slide active={activeSlide == 1}>
                <Container>
                  <Row>
                    <Col middle>
                      <div
                        style={{
                          fontSize: 20,
                          fontWeight: '400',
                          marginBottom: 12,
                        }}
                      >
                        Let your guest know more about you.
                      </div>
                      <div>
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: '400',
                            marginBottom: 12,
                          }}
                        >
                          What languages do you speak?
                        </div>
                        {resources.languages && (
                          <Select
                            isMulti
                            options={Object.keys(resources.languages).map(
                              (key) => ({
                                value: resources.languages[key].id,
                                label: resources.languages[key].displayName,
                              })
                            )}
                            onChange={(selectedOptions, action) => {
                              this.setState({
                                dto: {
                                  ...this.state.dto,
                                  spokenLanguagesIds: selectedOptions.map(
                                    (option) => parseInt(option.value)
                                  ),
                                },
                              });
                            }}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Slide>
              <Slide active={activeSlide == 2}>
                <Container>
                  <Row>
                    <Col middle>
                      <div
                        style={{
                          fontSize: 20,
                          fontWeight: '400',
                          marginBottom: 12,
                        }}
                      >
                        Select offices you want to host in.
                      </div>
                      {selectedOffices.map((office) => (
                        <MDBChip
                          waves
                          close
                          handleClose={() => this.handleToggleOffice(office)}
                          key={`office-${office.id}`}
                        >
                          {office.name}
                        </MDBChip>
                      ))}
                    </Col>
                  </Row>
                  <Row className='align-items-center'>
                    <Col size='10'>
                      <input
                        className='form-control form-control-sm'
                        type='text'
                        placeholder='Find your offices'
                        aria-label='Search'
                        style={{ flexGrow: 1, marginBottom: 0 }}
                        onChange={(e) => {
                          this.handleFilterChange(e);
                          debounce(this.getOffices, 1000)();
                        }}
                        onKeyUp={this.handleFilterKeyUp}
                      />
                    </Col>
                    <Col size='2'>
                      <Button
                        rounded
                        size='sm'
                        circle
                        onClick={() => this.getOffices()}
                      >
                        {' '}
                        <Fa icon='search' />
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='text-center'>
                      {loading && <Spinner small />}
                    </Col>
                  </Row>
                  <MDBListGroup
                    style={{
                      maxHeight: '250px',
                      overflowX: 'hidden',
                      overflowY: 'auto',
                    }}
                  >
                    {officeDtos
                      .filter(
                        (dto) =>
                          selectedOffices
                            .map((office) => office.id)
                            .indexOf(dto.id) == -1
                      )
                      .map((office) => {
                        return (
                          <MDBListGroupItem>
                            <MDBMedia>
                              <MDBMedia className='pb-0' left>
                                <img
                                  style={{ width: 30, height: 30 }}
                                  src={office.coverPhotoUrl}
                                />
                              </MDBMedia>
                              <MDBMedia body>
                                <Row>
                                  <Col size='8'>
                                    <h5>{office.name}</h5> {office.address.city}
                                    , {office.address.city}
                                  </Col>
                                  <Col size='4' style={{ textAlign: 'right' }}>
                                    <Button
                                      onClick={() =>
                                        this.handleToggleOffice(office)
                                      }
                                      tag='a'
                                      size='sm'
                                      gradient='aqua'
                                    >
                                      <MDBIcon
                                        icon={
                                          dto.addOffices.indexOf(office.id) > -1
                                            ? 'remove'
                                            : 'plus'
                                        }
                                      />
                                    </Button>
                                  </Col>
                                </Row>
                              </MDBMedia>
                            </MDBMedia>
                          </MDBListGroupItem>
                        );
                      })}
                  </MDBListGroup>
                </Container>
              </Slide>
              <Slide active={activeSlide == 3}>
                <Container>
                  <Row>
                    <Col>
                      <Card>
                        <CardBody>
                          <CardTitle>Terms and Conditions</CardTitle>
                          <CardText>
                            {this.props.resources.texts &&
                              this.props.resources.texts[0].strings.filter(
                                (text) => text.key === 'termsAndConditions'
                              )[0].translatedText}
                          </CardText>
                          <Input
                            label='I agree'
                            onChange={this.handleCheck}
                            type='checkbox'
                            id='checkbox1'
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </Slide>
            </SlidesContainer>
          </ModalBody>
          <ModalFooter>
            {activeSlide < 3 && (
              <Row style={{ width: '100%' }}>
                {activeSlide === 2 && dto.addOffices.length === 0 && (
                  <Col className='text-left'>
                    You need to select at least one office to become a Host.
                  </Col>
                )}
                <Col className='text-right'>
                  <Button
                    className='ml-2 mt-0'
                    disabled={activeSlide === 2 && dto.addOffices.length === 0}
                    gradient='peach'
                    onClick={this.handleNextSlide}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            )}
            {activeSlide === 3 && (
              <Button
                className='mt-0'
                disabled={!dto.termsAndConditionsAccepted}
                gradient='peach'
                onClick={this.handleHostSubmit}
              >
                Make me a Host!
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default connect(combinedSelectors, combinedActions)(BecomeHostButton);
