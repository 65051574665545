export const averages = (state) => state.Dashboard.averages;
export const bookings = (state) => state.Dashboard.bookings;
export const totals = (state) => state.Dashboard.totals;
export const locations = (state) => state.Dashboard.locations;
export const dashboardUsers = (state) => state.Dashboard.users;
export const locationKPIs = (state) => state.Dashboard.locationKPIs;

export default (state) => ({
  averages: averages(state),
  bookings: bookings(state),
  totals: totals(state),
  locations: locations(state),
  dashboardUsers: dashboardUsers(state),
  locationKPIs: locationKPIs(state),
});
