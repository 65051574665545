const defaultState = {
    name: 'SampleModule'
}
export default (state = defaultState, action) => {
    switch(action.type) {
        case 'CHANGE_NAME': return {
            ...state,
            name: action.payload
        }
        default: return state;
    }
}