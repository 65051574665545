import React from "react";

import {
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBBtn as Button,
  Fa,
} from "mdbreact";
import { SmallOfficeDetails } from "../../../components";
import { Link } from "react-router-dom";
import { UserPicture } from "../../../../../components";

// TODO: this could be used everywhere where we display booked hosts
export default function BookedHostTable({ data }) {
  return (
    <MDBTable responsive btn>
      <MDBTableHead>
        <tr>
          <th>Office </th>
          <th>Location rating </th>
          <th>Period </th>
          <th>Host </th>
          <th>Status </th>
          <th>
            <span className="srOnly">Actions</span>
          </th>
        </tr>
      </MDBTableHead>

      <MDBTableBody>
        {data.length > 0 ? (
          data.map((row) => {
            const {
              location,
              startDateTimeStamp,
              endDateTimeStamp,
              host,
              bookingStatus,
            } = row;

            return (
              <tr>
                <td>
                  <SmallOfficeDetails office={location} />
                </td>

                <td>
                  <div className="ratingCell">
                    <span>
                      <Fa icon="star" />
                    </span>

                    {parseFloat(location.facilityRating).toFixed(1)}
                  </div>
                </td>

                <td>
                  <div>
                    {[
                      `${new Date(
                        startDateTimeStamp * 1000
                      ).toLocaleDateString()}`,
                      `${new Date(
                        endDateTimeStamp * 1000
                      ).toLocaleDateString()}`,
                    ].map((date, i) => (
                      <div className="text-nowrap">{date}</div>
                    ))}
                  </div>
                </td>

                <td>
                  <div>
                    {host && (
                      <Link to={`/user/${host.id}`}>
                        <UserPicture avatar={host.photoUrl} name={host.name} />
                      </Link>
                    )}

                    {host == null && "Random host"}
                  </div>
                </td>

                <td>
                  <span>
                    {bookingStatus.id == 1 &&
                      startDateTimeStamp * 1000 > new Date().getTime() &&
                      bookingStatus.name}
                    {bookingStatus.id == 1 &&
                      startDateTimeStamp * 1000 < new Date().getTime() &&
                      "Expired"}
                    {bookingStatus.id == 3 && row.bookingStatus.name}
                    {bookingStatus.id == 2 &&
                      startDateTimeStamp * 1000 > new Date().getTime() &&
                      "Approved"}
                    {bookingStatus.id == 2 &&
                      endDateTimeStamp * 1000 < new Date().getTime() &&
                      "Completed"}
                  </span>
                </td>

                <td>
                  {/**
                   * TODO: Add actions
                   */}
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={5}>Empty result... </td>
          </tr>
        )}
      </MDBTableBody>
    </MDBTable>
  );
}
