import React, { Component, Fragment } from "react";

import "./style.scss";

import { connect } from "react-redux";
import {
  MDBBtn as Button,
  Row,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  toast,
} from "mdbreact";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import selectors from "../../../selectors";
import actions from "../../../actions";
import bookingsSelectors from "../../../../Bookings/selectors";
import bookingsActions from "../../../../Bookings/actions";
import config from "../../../../../config";
import moment from "moment";
import ConfirmationModal from "../../../../../components/ConfirmationModal/ConfirmationModal";
import { fetchOffice } from "../../../fetchHelpers";

const {
  appSettings: { userCanSelectPreviousDates },
} = config;

class PlanVisitModal extends Component {
  constructor(props) {
    super(props);

    let calcStartDate = null;
    let startDateDisabled = false;
    let selectedFloorId = null;

    if (props.currentVisit) {
      const isBef = moment(props.currentVisit.startDate).isBefore(
        moment(`${moment().format("YYYY-MM-DD")} 00:00`)
      );
      if (isBef) {
        calcStartDate = moment(props.currentVisit.startDate);
        startDateDisabled = true;
        selectedFloorId = props.currentVisit.floorId;
      }
    }

    this.state = {
      startDate: calcStartDate,
      startDateDisabled,
      endDate: null,
      selectedFloorId,
      focusedInput: null,
      floorsAvailability: null,
      showRebookModal: false,
      office: props.office,
    };
  }

  componentDidMount() {
    const getOffice = async () => {
      const office = await fetchOffice(this.props.officeId);

      this.setState({
        ...this.state,
        office,
      });
    };

    if (!this.props.office) {
      getOffice();
    }
  }

  fetchBuildingFloors = () => {
    const { buildingId, getBuildingFloors } = this.props;
    const { startDate, endDate } = this.state;

    getBuildingFloors(
      buildingId,
      startDate.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD")
    ).then((action) => {
      if (action.type.indexOf("_SUCCESS") < 0) return;
      this.setState({ floorsAvailability: action.payload });
    });
  };

  handleBookingSubmit = () => {
    const { selectedFloorId, startDate, endDate } = this.state;
    const { visitFloor, onToggle, currentVisit, hideRebookModal } = this.props;

    visitFloor(
      selectedFloorId,
      startDate.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD"),
      currentVisit ? currentVisit.id : undefined
    ).then((action) => {
      switch (action.type) {
        case "GET_FLOOR_VISITS_SUCCESS": {
          toast.success(
            `Your booking was created and approved automatically.`,
            {
              position: "top-center",
            }
          );

          this.setState({
            ...this.state,
            startDate: null,
            endDate: null,
            selectedFloorId: null,
            focusedInput: null,
            floorsAvailability: null,
            showRebookModal: !hideRebookModal,
          });

          onToggle();
          return;
        }
        case "GET_FLOOR_VISITS_ERROR": {
          toast.error(`Your booking was not created. Please try again later.`, {
            position: "top-center",
          });
          return;
        }
        default:
          return;
      }
    });
  };

  render() {
    const { showRebookModal, office } = this.state;
    const {
      buildingId,
      buildingName,
      floorVisits,
      onToggle,
      showModal,
      currentVisit,
    } = this.props;

    const calculatedFloorVisists = currentVisit
      ? floorVisits.filter((fv) => fv.id !== currentVisit.id)
      : floorVisits;

    const availableFloors = this.handleAvailableFloors();

    return (
      <Fragment>
        <Modal
          backdrop={true}
          centered
          backdropClassName="modalZFix"
          isOpen={showModal}
          toggle={() => onToggle()}
        >
          <ModalHeader className="text-left" toggle={() => onToggle()}>
            {buildingName && (
              <React.Fragment>
                Visiting <strong>{buildingName}</strong>
              </React.Fragment>
            )}{" "}
            {office && (
              <React.Fragment>
                in <strong>{office.name}</strong>
              </React.Fragment>
            )}
          </ModalHeader>

          <ModalBody className="text-left">
            <h6>Please select the dates of your visit (up to 5 days):</h6>

            <div className="visitDateRangePickerContainer">
              <div className="legendContainer">
                <span className="legend alreadyBooked">
                  <span className="text">Dates that are already booked</span>
                </span>
              </div>

              <div className="d-flex align-content-center justify-content-between">
                <DateRangePicker
                  startDateId="startDate"
                  endDateId="endDate"
                  numberOfMonths={1}
                  minimumNights={0}
                  showDefaultInputIcon={true}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  firstDayOfWeek={1}
                  focusedInput={this.state.focusedInput}
                  onFocusChange={(focusedInput) => {
                    this.setState({
                      focusedInput:
                        focusedInput && this.state.startDateDisabled
                          ? "endDate"
                          : focusedInput,
                    });
                  }}
                  // {...(userCanSelectPreviousDates
                  //   ? { isOutsideRange: () => false }
                  //   : {})} // enable or disable past dates (toggle from config file)
                  onDatesChange={({ startDate, endDate }) => {
                    if (startDate || endDate) {
                      this.setState(
                        {
                          startDate: this.state.startDateDisabled
                            ? this.state.startDate
                            : startDate || endDate,
                          endDate: endDate || startDate,
                        },
                        this.fetchBuildingFloors
                      );
                    }
                  }}
                  isOutsideRange={(date) => {
                    const dayLimit = 5;

                    if (
                      date.isAfter(
                        moment(`${moment().format("YYYY-MM-DD")} 23:59`).add(
                          14,
                          "days"
                        )
                      )
                    ) {
                      //  Bookings in advance allowed to two weeks maximum.
                      return true;
                    }

                    if (
                      date.isBefore(
                        moment(`${moment().format("YYYY-MM-DD")} 00:00`)
                      )
                    ) {
                      // Past dates should be out of range
                      return true;
                    }

                    if (
                      this.state.startDate &&
                      this.state.focusedInput == "endDate" &&
                      moment(date.format("YYYY-MM-DD")).isAfter(
                        this.state.startDate.clone().add(dayLimit - 1, "days")
                      )
                    ) {
                      // Max length is 5 days, so dates with longer
                      // lengths should be out of range
                      return true;
                    }

                    for (let i = 0; i < calculatedFloorVisists.length; i++) {
                      const visit = calculatedFloorVisists[i];

                      if (visit.buildingId !== buildingId) {
                        // We dont care about visits in other buildings
                        continue;
                      }

                      const isSameAsStart =
                        date.format("YYYY-MM-DD") ===
                        moment(visit.startDate).format("YYYY-MM-DD");

                      const isSameAsEnd =
                        date.format("YYYY-MM-DD") ===
                        moment(visit.endDate).format("YYYY-MM-DD");

                      const isInBetween =
                        date.isBefore(visit.endDate) &&
                        date.isAfter(visit.startDate);

                      if (isSameAsStart || isSameAsEnd || isInBetween) {
                        return true;
                      }
                    }

                    return false;
                  }}
                  isDayHighlighted={(date) => {
                    for (let i = 0; i < calculatedFloorVisists.length; i++) {
                      const visit = calculatedFloorVisists[i];

                      const isSameAsStart =
                        date.format("YYYY-MM-DD") ===
                        moment(visit.startDate).format("YYYY-MM-DD");

                      const isSameAsEnd =
                        date.format("YYYY-MM-DD") ===
                        moment(visit.endDate).format("YYYY-MM-DD");

                      const isInBetween =
                        date.isBefore(visit.endDate) &&
                        date.isAfter(visit.startDate);

                      if (isSameAsStart || isSameAsEnd || isInBetween) {
                        return true;
                      }
                    }

                    return false;
                  }}
                />
              </div>
            </div>

            {this.state.startDate && this.state.endDate && (
              <>
                <hr />

                <h6>
                  For your safety we advice you select the floor where you
                  usually work at.
                </h6>

                <div>
                  <select
                    className="browser-default custom-select"
                    disabled={
                      this.state.startDateDisabled ||
                      Object.keys(availableFloors).length === 0
                    }
                    value={this.state.selectedFloorId || "none"}
                    onChange={(e) => {
                      this.setState({
                        ...this.state,
                        selectedFloorId: !!e.target.value
                          ? e.target.value
                          : null,
                      });
                    }}
                  >
                    <option value="none">
                      {Object.keys(availableFloors).length === 0
                        ? "Loading available floors..."
                        : "Select one of the available floors"}
                    </option>
                    {Object.keys(availableFloors)
                      .map((key) => availableFloors[key])
                      .sort((a, b) =>
                        a.orderIndex > b.orderIndex
                          ? 1
                          : a.orderIndex < b.orderIndex
                          ? -1
                          : 0
                      )
                      .map((floor) => {
                        return (
                          <option
                            disabled={floor.capacity <= 0}
                            value={floor.id}
                          >
                            {floor.label}{" "}
                            {floor.capacity > 0
                              ? ` (${floor.capacity}/${floor.totalCapacity} available desks)`
                              : floor.totalCapacity > 0
                              ? " (Unavailable for selected date range)"
                              : " (not available for booking)"}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            {office && (
              <Container>
                <Row>
                  <Col size="8">
                    {office.officePolicyUrl && (
                      <div style={{ textAlign: "left" }}>
                        Before submitting make sure you're familiar with the{" "}
                        <a href={office.officePolicyUrl} target="_blank">
                          security policy and safety information
                        </a>
                        .
                      </div>
                    )}
                  </Col>
                  <Col size="4">
                    <Button
                      className="mt-0"
                      disabled={
                        !this.state.startDate ||
                        !this.state.endDate ||
                        !this.state.selectedFloorId
                      }
                      color="orange"
                      onClick={this.handleBookingSubmit}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Container>
            )}
          </ModalFooter>
        </Modal>

        <ConfirmationModal
          open={showRebookModal}
          modalStyle="success"
          headerText="Do you want to make another booking at the same location?"
          bodyText={null}
          onToggle={() =>
            this.setState({ ...this.state, showRebookModal: false })
          }
          cancelText="Yes"
          onCancel={() => {
            this.setState({
              ...this.state,
              showRebookModal: false,
            });

            onToggle();
          }}
          confirmText="No"
          onConfirm={() =>
            this.setState({ ...this.state, showRebookModal: false })
          }
        />
      </Fragment>
    );
  }

  handleAvailableFloors = () => {
    const { floorsAvailability, startDate, endDate } = this.state;
    const { currentVisit } = this.props;

    if (!floorsAvailability) {
      return {};
    }

    const calculatedFloorsAvailability = currentVisit
      ? floorsAvailability.map((fa) => {
          const isSameAsStart =
            moment(fa.date).format("YYYY-MM-DD") ===
            moment(currentVisit.startDate).format("YYYY-MM-DD");

          const isSameAsEnd =
            moment(fa.date).format("YYYY-MM-DD") ===
            moment(currentVisit.endDate).format("YYYY-MM-DD");

          const isInBetween =
            moment(fa.date).isBefore(currentVisit.endDate) &&
            moment(fa.date).isAfter(currentVisit.startDate);

          if (isSameAsStart || isSameAsEnd || isInBetween) {
            return {
              ...fa,
              floors: fa.floors.map((floor) => {
                if (floor.id === currentVisit.floorId) {
                  return { ...floor, capacity: floor.capacity + 1 };
                }

                return floor;
              }),
            };
          }
          return fa;
        })
      : floorsAvailability;

    // TODO:!floorsAvailability ? {} :
    let availableFloors = calculatedFloorsAvailability.reduce((acc, date) => {
      date.floors.forEach((floor, orderIndex) => {
        acc[floor.id] = {
          ...(!acc[floor.id]
            ? floor
            : acc[floor.id].capacity > floor.capacity
            ? floor
            : acc[floor.id]),
          orderIndex,
        };
      });
      return acc;
    }, {});

    return availableFloors;
  };
}

export default connect(
  (state) => ({ ...selectors(state), ...bookingsSelectors(state) }),
  { ...actions, ...bookingsActions }
)(PlanVisitModal);
