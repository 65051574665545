import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import media from "../../../../media";
import { BookNowButton } from "../../../Bookings/components";
import { actions, selectors } from "../../../Auth";
import { selectors as usersSelectors } from "../../../Users";
import { BecomeHostButton } from "../../../Auth/components";
import {
  Row,
  Col,
  Container,
  Fa,
  MDBMedia,
  MDBInput,
  MDBDatePicker,
  MDBListGroup,
  MDBListGroupItem,
  MDBBadge,
  MDBContainer,
  MDBIcon
} from "mdbreact";
import StyledOfficeHosts from "../OfficeHosts/StyledOfficeHosts";
class OfficeHosts extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { office, hosts, userData, users } = this.props;
    return (
      <StyledOfficeHosts>
        <Container className="text-left">
          <MDBListGroup>
            {hosts.length > 0 &&
              hosts.map(host => (
                <MDBListGroupItem
                  style={{ borderLeft: "none", borderRight: "none" }}
                >
                  <MDBMedia>
                    <MDBMedia left className="mr-3">
                      <Link to={`/user/${host.id}`}>
                        <MDBMedia
                          object
                          style={{ width: 51, height: 51, borderRadius: "50%" }}
                          src={host.photoUrl}
                          alt=""
                        />
                      </Link>
                    </MDBMedia>
                    <MDBMedia body>
                      <Row>
                        <Col size="lg-4" middle>
                          <div style={{ fontWeight: "bold", fontSize: 18 }}>
                            <Link to={`/user/${host.id}`}>{host.name}</Link>
                            {host.id === userData.id && <small> (you)</small>}
                          </div>
                          <div style={{ color: "#9B9B9B", fontSize: 14 }}>
                            {host.jobTitle}
                          </div>
                          <div
                            style={{
                              color: "#555555",
                              fontSize: 14,
                              fontWeight: 500
                            }}
                          >
                            Hosted {host.hostedGuests.length} guests
                          </div>
                        </Col>
                        <Col middle size="lg-4">
                          {/* <MDBBadge className="ml-2" color="default">
                          <MDBIcon icon="facebook" />
                        </MDBBadge>
                        <MDBBadge className="ml-2" color="primary">
                          <MDBIcon icon="instagram" />
                        </MDBBadge>
                        <MDBBadge className="ml-2" color="success">
                          <MDBIcon icon="snapchat-ghost" />
                        </MDBBadge>
                        <MDBBadge className="ml-2" color="info">
                          <MDBIcon icon="anchor" />
                        </MDBBadge> */}
                        </Col>
                        <Col
                          size="lg-4"
                          middle
                          className="text-right d-flex justify-content-between"
                        >
                          {host.phoneNumber && (
                            <a href={`tel:${host.phoneNumber}`} target="_blank">
                              <img width="50px" src={media["phone"]} />
                            </a>
                          )}
                          {host.email && (
                            <a href={`mailto:${host.email}`} target="_blank">
                              <img width="50px" src={media["email"]} />
                            </a>
                          )}
                          {host.workplaceUrl && (
                            <a href={`${host.workplaceUlr}`} target="_blank">
                              <img width="50px" src={media["workplace"]} />
                            </a>
                          )}
                          {userData.id !== host.id && (
                            <BookNowButton
                              office={office}
                              host={host}
                              size="md"
                              disabled={
                                users[userData.id] &&
                                users[userData.id].hostedLocationIds.indexOf(
                                  office.id
                                ) > -1
                              }
                              title={
                                users[userData.id] &&
                                users[userData.id].hostedLocationIds.indexOf(
                                  office.id
                                ) === -1
                                  ? "Book a visit"
                                  : "You cannot book a visit in your location."
                              }
                            />
                          )}
                          {/* {userData.id !== host.id && userData.userType.id == 1 && (
                          <BecomeHostButton text="Book now" size="md" />
                        )} */}
                        </Col>
                      </Row>
                    </MDBMedia>
                  </MDBMedia>
                </MDBListGroupItem>
              ))}
          </MDBListGroup>
        </Container>
      </StyledOfficeHosts>
    );
  }
}
export default connect(
  state => ({
    ...selectors(state),
    ...usersSelectors(state)
  }),
  actions
)(OfficeHosts);
