import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import media from "../../../../media";
import { BookNowButton } from "../../../Bookings/components";
import { PlanVisitButton, AddBuildingButton } from "../";
import { adalApiFetch } from "../../../Auth/index";
import {
  actions as authActions,
  selectors as authSelectors,
} from "../../../Auth";
import { selectors as usersSelectors } from "../../../Users";
import selectors from "../../selectors";
import actions from "../../actions";
import { BecomeHostButton } from "../../../Auth/components";
import moment from "moment";
import {
  DateRangePicker,
  SingleDatePicker,
  DayPickerRangeController,
} from "react-dates";
import {
  Col,
  Container,
  Fa,
  MDBBadge,
  MDBBtn,
  MDBBtnGroup,
  MDBContainer,
  MDBDatePicker,
  MDBIcon,
  MDBInput,
  MDBListGroup,
  MDBListGroupItem,
  MDBMedia,
  Row,
} from "mdbreact";
import StyledOfficeBuildings from "../OfficeBuildings/StyledOfficeBuildings";
import config from "../../../../config";
class OfficeBuildings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(),
      focused: null,
    };
  }

  fetchBuildings = () => {
    this.props.getBuildings(
      this.props.office.id,
      this.state.date.format("YYYY-MM-DD")
    );
  };

  componentDidMount() {
    this.fetchBuildings();
  }
  render() {
    const { office, hosts, userData, users } = this.props;
    return (
      <StyledOfficeBuildings>
        <Container
          style={{
            padding: "10px 0px",
          }}
        >
          <Row>
            <Col md="6">
              Showing availability for{" "}
              <SingleDatePicker
                readOnly
                // noBorder
                showDefaultInputIcon
                inputIconPosition="after"
                small
                numberOfMonths={1}
                firstDayOfWeek={1}
                displayFormat="DD/MM/YYYY"
                date={this.state.date} // momentPropTypes.momentObj or null
                isOutsideRange={() => false}
                onDateChange={(date) => {
                  this.setState({ date }, this.fetchBuildings);
                }} // PropTypes.func.isRequired
                focused={this.state.focused} // PropTypes.bool
                onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                // onFocusOut={}
                id="availability_date_picker" // PropTypes.string.isRequired,
              />
            </Col>
            <Col md="6" align="right">
              {(userData.isAdmin ||
                office.siteManagers.map((u) => u.id).indexOf(userData.id) >
                  -1) && (
                <MDBBtnGroup>
                  <MDBBtn
                    onClick={(e) => {
                      adalApiFetch(
                        fetch,
                        `${
                          config.api.endpoints.base
                        }/api/Offices/GetLocationDailyReport?locationId=${
                          office.id
                        }&date=${this.state.date.format("YYYY-MM-DD")}`,
                        {
                          method: "GET",
                        }
                      ).then((response) => {
                        response.blob().then((blob) => {
                          if (window.navigator && window.navigator.msSaveBlob) {
                            console.warn(
                              "Triggering download using msSaveBlob"
                            );
                            window.navigator.msSaveBlob(
                              blob,
                              "deskovery_report.xlsx"
                            );
                          } else {
                            var url = window.URL.createObjectURL(blob);
                            var a = document.createElement("a");
                            a.href = url;
                            a.download = "";
                            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                            a.click();
                            a.remove(); //afterwards we remove the element again
                          }
                        });
                      });
                    }}
                    // target="_blank"
                    // href={`${
                    //   config.api.endpoints.base
                    // }/api/Offices/GetLocationDailyReport?locationId=${
                    //   office.id
                    // }&date=${this.state.date.format("YYYY-MM-DD")}`}
                    size="sm"
                  >
                    <MDBIcon icon="download" /> Daily report
                  </MDBBtn>

                  <AddBuildingButton size="sm" office={office} />
                </MDBBtnGroup>
              )}
            </Col>
          </Row>
        </Container>
        <Container className="text-left">
          <MDBListGroup>
            {Object.keys(this.props.buildings)
              .map((key) => this.props.buildings[key])
              .map((building, idx) => {
                return (
                  <MDBListGroupItem
                    style={{ borderLeft: "none", borderRight: "none" }}
                  >
                    <MDBMedia>
                      <MDBMedia left className="mr-3">
                        <MDBIcon far icon="building" />
                      </MDBMedia>
                      <MDBMedia body>
                        <Row>
                          <Col size="lg-4" middle>
                            <div style={{ fontWeight: "bold", fontSize: 18 }}>
                              {building.name}
                            </div>
                            <div style={{ color: "#9B9B9B", fontSize: 14 }}>
                              {building.floors.length} floors,{" "}
                              {building.floors.reduce(
                                (total, floor) => total + floor.totalCapacity,
                                0
                              )}{" "}
                              total desks
                            </div>
                          </Col>
                          <Col middle size="lg-4">
                            <div
                              style={{
                                color: "#555555",
                                // fontSize: 14,
                                fontWeight: 500,
                              }}
                            >
                              <strong>{building.capacity} desks</strong>{" "}
                              available
                            </div>
                          </Col>
                          <Col size="lg-4" middle className="text-right">
                            <MDBBtnGroup>
                              {/* {building.capacity > 0 && ( */}
                              <PlanVisitButton
                                office={office}
                                buildingId={building.id}
                                buildingName={building.name}
                              />
                              {/* )} */}
                              {(userData.isAdmin ||
                                office.siteManagers
                                  .map((u) => u.id)
                                  .indexOf(userData.id) > -1) && (
                                <AddBuildingButton
                                  size="sm"
                                  office={office}
                                  building={building}
                                />
                              )}
                            </MDBBtnGroup>
                          </Col>
                        </Row>
                      </MDBMedia>
                    </MDBMedia>
                  </MDBListGroupItem>
                );
              })}
          </MDBListGroup>

          {!this.props.buildingsFetching &&
            this.props.fetchedBuildingsCID !== undefined &&
            this.props.fetchedBuildingsCID === this.props.office.id &&
            Object.keys(this.props.buildings).length === 0 && (
              <Row>
                <Col style={{ textAlign: "center" }}>
                  Your site is not yet activated.
                  <br />
                  Tell your Site Manager to activate the site and assign a
                  capacity.
                  <br /> Go to the Deskovery Workplace Group to find out how
                </Col>
              </Row>
            )}
        </Container>
      </StyledOfficeBuildings>
    );
  }
}
export default connect(
  (state) => ({
    ...selectors(state),
    ...authSelectors(state),
    ...usersSelectors(state),
    fetchedBuildingsCID: state.Offices.fetchedBuildingsCID,
    buildingsFetching: state.Offices.buildingsFetching,
  }),
  { ...actions, ...authActions }
)(OfficeBuildings);
