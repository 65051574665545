import React, { Component } from "react";
import { Container } from "mdbreact";

import MainLayout from "../layouts/MainLayout";
import { container as DashboardContainer } from "../modules/Dashboard";

const DashboardView = props => (
  <MainLayout>
    <Container style={{ padding: "60px 0" }}>
      <DashboardContainer />
    </Container>
  </MainLayout>
);

export default DashboardView;
