import { adalApiFetch } from "../Auth";
import config from "../../config";

const getUsersStart = () => ({
  type: "GET_USERS_START"
});

const getUsersSuccess = payload => ({
  type: "GET_USERS_SUCCESS",
  payload
});

const getUsersError = payload => ({
  type: "GET_USERS_ERROR",
  payload
});

export const getUsers = filters => async dispatch => {
  dispatch(getUsersStart());
  try {
    const users = await adalApiFetch(fetch, config.api.endpoints.users.list, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(filters)
    }).then(res => {
      if (!res.ok || res.status !== 200) {
        throw new Error(
          "Call to endpoint " +
            res.url +
            " failed with status code " +
            res.status
        );
      }
      if (res.headers.get("content-type").indexOf("application/json") === -1) {
        throw new TypeError(
          'Response from "' + res.url + '" has unexpected "content-type"'
        );
      }
      return res.json();
    });
    return dispatch(getUsersSuccess(users));
  } catch (error) {
    console.log(error);
    return dispatch(getUsersError(error));
  }
};

export default {
  getUsers
};
